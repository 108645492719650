import { IcLeftArrow, IcRightArrow } from "components/atoms/icon/icon";
import { StyleNumberPagination } from "components/atoms/pagination/pagination";
import { PaginationButton } from "components/molecules/pagination/PaginationButton";
import React from "react";
import { PaginationComponentProps } from "react-data-table-component";
import { FlexBox } from "theme/globalStyle";

interface DatatablePaginationPropsType extends PaginationComponentProps {
  // rowsPerPage?: number; //페이지당 표시할 행의 개수
  // rowCount: number; //전체/ 데이터 수
  // currentPage?: number;
  // onChangePage: (page: number) => void;
  btnType?: "number" | "dot";
}

export const DatatablePagination: React.FC<DatatablePaginationPropsType> = ({
  rowsPerPage = 10,
  rowCount, //전체 데이터 수
  currentPage = 1,
  onChangePage,
  btnType = "number",
}) => {
  const totalPages = Math.ceil(rowCount / rowsPerPage);
  const pages = [];

  let startPage = 1;
  let endPage = totalPages;

  if (totalPages > 5) {
    if (currentPage <= 3) {
      endPage = 5;
    } else if (currentPage + 2 >= totalPages) {
      startPage = totalPages - 4;
    } else {
      startPage = currentPage - 2;
      endPage = currentPage + 2;
    }
  }

  //이전페이지 랜더링
  if (currentPage > 1 && btnType === "number") {
    pages.push(
      <StyleNumberPagination
        key={"prev"}
        onClick={() => onChangePage(currentPage - 1, totalPages)}
      >
        <IcLeftArrow />
      </StyleNumberPagination>
    );
  }

  for (let i = startPage; i <= endPage; i++) {
    pages.push(
      <PaginationButton
        key={i}
        pageNum={i}
        $isNowPage={i === currentPage}
        onChangePage={() => onChangePage(i, totalPages)}
        btnType={btnType}
      />
    );
  }

  //다음페이지 버튼 랜더링
  if (currentPage < totalPages && btnType === "number") {
    pages.push(
      <StyleNumberPagination
        key={"next"}
        onClick={() => onChangePage(currentPage + 1, totalPages)}
      >
        <IcRightArrow />
      </StyleNumberPagination>
    );
  }

  return (
    <FlexBox $mt={2} $flexDirection="row" $jc="center" $ai="center">
      {pages}
    </FlexBox>
  );
};
