import { GetUsersResponseData } from "api/blueButton/GetUsers";
import { TableRowWrapper } from "components/atoms/table/table";
import { SolidCheckbox } from "components/molecules/checkbox/SolidCheckkbox";
import { TableContent } from "components/molecules/table/TableContent";
import { TableTitle } from "components/molecules/table/TableTitle";
import Typograpy from "components/molecules/Typograpy";
import { CusMemberInfo } from "components/organisms/saller/customerDetail/CusMemberInfo";
import React from "react";
import { useSnackbarStore } from "store/snackbarStore";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { Box, FlexBox } from "theme/globalStyle";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useLoadingStore } from "store/useLoadingStore";
import ModalPortal from "utils/Portal";
import { AlertModal } from "components/organisms/modal/AlertModal";
import useScrollLock from "hooks/useScrollLock";
import { IconOutlineLoading } from "components/atoms/icon/animated";
import { useGetProposalDetail } from "api/api.userProposal";
import axios from "axios";

interface InseranceProposalProps {
  setIsDetailModal: (modalInfo: { visible: boolean; selectId: number }) => void;
  detailUserInfo: GetUsersResponseData;
  productType: "insurances" | "finance";
  proposal_id: number;
  isAccecptDetail?: boolean;
}

//보험상품 제안하기
export const ProposalInfo: React.FC<InseranceProposalProps> = ({
  detailUserInfo,
  proposal_id,
  isAccecptDetail,
}) => {
  const { data: proposalData, isLoading: proposalLoading } =
    useGetProposalDetail(proposal_id);

  const downloadFile = async (url: string, fileName: string) => {
    const response = await fetch(url);
    const blob = await response.blob();
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  };
  useScrollLock(true);

  return (
    <Box $isFullWidth>
      <Typograpy
        fontWeight="Medium"
        fontStyle={fontStyle.headline2}
        color={colors.BLACK}
      >
        기본정보
      </Typograpy>
      <FlexBox $mt={2} $isFullWidth $btw={1} $btc={colors.DEFAULT_BORDER_COLOR}>
        <CusMemberInfo
          isAccecptDetail={isAccecptDetail}
          detailUserInfo={detailUserInfo}
        />
      </FlexBox>
      <Box $mt={4} />
      <Typograpy
        fontWeight="Medium"
        fontStyle={fontStyle.headline2}
        color={colors.BLACK}
      >
        제안내용
      </Typograpy>
      {!proposalData || proposalLoading ? (
        <FlexBox
          $flex={1}
          $isFullWidth
          height={"100%"}
          $jc={"center"}
          $ai={"center"}
        >
          <IconOutlineLoading width={5} height={5} />
        </FlexBox>
      ) : (
        <FlexBox $mt={2} $btw={1} $btc={colors.DEFAULT_BORDER_COLOR}>
          <TableRowWrapper>
            <TableTitle title={"제안명"} />
            <TableContent isColumnCenter>
              <FlexBox
                $isFullWidth
                $flexDirection="row"
                $ai="center"
                $gap={1.2}
              >
                <Typograpy
                  fontStyle={fontStyle.label1_reading}
                  color={colors.SALLER_DETAIL_CONTENT}
                >
                  {proposalData.data.sales_proposal.proposal_title}
                </Typograpy>
              </FlexBox>
            </TableContent>
          </TableRowWrapper>
          <TableRowWrapper>
            <TableTitle title={"제안상태"} />
            <TableContent isColumnCenter>
              <FlexBox
                $isFullWidth
                $flexDirection="row"
                $ai="center"
                $gap={1.2}
              >
                <Typograpy
                  fontStyle={fontStyle.label1_reading}
                  color={colors.SALLER_DETAIL_CONTENT}
                >
                  {proposalData.data.proposition_status === "SEND"
                    ? "제안"
                    : proposalData.data.proposition_status === "WAIT"
                    ? "발송완료"
                    : proposalData.data.proposition_status === "REJECT"
                    ? "거절"
                    : "수락"}
                </Typograpy>
              </FlexBox>
            </TableContent>
          </TableRowWrapper>
          <TableRowWrapper>
            <TableTitle title={"알림톡 발송"} />
            <TableContent isColumnCenter>
              <FlexBox
                $flexDirection="row"
                $ai="center"
                $isFullWidth
                $gap={0.8}
              >
                <SolidCheckbox
                  $checked={
                    proposalData.data.sales_proposal.notification === "YES"
                  }
                  onChange={() => {}}
                  name="notification"
                  value={"Y"}
                  label="발송"
                />
              </FlexBox>
            </TableContent>
          </TableRowWrapper>
          <TableRowWrapper>
            <TableTitle title={"영업담당자"} />
            <TableContent isColumnCenter isLeft>
              <FlexBox $flexDirection="row" $ai="center" $gap={1.2}>
                <Typograpy
                  fontStyle={fontStyle.label1_reading}
                  color={colors.SALLER_DETAIL_CONTENT}
                >
                  {proposalData.data.salesmanager.salesmanager_name}
                </Typograpy>
              </FlexBox>
            </TableContent>
          </TableRowWrapper>
          <TableRowWrapper>
            <TableTitle title={"회사명"} />
            <TableContent isColumnCenter isLeft>
              <FlexBox $flexDirection="row" $ai="center" $gap={1.2}>
                <Typograpy
                  fontStyle={fontStyle.label1_reading}
                  color={colors.SALLER_DETAIL_CONTENT}
                >
                  {proposalData.data.salesmanager.company_name}
                </Typograpy>
              </FlexBox>
            </TableContent>
          </TableRowWrapper>
          <TableRowWrapper>
            <TableTitle title={"제안 내용"} />
            <TableContent isColumnCenter>
              <FlexBox $isFullWidth>
                <FlexBox
                  $flexDirection="row"
                  $ai="center"
                  $gap={1.2}
                  $isFullWidth
                >
                  {proposalData && (
                    <Box
                      dangerouslySetInnerHTML={{
                        __html:
                          proposalData.data.sales_proposal.proposal_content,
                      }}
                    ></Box>
                  )}
                </FlexBox>
              </FlexBox>
            </TableContent>
          </TableRowWrapper>

          {proposalData.data.sales_proposal.file_info && (
            <TableRowWrapper>
              <TableTitle title={"첨부파일"} />
              <TableContent isColumnCenter isLeft>
                <FlexBox>
                  {proposalData.data.sales_proposal.file_info.map(
                    (item, index) => {
                      return (
                        <Box
                          key={"proposal-filelist" + index}
                          $isPointer
                          onClick={() => {
                            downloadFile(
                              process.env.REACT_APP_FILE_SERVER + item.file_url,
                              item.file_url
                            );
                          }}
                        >
                          <Typograpy
                            fontStyle={fontStyle.label1_reading}
                            color={colors.SALLER_DETAIL_CONTENT}
                          >
                            {item.file_url}
                          </Typograpy>
                        </Box>
                      );
                    }
                  )}
                </FlexBox>
              </TableContent>
            </TableRowWrapper>
          )}
        </FlexBox>
      )}
    </Box>
  );
};
