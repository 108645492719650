import {
  RadioButton,
  RadioLabel,
  RadioProps,
  RadioWrapper,
} from "components/atoms/radio/radio";
import React from "react";

export const Radio: React.FC<RadioProps> = ({
  size = "normal",
  checked = false,
  disabled = false,
  interaction = "normal",
  onChange,
  name,
  value,
  label,
}) => {
  return (
    <RadioWrapper size={size} disabled={disabled} interaction={interaction}>
      <RadioButton
        size={size}
        defaultChecked={checked}
        disabled={disabled}
        interaction={interaction}
        onChange={onChange}
        name={name}
        value={value}
      />
      <RadioLabel>{label}</RadioLabel>
    </RadioWrapper>
  );
};
