import React from "react";
import { Box, FlexBox } from "theme/globalStyle";
import { PrivacyAgree } from "./PrivacyAgree";
import { useLocation } from "react-router";
import { ServiceAgree } from "./ServiceAgree";
import { MarketingAgree } from "./MarketingAgree";
import { RefundAgree } from "./RefundAgree";

const AgreeTemplate = () => {
  const location = useLocation();
  const currentPath = location.pathname.replace(/^\/agree\/?/, "");

  // React.useEffect(() => {
  //   console.log(currentPath);
  // }, []);

  return (
    <FlexBox $isFullWidth $flex={1} $px={2} $pt={2}>
      {currentPath === "privacy" ? (
        <PrivacyAgree />
      ) : currentPath === "service" ? (
        <ServiceAgree />
      ) : currentPath === "refund" ? (
        <RefundAgree />
      ) : (
        <MarketingAgree />
      )}
    </FlexBox>
  );
};

export default AgreeTemplate;
