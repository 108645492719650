import { SolidButton } from "components/molecules/button/SolidButton";
import { Divider } from "components/molecules/divider/Divider";
import Typograpy from "components/molecules/Typograpy";
import React from "react";
import { colors } from "theme/colors";
import { Box, FlexBox } from "theme/globalStyle";
import { NumberComma } from "utils/func";

import IconUpload from "assets/img/ic_upload.svg";
import { fontStyle } from "theme/fontStyle";
import { GetInsurancesResponseType } from "api/blueButton/getInsurancesInfo";

interface InsuranceListElPropsType {
  data: GetInsurancesResponseType;
}

//제안보험 리스트
export const InsuranceListEl: React.FC<InsuranceListElPropsType> = ({
  data,
}) => {
  return (
    <FlexBox
      $flexDirection="row"
      $ai="center"
      $isFullWidth
      $bw={1}
      $bc={colors.DEFAULT_BORDER_COLOR}
      $br={10}
      $py={1}
      $px={2}
    >
      <FlexBox $flex={3}>
        <Typograpy
          color={colors.INSURANCE_TITLE}
          fontStyle={fontStyle.caption1}
          fontWeight="SemiBold"
        >
          보험명
        </Typograpy>
        <Typograpy
          color={colors.INSURANCE_CONTENT}
          fontStyle={fontStyle.label1_normal}
        >
          {data.insuranceName}
        </Typograpy>
      </FlexBox>
      <Divider height={"100%"} variant="normal" isVertical />
      <FlexBox $flex={1.5} $px={1.6}>
        <Typograpy
          color={colors.INSURANCE_TITLE}
          fontStyle={fontStyle.caption1}
          fontWeight="SemiBold"
        >
          공급
        </Typograpy>
        <Typograpy
          color={colors.INSURANCE_CONTENT}
          fontStyle={fontStyle.label1_normal}
        >
          {data.companyName}
        </Typograpy>
      </FlexBox>
      <Divider height={"100%"} variant="normal" isVertical />
      <FlexBox $flex={1} $pl={1.6}>
        <Typograpy
          color={colors.INSURANCE_TITLE}
          fontStyle={fontStyle.caption1}
          fontWeight="SemiBold"
        >
          금액
        </Typograpy>
        <Typograpy
          color={colors.INSURANCE_CONTENT}
          fontStyle={fontStyle.label1_normal}
        >
          월 {NumberComma(Math.floor(data.paymentAmount / 12))}원
        </Typograpy>
      </FlexBox>
    </FlexBox>
  );
};
