import { useAdminLogin } from "api/auth/AdminLogin";
import axios from "axios";
import { IcKakao, IcNaver } from "components/atoms/icon/icon";
import { OutlineButton } from "components/molecules/button/OutlineButton";
import { Divider } from "components/molecules/divider/Divider";
import Typograpy from "components/molecules/Typograpy";
import { LoginForm } from "components/organisms/user/LoginForm";
import React from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { Box, FlexBox, MainMaxWidth, UserMaxWidth } from "theme/globalStyle";

const LoginTemplate = () => {
  const navigate = useNavigate();

  //로그인 페이지 이동 함수
  const handleSocialLogin = (type: "kakao" | "naver") => {
    const KAKAO_CLIENT_KEY = process.env.REACT_APP_KAKAO_APP_KEY;
    const KAKAO_REDIRECT_URL = process.env.REACT_APP_KAKAO_REDIRECT_URL;
    const NAVER_CLIENT_KEY = process.env.REACT_APP_NAVER_APP_KEY;

    const CALLBACK_URL = `${KAKAO_REDIRECT_URL}/auth`;

    let socialParams;

    switch (type) {
      case "kakao":
        socialParams = {
          clientId: `${KAKAO_CLIENT_KEY}`,
          redirectUri: `${CALLBACK_URL}?type=kakao`,
          codeUrl: "https://kauth.kakao.com/oauth/authorize",
          scope: [
            "profile_nickname",
            "profile_image",
            "account_email",
            "name",
            "birthday",
            "birthyear",
            "",
          ],
        };
        break;
      case "naver":
        socialParams = {
          clientId: `${NAVER_CLIENT_KEY}`,
          redirectUri: `${CALLBACK_URL}?type=naver`,
          codeUrl: "https://nid.naver.com/oauth2.0/authorize",
          scope: "profile",
        };
        break;
      default:
        console.error("Unknown social type:", type);
        return;
    }

    const url = new URL(socialParams.codeUrl);
    const params = new URLSearchParams({
      client_id: socialParams.clientId,
      redirect_uri: socialParams.redirectUri,
      response_type: "code",
      scope: "", // scope는 선택적
    });
    url.search = params.toString();

    window.location.href = url.toString();
  };

  return (
    <FlexBox $minHeight={70} $isFullWidth $ai="center">
      <FlexBox
        $isFullWidth
        $py={4}
        $ai="center"
        $jc="center"
        $bgcolor={colors.USER_HEADER_BG}
      >
        <Typograpy
          fontSize={45}
          fontWeight="Bold"
          color={colors.WHITE}
          style={{ lineHeight: "4.8rem" }}
        >
          로그인
        </Typograpy>
      </FlexBox>
      <Box $isFullWidth $maxWidth={UserMaxWidth}>
        <LoginForm accessType="SALLER" />

        <Box $my={4}>
          <Divider variant="normal" width={"100%"} />
        </Box>
        <Box style={{ textAlign: "center" }}>
          <Typograpy fontStyle={fontStyle.headline2} color={colors.BLACK}>
            진짜 고객을 만나는 방법{" "}
            <Typograpy
              fontStyle={fontStyle.headline2}
              color={colors.MAIN}
              fontWeight="Bold"
            >
              블루세일즈
            </Typograpy>
          </Typograpy>
        </Box>
        <Box $mt={2} $mb={4}>
          <Link to={"/join/writeUserInfo"}>
            <OutlineButton
              size={"large"}
              buttonLabel="회원가입"
              style={{ width: "100%" }}
            />
          </Link>
        </Box>

        <Box $isFullWidth style={{ textAlign: "center" }}>
          <Typograpy fontStyle={fontStyle.body1_normal} color={colors.BLACK}>
            SNS 계정으로 로그인하기
          </Typograpy>

          <FlexBox $flexDirection="row" $mt={2} $isFullWidth $gap={0.8}>
            {/* <FlexBox
              $flexDirection="row"
              $ai="center"
              $jc="center"
              $gap={0.8}
              $flex={1}
              $py={1.5}
              $br={4}
              $bgcolor={colors.NAVER_BG}
              onClick={() => {
                handleSocialLogin("naver");
              }}
            >
              <IcNaver />
              <Typograpy
                fontStyle={fontStyle.headline1}
                color={colors.WHITE}
                fontWeight="Medium"
              >
                로그인
              </Typograpy>
            </FlexBox> */}
            <FlexBox
              $flexDirection="row"
              $ai="center"
              $jc="center"
              $gap={0.8}
              $flex={1}
              $py={1.5}
              $br={4}
              $bgcolor={colors.KAKAO_BG}
              $isPointer
              onClick={() => {
                handleSocialLogin("kakao");
              }}
            >
              <IcKakao />
              <Typograpy
                fontStyle={fontStyle.headline1}
                color={colors.BLACK}
                fontWeight="Medium"
              >
                로그인
              </Typograpy>
            </FlexBox>
          </FlexBox>
        </Box>
      </Box>
    </FlexBox>
  );
};

export default LoginTemplate;
