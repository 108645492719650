import { useQueryClient } from "@tanstack/react-query";
import {
  GetUsersParams,
  GetUsersResponseData,
  useGetUsersList,
} from "api/blueButton/GetUsers";
import { IcMore } from "components/atoms/icon/icon";
import { ConsumerDetailModal } from "components/organisms/modal/ConsumerDetailModal";
import { DatatablePagination } from "components/organisms/pagination/DatatablePagination";
import { CustomerListFilter } from "components/organisms/filter/CustomerListFilter";
import { NoneDataComponent } from "components/organisms/table/NoneDataComponent";
import React from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { Box, FlexBox } from "theme/globalStyle";
import { tableCustomStyles } from "theme/table.custom";
import {
  formatAndMaskVariableDigits,
  maskAllButFirst,
  NumberComma,
  operatorTypeValueReturn,
  searchFilterValueReturn,
  subtypeValueReturn,
} from "utils/func";
import ModalPortal from "utils/Portal";
import { useLocationStore } from "store/useLocationStore";
import { GetSallerListResponse } from "api/api.salesmanager";
import { Pagination } from "components/organisms/pagination/Pagination";
import LoadingComponent from "components/organisms/loadingComponent";

interface SallerListProps {
  selCustomerIndex?: number;
  regionId?: number;
  sallerData?: GetSallerListResponse;
}

export const SallerList: React.FC<SallerListProps> = ({
  regionId,
  sallerData,
}) => {
  //지역데이터 불러오기
  const { locationList } = useLocationStore((state) => state);

  //고객목록 STATE
  const [userList, setUserList] = React.useState<GetUsersResponseData[]>([]);

  //필터 정보
  const [filterInfo, setFilterInfo] = React.useState<GetUsersParams>({
    regionId: "",
    type1: "",
    type2: "",
    searchKeyword: "",
    totalIncomeOperatorType: "",
    totalIncome: "",
    ageOperatorType: "",
    age: "",
    page: 0,
    size: 10,
  });

  //고객 상세정보
  const [isDetailModal, setIsDetailModal] = React.useState({
    visible: false,
    selectId: 0,
  });
  const [isListLoading, setIsListLoading] = React.useState(false);

  const filterSetting = (value: string, key?: string) => {
    if (key) {
      setFilterInfo({
        ...filterInfo,
        [key]: value,
      });
    }
  };

  //고객 리스트 불러오기
  const { data: userData, refetch: userRefetch } = useGetUsersList({
    ...filterInfo,
    regionId:
      regionId ||
      locationList.filter((el) => el.regionName === filterInfo.regionId)[0]
        ?.regionId ||
      "",
    type1: subtypeValueReturn(filterInfo.type1 || "") || "INSURANCE_FINANCE",
    type2: "BUSINESS_NAME",
    age: Number(filterInfo.age),
    ageOperatorType:
      filterInfo.age === ""
        ? "NONE"
        : operatorTypeValueReturn(filterInfo.ageOperatorType || ""),
  });

  const columns: TableColumn<GetUsersResponseData>[] = [
    {
      name: "NO",
      sortable: false,
      cell: (_: any, index: number) => index + 1,
      width: "6rem",
      grow: 1,
    },
    {
      name: "구분",
      selector: (row) => {
        if (row.isFinancialInfo && row.isInsuranceInfo) {
          return "보험/금융";
        } else if (row.isFinancialInfo) {
          return "금융";
        } else if (row.isInsuranceInfo) {
          return "보험";
        }
        return "";
      },
      sortable: false,
    },
    {
      name: "성명",
      selector: (row) => maskAllButFirst(row.name),
      sortable: true,
    },
    {
      name: "연령",
      selector: (row) => row.age,
      sortable: true,
    },
    {
      name: "직장",
      selector: (row) => row.businessName,
      sortable: true,
      grow: 4,
    },
    {
      name: "이메일",
      selector: (row) => row.email,
      sortable: false,
      grow: 2,
    },
    {
      name: "종합소득",
      selector: (row) => formatAndMaskVariableDigits(row.totalIncome, 7) + "원",
      sortable: true,
      grow: 2,
    },
    {
      name: "",
      selector: (row: any) => row.readCount,
      sortable: false,
      cell: (row) => {
        return (
          <Box
            $isPointer
            onClick={() => {
              setIsDetailModal({
                visible: true,
                selectId: row.id,
              });
            }}
          >
            <IcMore />
          </Box>
        );
      },
    },
  ];

  const searchAction = () => {
    setIsListLoading(true);
    userRefetch().then(() => {
      setIsListLoading(false);
    });
  };

  // //초기 셋팅 및 regionId 예외셋팅
  // const setting = () => {
  //   //내가 결제한 지역 리스트 뽑기
  //   const firRegion = locationList;
  //   if (filterInfo.regionId === "") {
  //     //초기 셋팅
  //     if (firRegion.length > 0) {
  //       setFilterInfo((prev) => ({
  //         ...prev,
  //         regionId: firRegion[0].regionName,
  //       }));
  //     }
  //   }
  // };

  React.useEffect(() => {
    return () => {
      setFilterInfo({
        regionId: "",
        type1: "",
        type2: "",
        searchKeyword: "",
        totalIncomeOperatorType: "",
        totalIncome: "",
        ageOperatorType: "",
        age: "",
        page: 0,
        size: 10,
      });
    };
  }, []);

  React.useEffect(() => {
    if (regionId) {
      // setFilterInfo({ ...filterInfo, regionId: regionId });
      setIsListLoading(true);
      userRefetch().then(() => {
        setIsListLoading(false);
      });
    }
  }, [regionId]);

  React.useEffect(() => {
    // setFilterUserList();
    setIsListLoading(true);
    userRefetch().then(() => {
      setIsListLoading(false);
    });
  }, [filterInfo.regionId, filterInfo.page]);

  // React.useEffect(() => {
  //   if (locationList.length > 0 && !regionId) {
  //     setting();
  //   }
  // }, [locationList]);

  React.useEffect(() => {
    if (userData) {
      if (userData?.status === 200) {
        setUserList([...userData.data.response]);
      } else {
      }
    } else {
      setUserList([]);
    }
  }, [userData]);

  return (
    <FlexBox $pa={2} $isFullWidth>
      {isDetailModal.visible && (
        <ModalPortal>
          <ConsumerDetailModal
            setIsDetailModal={setIsDetailModal}
            selectId={isDetailModal.selectId}
            productType={"insurances"}
            userData={userData}
            isJustDetail
          />
        </ModalPortal>
      )}
      {!regionId && (
        <CustomerListFilter
          filterInfo={filterInfo}
          filterSetting={filterSetting}
          searchAction={searchAction}
        />
      )}
      {!isListLoading ? (
        <Box $isFullWidth $mt={regionId ? 0 : 2}>
          <DataTable
            columns={columns}
            data={[...userList]}
            // selectableRows
            defaultSortFieldId={1}
            pagination={false}
            // paginationComponent={DatatablePagination}
            customStyles={tableCustomStyles}
            noDataComponent={<NoneDataComponent />}
          />
          <Pagination
            page={
              filterInfo.page === 0 || filterInfo.page ? filterInfo.page + 1 : 0
            }
            rowCount={userData?.data.page.totalElements || 0}
            rowPerPage={filterInfo.size || 10}
            setPage={(page: number) => {
              setFilterInfo({
                ...filterInfo,
                page: page - 1,
              });
            }}
          />
        </Box>
      ) : (
        <LoadingComponent />
      )}
    </FlexBox>
  );
};
