import styled from "styled-components";
import { colors } from "theme/colors";
import { FlexBox } from "theme/globalStyle";

interface TableTdProps {
  $tdWidth?: string | number;
  $bgcolor?: string;
  $textAlign?: "left" | "center" | "right";
}

const TableRowWrapper = styled(FlexBox)`
  width: 100%;
  flex-direction: row;
  align-items: stretch;
  border-bottom: 1px solid ${colors.DEFAULT_BORDER_COLOR};
`;

const AgreeTableWrapper = styled.table`
  /* border : 1px solid ${colors.DEFAULT_BORDER_COLOR}; */

  width: 100%;
  height: auto;
  border-collapse: collapse;
`;

const AgreeTableHeader = styled.th<TableTdProps>`
  border: 1px solid ${colors.DEFAULT_BORDER_COLOR};
  width: 100%;
  ${({ $tdWidth }) =>
    $tdWidth &&
    `
    width: ${typeof $tdWidth === "string" ? `${$tdWidth}` : `${$tdWidth}rem`};
  `}
`;

const AgreeTableTr = styled.tr`
  width: 100%;
  /* border: 1px solid ${colors.DEFAULT_BORDER_COLOR}; */
`;

const AgreeTableTd = styled.td<TableTdProps>`
  border: 1px solid ${colors.DEFAULT_BORDER_COLOR};
  padding-left: 1.2rem;
  padding-right: 1.2rem;
  padding-top: 0.4rem;
  padding-bottom: 0.4rem;
  ${({ $tdWidth }) =>
    $tdWidth &&
    `
    width: ${typeof $tdWidth === "string" ? `${$tdWidth}` : `${$tdWidth}rem`};
  `}
`;

const TableWrapper = styled.table`
  width: 100%;
  height: auto;
  border-collapse: collapse;
`;

const TableTr = styled.tr``;

const TableHeader = styled.th<TableTdProps>`
  border: 1px solid ${colors.DEFAULT_BORDER_COLOR};
  width: 100%;
  ${({ $tdWidth }) =>
    $tdWidth &&
    `
    width: ${typeof $tdWidth === "string" ? `${$tdWidth}` : `${$tdWidth}rem`};
  `}
  ${({ $bgcolor }) => $bgcolor && `background-color : ${$bgcolor};`}
  padding : 1.2rem !important;
  text-align: ${({ $textAlign }) => $textAlign || "center"};
`;

const TableTd = styled.td<TableTdProps>`
  border: 1px solid ${colors.DEFAULT_BORDER_COLOR};
  ${({ $tdWidth }) =>
    $tdWidth &&
    `
    width: ${typeof $tdWidth === "string" ? `${$tdWidth}` : `${$tdWidth}rem`};
  `}
  ${({ $bgcolor }) => $bgcolor && `background-color : ${$bgcolor};`}
  padding : 1.2rem !important;
  text-align: ${({ $textAlign }) => $textAlign || "center"};
`;

export {
  TableRowWrapper,
  AgreeTableWrapper,
  AgreeTableHeader,
  AgreeTableTr,
  AgreeTableTd,
  TableWrapper,
  TableTr,
  TableHeader,
  TableTd,
};
