import { TableContent } from "components/molecules/table/TableContent";
import { TableTitle } from "components/molecules/table/TableTitle";
import Typograpy from "components/molecules/Typograpy";
import React, { ReactNode } from "react";
import { CSSProperties } from "styled-components";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { FlexBox } from "theme/globalStyle";

interface DetailTableRowProps {
  isBtw?: boolean;

  title: string;
  titleWidth?: number;
  isRequire?: boolean;

  contents: ReactNode;
  isColumn?: boolean;
  isLeft?: boolean;
  isColumnCenter?: boolean;
  contentsStyle?: CSSProperties;
}

export const DetailTableRow: React.FC<DetailTableRowProps> = ({
  isBtw,

  title,
  isRequire,
  titleWidth,

  contents,
  isColumn,
  isLeft,
  isColumnCenter,
  contentsStyle,
}) => {
  return (
    <FlexBox
      $btw={isBtw ? 1 : 0}
      $btc={colors.DEFAULT_BORDER_COLOR}
      $bbw={1}
      $bbc={colors.DEFAULT_BORDER_COLOR}
      $flexDirection="row"
      $ai="center"
      $isFullWidth
    >
      <FlexBox $isFullWidth $flexDirection="row" $ai="stretch">
        <TableTitle title={title} width={titleWidth || 15} />
        <TableContent
          isLeft={isLeft}
          isColumnCenter={isColumnCenter}
          isColumn={isColumn}
          style={{ ...contentsStyle }}
        >
          {contents}
        </TableContent>
      </FlexBox>
    </FlexBox>
  );
};
