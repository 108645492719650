import React from "react";
import Typograpy from "../Typograpy";
import {
  CheckboxProps,
  CheckboxWrapper,
  HiddenCheckbox,
  JustCheckIcon,
  StyleJustCheckBox,
} from "components/atoms/checkbox/checkbox";

export const JustCheckbox: React.FC<CheckboxProps> = ({
  size,
  $checked,
  disabled,
  onChange,
  name,
  value,
  label,
  fontStyle,
  fontWeight,
  fontColor,
}) => (
  <CheckboxWrapper disabled={disabled}>
    <HiddenCheckbox
      checked={$checked}
      value={value}
      name={name}
      disabled={disabled}
      onChange={onChange}
    />
    <StyleJustCheckBox
      size={size}
      name={name}
      value={value}
      $checked={$checked}
    >
      <JustCheckIcon checked={$checked} viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </JustCheckIcon>
    </StyleJustCheckBox>
    {label && (
      <Typograpy
        fontWeight={fontWeight}
        color={fontColor}
        fontStyle={fontStyle}
      >
        {label}
      </Typograpy>
    )}
  </CheckboxWrapper>
);
