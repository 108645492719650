import React from "react";
import { FlexBox } from "theme/globalStyle";
import { CouponSearchArea } from "./CouponeSerchArea";
import ModalPortal from "utils/Portal";
import { CouponManageModal } from "components/organisms/modal/CouponManageModal";
import { CouponDetailArea } from "./CouponDetailArea";
import { Divider } from "components/molecules/divider/Divider";
import { CouponHistoryList } from "./CouponHistoryList";

const AdminCouponTemplate = () => {
  const [selCouponIdx, setSelCouponIdx] = React.useState<"" | number>("");

  const [couponModal, setCouponModal] = React.useState(false);

  const couponModalOn = () => {
    setCouponModal(true);
  };

  const couponModalOff = () => {
    setCouponModal(false);
  };

  return (
    <FlexBox $flex={1} $flexDirection={"row"} height={"100%"} $isFullWidth>
      <ModalPortal>
        <CouponManageModal
          visible={couponModal}
          modalOff={couponModalOff}
          mode={"create"}
          // selCouponIdx={couponModal.selCouponIdx}
        />
      </ModalPortal>
      <CouponSearchArea
        selCouponIdx={selCouponIdx}
        setSelCouponIdx={setSelCouponIdx}
        couponModalOn={couponModalOn}
      />
      <FlexBox $isFullWidth>
        {selCouponIdx !== "" && (
          <CouponDetailArea selCouponIdx={selCouponIdx} />
        )}
        <Divider variant="normal" width={"100%"} />
        {selCouponIdx !== "" && (
          <CouponHistoryList selCouponIdx={selCouponIdx} />
        )}
      </FlexBox>
    </FlexBox>
  );
};

export default AdminCouponTemplate;
