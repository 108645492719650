import React from "react";
import { AdminLoginResponse, useAdminLogin } from "api/auth/AdminLogin";
import { CheckTokenResponse, useCheckToken } from "api/auth/CheckToken";
import { useSallerLogin } from "api/auth/SallerLogin";
import { AxiosError, AxiosResponse } from "axios";
import { SolidButton } from "components/molecules/button/SolidButton";
import { SolidCheckbox } from "components/molecules/checkbox/SolidCheckkbox";
import { Divider } from "components/molecules/divider/Divider";
import { PrimaryInput } from "components/molecules/Input/PrimaryInput";
import Typograpy from "components/molecules/Typograpy";
import { useNavigate } from "react-router";
import { AdminInfoType, useAdminInfoStore } from "store/useAdminInfoStore";
import { UserInfoType, useUserInfoStore } from "store/userInfoStore";
import { useTokenStore } from "store/useTokenStore";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { Box, FlexBox, UserMaxWidth } from "theme/globalStyle";
import ModalPortal from "utils/Portal";
import { AlertModal } from "../modal/AlertModal";

import { IcEyeOff, IcEyeOn } from "components/atoms/icon/icon";
import { useGetPaymentList } from "api/api.payment";
import { useGetSallerInfo } from "api/api.salesmanager";
import { useLoadingStore } from "store/useLoadingStore";
import { isDateInRange } from "utils/func";
import { Link } from "react-router-dom";

interface LoginFormPropsType {
  accessType: "ADMIN" | "SALLER";
}

//로그인 폼
export const LoginForm: React.FC<LoginFormPropsType> = ({ accessType }) => {
  const navigate = useNavigate();

  const [isPassShow, setIsPassShow] = React.useState(false);
  const [isCapslock, setIsCapslock] = React.useState(false);

  const [alertModal, setAlertModal] = React.useState({
    visible: false,
    msg: "",
    type: "",
  });

  //로그인 mutation
  const { mutate, isPending } = useSallerLogin({
    onSuccess: loginSuccess,
    onError: loginError,
  });

  //관리자 로그인 mutation
  const { mutate: adminLoginMutate, isPending: adminLoginPending } =
    useAdminLogin({
      onSuccess: loginSuccess,
      onError: loginError,
    });

  //아이디 (admin_account)
  const [adminId, setAdminId] = React.useState<{
    value: string;
    $isError: boolean;
  }>({ value: "", $isError: false });

  //비밀번호 (password)
  const [adminPw, setAdminPw] = React.useState<{
    value: string;
    $isError: boolean;
  }>({ value: "", $isError: false });

  //아이디 저장
  const [saveId, setSaveId] = React.useState(true);

  //비밀번호 숨기기
  const [hidePassword, setHidePassword] = React.useState<boolean>(true);

  //토큰 검증 및 유저정보 불러오기
  const {
    mutate: tokenCheck,
    isError,
    error,
    isPending: tokenCheckPending,
  } = useCheckToken({
    goHome: () => {
      navigate("/");
    },
    onError: (error) => {
      setAlertModal({
        visible: true,
        msg: `예상치 못한 에러가 발생하였습니다. \n ${error}`,
        type: "",
      });
    },
  });

  //로그인
  function adminLoginHandler() {
    if (adminId.value === "") {
      setAdminId({ ...adminId, $isError: true });
    } else if (adminPw.value === "") {
      setAdminPw({ ...adminPw, $isError: true });
    } else {
      if (accessType === "ADMIN") {
        adminLoginMutate({
          admin_account: adminId.value,
          password: adminPw.value,
          platform: "PC",
        });
      } else {
        mutate({
          salesmanager_account: adminId.value,
          salesmanager_password: adminPw.value,
          platform: "PC",
        });
      }
    }
  }

  //로그인 성공
  function loginSuccess(data: AdminLoginResponse) {
    const tokenData = data.data.token;
    if (saveId) {
      localStorage.setItem("localId", adminId.value);
    } else {
      localStorage.setItem("localId", "");
    }

    if (data.status === 500) {
      setAdminId({ ...adminId, $isError: true });
      setAdminPw({ ...adminPw, $isError: true });
    } else {
      setAdminId({ value: "", $isError: false });
      setAdminPw({ value: "", $isError: false });
      useTokenStore.getState().setToken(tokenData);
      tokenCheck(tokenData);
    }
  }

  //로그인 api호출중 오류 발생
  function loginError(error: AxiosError) {
    console.log("$isError!!!", error);
  }

  React.useEffect(() => {
    if (adminId.value !== "") {
      setAdminId({ ...adminId, $isError: false });
    }
  }, [adminId.value]);

  React.useEffect(() => {
    if (adminPw.value !== "") {
      setAdminPw({ ...adminPw, $isError: false });
    }
  }, [adminPw.value]);

  // React.useEffect(() => {
  //   updateLoading(
  //     tokenCheckPending || getSallerInfoPending || adminLoginPending
  //   );
  // }, [tokenCheckPending, getSallerInfoPending, adminLoginPending]);

  React.useEffect(() => {
    const localId = localStorage.getItem("localId");
    if (localId) {
      setAdminId({
        ...adminId,
        value: localId,
      });
    }
  }, []);

  return (
    <FlexBox $isFullWidth $maxWidth={UserMaxWidth}>
      <ModalPortal>
        <AlertModal
          visible={alertModal.visible}
          msg={alertModal.msg}
          type={alertModal.type}
          modalOff={() => {
            setAlertModal({ visible: false, msg: "", type: "" });
          }}
        />
      </ModalPortal>
      <FlexBox
        $ai="stretch"
        $flexDirection="row"
        $isFullWidth
        $gap={0.8}
        $mt={2}
      >
        <FlexBox $flex={8} $gap={0.8}>
          <PrimaryInput
            placeholder="아이디"
            onChangeValue={(value: string) => {
              setAdminId({ value, $isError: false });
            }}
            value={adminId.value}
            $isError={adminId.$isError}
            errorMsg={adminId.value !== "" ? "" : "아이디를 입력해주세요."}
            onEnterEvent={() => {
              adminLoginHandler();
            }}
            capsLockCheck={(capslock) => {
              if (!capslock) setIsCapslock(capslock);
            }}
          />
          <PrimaryInput
            placeholder="비밀번호"
            inputType={isPassShow ? "text" : "password"}
            onChangeValue={(value: string) => {
              setAdminPw({ value, $isError: false });
            }}
            value={adminPw.value}
            $isError={adminPw.$isError}
            errorMsg={
              adminPw.value !== ""
                ? "아아디 혹은 비밀번호가 일치하지 않습니다."
                : "비밀번호를 입력해주세요."
            }
            onEnterEvent={() => {
              adminLoginHandler();
            }}
            rightIcon={isPassShow ? <IcEyeOn /> : <IcEyeOff />}
            iconAction={() => {
              setIsPassShow((prev) => !prev);
            }}
            capsLockCheck={(capslock) => setIsCapslock(capslock)}
          />
        </FlexBox>
        <FlexBox style={{ flexGrow: 1 }}>
          <SolidButton
            buttonLabel="로그인"
            style={{ width: "100%", height: "100%" }}
            onClick={() => {
              adminLoginHandler();
            }}
          />
        </FlexBox>
      </FlexBox>
      {isCapslock && (
        <Box>
          <Typograpy fontStyle={fontStyle.caption1} color={colors.RED}>
            Capslock이 켜져 있습니다.
          </Typograpy>
        </Box>
      )}
      <FlexBox
        $isFullWidth
        $flexDirection={"row"}
        $ai="center"
        $jc="space-between"
        $mt={2}
      >
        <SolidCheckbox
          fontStyle={fontStyle.headline1}
          fontColor={colors.BLACK}
          label={"아이디 저장"}
          name="save-id"
          value={"Y"}
          onChange={(e) => setSaveId(e.target.checked)}
          $checked={saveId}
        />
        <FlexBox $flexDirection="row" $ai="center" $gap={1.6}>
          <Link to={"/find/findId"} state={{ type: "id" }}>
            <Typograpy fontSize={14} color={colors.BLACK}>
              아이디 찾기
            </Typograpy>
          </Link>
          <Divider
            variant="normal"
            isVertical
            height={1.2}
            $bgcolor={colors.BLACK}
          />
          <Link to={"/find/findPw"} state={{ type: "pw" }}>
            <Typograpy fontSize={14} color={colors.BLACK}>
              비밀번호 찾기
            </Typograpy>
          </Link>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};
