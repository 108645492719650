import { GetNoticeListDataType, useGetNotice } from "api/api.notice";
import { TableTd, TableTr, TableWrapper } from "components/atoms/table/table";
import { TableContent } from "components/molecules/table/TableContent";
import { TableTitle } from "components/molecules/table/TableTitle";
import Typograpy from "components/molecules/Typograpy";
import { AlertModal } from "components/organisms/modal/AlertModal";
import { NoticeTableHeader } from "components/organisms/notice/NoticeTableHeader";
import { Pagination } from "components/organisms/pagination/Pagination";
import React from "react";
import { useNavigate, useParams } from "react-router";
import { useSnackbarStore } from "store/snackbarStore";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { Box, FlexBox } from "theme/globalStyle";
import { convertUnixDate } from "utils/func";
import ModalPortal from "utils/Portal";

export const NoticeDetailTemplate = () => {
  const { noticeId } = useParams();
  const navigate = useNavigate();

  const [noticeDetailInfo, setNoticeDetailInfo] =
    React.useState<GetNoticeListDataType>({
      notice_name: "",
      notice_content: "",
      is_show: "Y",
      notice_id: 0,
      admin_id: 0,
      created_at: 0,
      updated_at: 0,
    });

  const [alertModal, setAlertModal] = React.useState({
    visible: false,
    msg: "",
    type: "",
  });

  const {
    mutate: noticeMutate,
    data: noticeData,
    isError: noticeIsError,
    error: noticeError,
  } = useGetNotice({
    onSuccess: (res) => {
      if (res.data.is_show === "N") {
        alertModalOn("존재하지 않는 공지사항입니다.", "not_show_notice");
      } else {
        setNoticeDetailInfo({ ...res.data });
      }
    },
    onError: (error) => {
      alertModalOn(
        "잘못된 경로이거나 삭제된 공지사항입니다.",
        "not_show_notice"
      );
    },
  });

  function alertModalOn(msg: string, type?: string) {
    setAlertModal({
      visible: true,
      msg,
      type: type || "",
    });
  }
  function alertModalOff() {
    setAlertModal({
      visible: false,
      msg: "",
      type: "",
    });
  }

  function alertAction() {
    if (alertModal.type === "not_show_notice") {
      navigate(-1);
    }
  }

  React.useEffect(() => {
    if (noticeId && !isNaN(Number(noticeId))) {
      noticeMutate({ notice_id: Number(noticeId) });
    }
  }, [noticeId]);

  return (
    <FlexBox $py={4} $bgcolor={colors.WHITE} $isFullWidth>
      <ModalPortal>
        <AlertModal
          visible={alertModal.visible}
          modalOff={alertModalOff}
          msg={alertModal.msg}
          action={alertAction}
          type={alertModal.type}
        />
      </ModalPortal>
      <Box $px={2} $pb={2} $isFullWidth>
        <FlexBox
          $btw={1}
          $btc={colors.DEFAULT_BORDER_COLOR}
          $bbw={1}
          $bbc={colors.DEFAULT_BORDER_COLOR}
          $flexDirection="row"
          $ai="center"
          $isFullWidth
        >
          <FlexBox $isFullWidth $flexDirection="row" $ai="center">
            {/* <TableTitle title={"제목"} width={15} /> */}
            <TableContent
              isColumnCenter
              style={{ paddingTop: 12, paddingBottom: 12 }}
            >
              <FlexBox $isFullWidth $flexDirection="column" $gap={1.2}>
                <Typograpy fontStyle={fontStyle.headline1} fontWeight="Bold">
                  {noticeDetailInfo.notice_name}
                </Typograpy>
                <Typograpy color={colors.PRODUCT_SUB_FONT}>
                  작성일 : {convertUnixDate(noticeDetailInfo.created_at)}
                </Typograpy>
                {noticeDetailInfo.created_at !==
                  noticeDetailInfo.updated_at && (
                  <Typograpy>수정일 : {noticeDetailInfo.updated_at}</Typograpy>
                )}
              </FlexBox>
            </TableContent>
          </FlexBox>
        </FlexBox>
        <FlexBox
          $isFullWidth
          $flexDirection="row"
          $ai="stretch"
          $bbw={1}
          $bbc={colors.DEFAULT_BORDER_COLOR}
          $minHeight={40}
        >
          {/* <TableTitle title={"내용"} width={15} /> */}
          <TableContent>
            <FlexBox
              $isFullWidth
              $flexDirection="row"
              $ai={"center"}
              $gap={1.2}
            >
              <Box
                dangerouslySetInnerHTML={{
                  __html: noticeDetailInfo.notice_content,
                }}
              ></Box>
            </FlexBox>
          </TableContent>
        </FlexBox>
      </Box>
    </FlexBox>
  );
};
