import React from "react";
import { Box, FlexBox } from "theme/globalStyle";
import Typograpy from "../Typograpy";

interface LabelPropsType {
  label?: string;
  subLabel?: string;
  labelColor?: string;
  labelStyle?: string;
  isRequire?: boolean;
}

export const Label: React.FC<LabelPropsType> = ({
  label,
  subLabel,
  labelColor,
  labelStyle,
  isRequire,
}) => {
  return (
    <FlexBox $flexDirection="row" $ai="center" $mb={0.8} $gap={0.4}>
      <Typograpy fontStyle={labelStyle} color={labelColor} fontSize={16}>
        {label}{" "}
        {isRequire && (
          <Typograpy fontStyle={labelStyle} fontSize={16} color={"#EE4700"}>
            *
          </Typograpy>
        )}
      </Typograpy>
      {subLabel && (
        <Typograpy fontStyle={labelStyle} fontSize={11} color={"#A7A7A7"}>
          {subLabel}
        </Typograpy>
      )}
    </FlexBox>
  );
};
