import { create } from "zustand";
import { persist } from "zustand/middleware";

export interface AdminInfoType {
  admin_id: number;
  admin_account: string;
  password: string;
  admin_phone: string;
  admin_email: string;
  admin_name: string;
  admin_privilege: string;
  created_at: number;
  updated_at: number;
  type: "" | "ADMIN";
}

interface UserInfoStoreType extends AdminInfoType {
  updateAdminInfo: (userInfo: AdminInfoType) => void;
  deleteAdminInfo: () => void;
}

// 관리자 로그인 정보 저장 (sessionStorage 적용)
export const useAdminInfoStore = create<UserInfoStoreType>()(
  persist(
    (set) => ({
      admin_id: 0,
      admin_account: "",
      password: "",
      admin_phone: "",
      admin_email: "",
      admin_name: "",
      admin_privilege: "",
      created_at: 0,
      updated_at: 0,
      type: "",
      updateAdminInfo: (userInfo: AdminInfoType) => set({ ...userInfo }),
      deleteAdminInfo: () =>
        set({
          admin_id: 0,
          admin_account: "",
          password: "",
          admin_phone: "",
          admin_email: "",
          admin_name: "",
          admin_privilege: "",
          created_at: 0,
          updated_at: 0,
          type: "",
        }),
    }),
    {
      name: "admin-info-storage", // sessionStorage에 저장될 키
      getStorage: () => sessionStorage,
    }
  )
);
