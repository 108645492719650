import { AxiosError } from "axios";
import { OutlineButton } from "components/molecules/button/OutlineButton";
import { SolidButton } from "components/molecules/button/SolidButton";
import { Divider } from "components/molecules/divider/Divider";
import { PrimaryInput } from "components/molecules/Input/PrimaryInput";
import { Switch } from "components/molecules/switch/Switch";
import { TableContent } from "components/molecules/table/TableContent";
import { TableTitle } from "components/molecules/table/TableTitle";
import Typograpy from "components/molecules/Typograpy";
import { AlertModal } from "components/organisms/modal/AlertModal";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import React from "react";
import { Editor } from "react-draft-wysiwyg";
import { useAdminInfoStore } from "store/useAdminInfoStore";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { Box, FlexBox } from "theme/globalStyle";
import ModalPortal from "utils/Portal";
import htmlToDraft from "html-to-draftjs";
import draftjsToHtml from "draftjs-to-html";
import { useSnackbarStore } from "store/snackbarStore";
import { DetailTableRow } from "components/organisms/table/DetailTableRow";
import { GetNoticeResponse, useEditNotice, useGetNotice } from "api/api.notice";

interface NoticeDetailPropsType {
  notice_id: number;
  modalOff: () => void;
}

export const NoticeDetail: React.FC<NoticeDetailPropsType> = ({
  notice_id,
  modalOff,
}) => {
  const showSnackBar = useSnackbarStore((state) => state.showSnackbar);

  const { admin_id } = useAdminInfoStore((state) => state);

  //수정모드 - 에디터
  const [editorState, setEditorState] = React.useState<EditorState>(() =>
    EditorState.createEmpty()
  );

  const [noticeDetailInfo, setNoticeDetailInfo] = React.useState({
    notice_name: "",
    notice_contents: "",
    is_show: "N",
    notice_id: 0,
  });

  const [detailMode, setDetailMode] = React.useState<"detail" | "edit">(
    "detail"
  );

  const [inputError, setInputError] = React.useState({
    notice_name: false,
    notice_name_msg: "",
    notice_content: false,
    notice_content_msg: "",
  });

  const [alertModal, setAlertModal] = React.useState({
    visible: false,
    msg: "",
    type: "",
  });

  const { mutate: mutateNoticeDetail } = useGetNotice({
    onSuccess: loadDetailSuccess,
    onError: loadDetailFail,
  });
  const { mutate: mutateEditNotice } = useEditNotice({ notice_id });

  function loadDetailSuccess(res: GetNoticeResponse) {
    const { data: noticeData } = res;
    setNoticeDetailInfo({
      notice_name: noticeData.notice_name,
      notice_contents: noticeData.notice_content,
      is_show: noticeData.is_show,
      notice_id: noticeData.notice_id,
    });

    //수정모드 - 에디터 셋팅
    const contentState = ContentState.createFromBlockArray(
      htmlToDraft(noticeData.notice_content).contentBlocks
    );
    setEditorState(EditorState.createWithContent(contentState));
  }

  function loadDetailFail(error: AxiosError) {
    setAlertModal({
      visible: true,
      msg: `공지사항 등록에 실패하였습니다. \n ${error.message}`,
      type: "",
    });
  }

  //노출여부 수정
  function editShow() {
    mutateEditNotice(
      {
        admin_id,
        is_show: noticeDetailInfo.is_show === "Y" ? "N" : "Y",
        notice_content: noticeDetailInfo.notice_contents,
        notice_name: noticeDetailInfo.notice_name,
      },
      {
        onSuccess: () => {
          mutateNoticeDetail({ notice_id });
        },
        onError: () => {
          console.log("is error!");
        },
      }
    );
  }

  //editor 공백만 있을경우 및 trim처리
  const isEditorEmpty = (): boolean => {
    const currentContent = editorState.getCurrentContent();
    const plainText = currentContent.getPlainText(); // 실제 텍스트 추출
    return !plainText.trim(); // 공백만 있는지 확인
  };

  //수정전 유효성 검사
  const createNoticeVerify = () => {
    if (noticeDetailInfo.notice_name === "") {
      setInputError((prev) => ({
        ...prev,
        notice_name: true,
        notice_name_msg: "제목을 입력해주세요.",
      }));
      return false;
    } else if (isEditorEmpty()) {
      setInputError((prev) => ({
        ...prev,
        notice_content: true,
        notice_content_msg: "내용을 입력해주세요.",
      }));
    } else {
      return true;
    }
  };

  //게시물 수정
  function editContents() {
    if (createNoticeVerify()) {
      mutateEditNotice(
        {
          admin_id,
          is_show: noticeDetailInfo.is_show,
          notice_content: draftjsToHtml(
            convertToRaw(editorState.getCurrentContent())
          ).replaceAll("<p></p>", "<br />"),
          notice_name: noticeDetailInfo.notice_name,
        },
        {
          onSuccess: async () => {
            await mutateNoticeDetail({ notice_id });
            showSnackBar("수정이 완료되었습니다.");
            setDetailMode("detail");
          },
          onError: () => {
            console.log("is error!");
          },
        }
      );
    }
  }

  //수정모드일때
  function noticeInfoHandler(value: string, key?: string) {
    if (key) {
      setInputError({
        notice_name: false,
        notice_name_msg: "",
        notice_content: false,
        notice_content_msg: "",
      });
      setNoticeDetailInfo((prev) => ({ ...prev, [key]: value }));
    }
  }

  React.useEffect(() => {
    mutateNoticeDetail({ notice_id });
  }, [notice_id]);

  React.useEffect(() => {}, []);

  return (
    <FlexBox $isFullWidth>
      <ModalPortal>
        <AlertModal
          visible={alertModal.visible}
          msg={alertModal.msg}
          type={alertModal.type}
          modalOff={() => {
            setAlertModal({ visible: false, msg: "", type: "" });
          }}
        />
      </ModalPortal>
      <Box $px={2} $pb={2} $isFullWidth>
        <DetailTableRow
          isColumnCenter
          isBtw
          title={"제목"}
          contents={
            <FlexBox
              $isFullWidth
              $flexDirection="row"
              $ai={"center"}
              $gap={1.2}
            >
              {detailMode === "detail" ? (
                <Typograpy fontStyle={fontStyle.body2_normal} fontWeight="Bold">
                  {noticeDetailInfo.notice_name}
                </Typograpy>
              ) : (
                <PrimaryInput
                  value={noticeDetailInfo.notice_name}
                  onChangeValue={noticeInfoHandler}
                  placeholder="제목을 입력해주세요."
                  stateKey="notice_name"
                  errorMsg="제목을 입력해주세요."
                  $isError={inputError.notice_name}
                />
              )}
            </FlexBox>
          }
          contentsStyle={{ paddingTop: 0, paddingBottom: 0 }}
        />
        <DetailTableRow
          isColumnCenter
          title={"내용"}
          contents={
            <FlexBox
              $isFullWidth
              $flexDirection="row"
              $ai={"center"}
              $gap={1.2}
            >
              {detailMode === "detail" ? (
                <Box
                  dangerouslySetInnerHTML={{
                    __html: noticeDetailInfo.notice_contents,
                  }}
                ></Box>
              ) : (
                <Box>
                  <Editor
                    editorState={editorState}
                    onEditorStateChange={setEditorState}
                    toolbarClassName="tool-wrapper"
                    wrapperClassName="wrapper-area"
                    editorClassName="editor-area"
                    //   editorClassName="editor-wrapper"
                  />
                  {inputError.notice_content && (
                    <Box $mt={1.2}>
                      <Typograpy
                        fontStyle={fontStyle.caption1}
                        color={colors.RED}
                      >
                        내용을 입력해주세요.
                      </Typograpy>
                    </Box>
                  )}
                </Box>
              )}
            </FlexBox>
          }
        />
      </Box>
      <Divider variant="normal" width={"100%"} />
      <FlexBox
        $px={2}
        $pt={2}
        $flexDirection="row"
        $jc={"space-between"}
        $ai={"center"}
        $isFullWidth
      >
        <FlexBox $flexDirection="row" $gap={0.8} $ai={"center"}>
          <Typograpy fontStyle={fontStyle.label1_reading}>노출여부</Typograpy>
          <Switch
            $bgcolor={colors.ADMIN_MAIN_COLOR}
            $checked={noticeDetailInfo.is_show === "Y"}
            onClick={() => {
              if (detailMode === "detail") {
                editShow();
              } else {
                noticeInfoHandler(
                  noticeDetailInfo.is_show === "Y" ? "N" : "Y",
                  "is_show"
                );
              }
            }}
            size="medium"
          />
        </FlexBox>
        <FlexBox $flexDirection="row" $gap={0.8}>
          <OutlineButton
            buttonLabel="닫기"
            bgcolor={colors.WHITE}
            bc={colors.ADMIN_MAIN_COLOR}
            fontStyle={`color : ${colors.ADMIN_MAIN_COLOR}`}
            size={"large"}
            $br={4}
            onClick={modalOff}
          />
          <SolidButton
            buttonLabel={detailMode === "detail" ? "수정" : "완료"}
            size={"large"}
            $br={4}
            bgcolor={colors.ADMIN_MAIN_COLOR}
            // isLoading={isPending}
            onClick={() => {
              if (detailMode === "detail") {
                setDetailMode("edit");
              } else {
                editContents();
              }
            }}
          />
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};
