import { useMutation } from "@tanstack/react-query";
import { useGetSallerInfo } from "api/api.salesmanager";
import { baseAxios } from "api/baseAxios";
import { AxiosError, AxiosResponse } from "axios";
import { AdminInfoType, useAdminInfoStore } from "store/useAdminInfoStore";
import { UserInfoType, useUserInfoStore } from "store/userInfoStore";
import { isDateInRange } from "utils/func";

interface CheckTokenParams {}

export interface CheckTokenResponse {
  status: number;
  message: string;
  data: UserInfoType | AdminInfoType;
}

interface CheckTokenProps {
  goHome?: () => void;
  onError: (error: AxiosError) => void;
}

//토큰 검사
export const useCheckToken = ({ goHome, onError }: CheckTokenProps) => {
  const { updateUserInfo } = useUserInfoStore((state) => state);
  const { updateAdminInfo } = useAdminInfoStore((state) => state);

  return useMutation<CheckTokenResponse, AxiosError, CheckTokenParams>({
    mutationKey: ["checkToken"],
    mutationFn: () => baseAxios.post("/auth/check").then((res) => res.data),
    onSuccess: (data) => {
      if (data.data.type === "ADMIN") {
        updateAdminInfo(data.data as AdminInfoType);
        // navigate("/");
        if (goHome) goHome();
      } else {
        let userInfo = data.data as UserInfoType;
        if (userInfo.payment) {
          //구독여부 분기
          if (userInfo.payment.length > 0) {
            const filterPaymentInfo = userInfo.payment.filter((el) => {
              if (el.payment_state === "READY") {
                return false;
              } else {
                if (!el.expiration_date || el.expiration_date === null) {
                  return true;
                } else {
                  return isDateInRange(el.created_at || 0, el.expiration_date);
                }
              }
            });

            userInfo = {
              ...userInfo,
              payment: filterPaymentInfo,
            };
          }
        }
        updateUserInfo({
          ...userInfo,
          type: "SALESMANAGER",
        });
        if (goHome) goHome();
      }
    },
    onError,
  });
};
