import Typograpy from "components/molecules/Typograpy";
import React from "react";
import { Outlet } from "react-router";
import { colors } from "theme/colors";
import { Box, FlexBox, MainMaxWidth } from "theme/globalStyle";

export const NoticePage = () => {
  return (
    <FlexBox $isFullWidth $ai="center" $minHeight={70}>
      <FlexBox
        $isFullWidth
        $py={4}
        $ai="center"
        $jc="center"
        $bgcolor={colors.USER_HEADER_BG}
      >
        <Typograpy
          fontSize={45}
          fontWeight="Bold"
          color={colors.WHITE}
          style={{ lineHeight: "4.8rem" }}
        >
          공지사항
        </Typograpy>
      </FlexBox>
      <Box $isFullWidth $maxWidth={MainMaxWidth}>
        <Outlet />
      </Box>
    </FlexBox>
  );
};
