import { Divider } from "components/molecules/divider/Divider";
import SwiperCarousel from "components/molecules/SwiperCarousel/Index";
import Typograpy from "components/molecules/Typograpy";
import { CompanyInfo } from "components/organisms/footer/CompanyInfo";
import { NoticeBar } from "components/organisms/footer/NoticeBar";
import { Header } from "components/organisms/header/Header";
import { TopMenu } from "components/organisms/header/TopMenu";
import { SalesDescription } from "components/organisms/main/salesDescription/SalesDescription";
import React from "react";
import { Outlet } from "react-router";
import { colors } from "theme/colors";
import { Box, FlexBox, MainMaxWidth } from "theme/globalStyle";
import { descriptionList } from "utils/list";

export const NotFound = () => {
  return (
    <FlexBox $isFullWidth $ai="center">
      <Box $isFullWidth $maxWidth={MainMaxWidth}>
        <Header />
      </Box>
      <Divider variant="normal" width={"100%"} />
      <Box $isFullWidth $maxWidth={MainMaxWidth}>
        <TopMenu />
      </Box>

      <FlexBox $isFullWidth $ai="center" $minHeight={70}>
        <FlexBox
          $isFullWidth
          $py={10}
          $ai="center"
          $jc="center"
          $bgcolor={colors.USER_HEADER_BG}
        >
          <Typograpy
            fontSize={45}
            fontWeight="Bold"
            color={colors.WHITE}
            style={{ lineHeight: "4.8rem" }}
          >
            페이지를 찾을 수 없습니다.
          </Typograpy>
        </FlexBox>
        <Box $isFullWidth $maxWidth={"100%"}>
          <SwiperCarousel autoplay isLoop>
            {descriptionList.map((item, index) => {
              return (
                <Box $isFullWidth key={"description" + index}>
                  <SalesDescription
                    isBgGray={false}
                    desInfo={item}
                    key={"mainDes" + index}
                  />
                </Box>
              );
            })}
          </SwiperCarousel>
        </Box>
      </FlexBox>
      <NoticeBar />
      {/* <FooterTab /> */}
      <CompanyInfo />
    </FlexBox>
  );
};
