import { IconOutlineLoading } from "components/atoms/icon/animated";
import React from "react";
import { FlexBox } from "theme/globalStyle";

const LoadingComponent = () => {
  return (
    <FlexBox $isFullWidth $ai={"center"}>
      <IconOutlineLoading />
    </FlexBox>
  );
};

export default LoadingComponent;
