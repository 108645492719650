import { OutlineButton } from "components/molecules/button/OutlineButton";
import { SolidButton } from "components/molecules/button/SolidButton";
import React from "react";
import { Box, FlexBox } from "theme/globalStyle";

import SmileIcon from "assets/img/smile.png";
import { Icon } from "components/molecules/icon/Icon";
import { Divider } from "components/molecules/divider/Divider";
import { Radio } from "components/molecules/radio/Radio";
import { SolidCheckbox } from "components/molecules/checkbox/SolidCheckkbox";
import { RoundCheckbox } from "components/molecules/checkbox/RoundCheckbox";
import { JustCheckbox } from "components/molecules/checkbox/JustCheckbox";
import { Switch } from "components/molecules/switch/Switch";
import { PrimaryTab } from "components/molecules/tab/PrimaryTab";
import { SecondaryTab } from "components/molecules/tab/SecondaryTab";
import { ScrollableTab } from "components/molecules/tab/ScrollableTab";
import { PrimaryInput } from "components/molecules/Input/PrimaryInput";
import { BorderDeleteIcon, IcSearch } from "components/atoms/icon/icon";
import Typograpy from "components/molecules/Typograpy";
import { Textarea } from "components/molecules/textarea/TextArea";
import { SelectBox } from "components/molecules/selectbox/SelectBox";
import { fontStyle } from "theme/fontStyle";
import { AlertModal } from "components/organisms/modal/AlertModal";
import { Menu } from "components/molecules/menu/Menu";
import { useSnackbarStore } from "store/snackbarStore";
import { Description } from "components/organisms/description/Description";

export const TextPage = () => {
  const [isCheck, setIsCheck] = React.useState(false);

  const [input, setInput] = React.useState("");
  const [alertModal, setAlertModal] = React.useState(false);

  const [activeTab, setActiveTab] = React.useState(0);

  const primaryItems = ["탭 이름", "탭 이름", "탭 이름", "탭 이름", "이름"];
  const secondaryItems = ["주간", "월간"];

  const menuList = [
    {
      label: "메뉴1",
      action: () => {
        alert("is action!");
      },
    },
    { label: "메뉴12", action: () => {} },
    { label: "메뉴133", action: () => {} },
    { label: "메뉴1466", action: () => {} },
    { label: "메뉴15777", action: () => {} },
  ];

  const showSnackbar = useSnackbarStore((state) => state.showSnackbar);

  return (
    <FlexBox $flexDirection="row">
      <AlertModal
        msg={"이용동의가 철회되었습니다."}
        visible={alertModal}
        modalOff={() => setAlertModal(false)}
      />
      <FlexBox $flex={1}>
        <Typograpy fontSize={30} fontWeight="Bold">
          Molecules
        </Typograpy>
        <Box>
          <SolidButton size={"large"} buttonLabel="확인" />
          <SolidButton buttonLabel="확인" />
          <SolidButton size={"small"} buttonLabel="확인" />
        </Box>

        <Box>
          <OutlineButton buttonLabel="Label" size={"large"} />
          <OutlineButton buttonLabel="Label" />
          <OutlineButton buttonLabel="Label" size={"small"} />
        </Box>

        <Box>
          <OutlineButton buttonLabel="Label" type={"pri"} />
          <OutlineButton buttonLabel="Label" type={"sec"} />
          <OutlineButton buttonLabel="Label" type={"assis"} />
          <OutlineButton buttonLabel="Label" disable />
        </Box>

        <Box>
          <Icon width={2} imgLink={SmileIcon} type={"normal"} />
          <Icon width={2} imgLink={SmileIcon} type={"background"} />
          <Icon width={2} imgLink={SmileIcon} type={"outline"} />
          <Icon width={2} imgLink={SmileIcon} type={"solid"} />
        </Box>

        <Box>
          <Icon width={2} disabled imgLink={SmileIcon} type={"normal"} />
          <Icon width={2} disabled imgLink={SmileIcon} type={"background"} />
          <Icon width={2} disabled imgLink={SmileIcon} type={"outline"} />
          <Icon width={2} disabled imgLink={SmileIcon} type={"solid"} />
        </Box>

        <Box width={"100%"}>
          <Divider variant="normal" width={"100%"} />
          <Box $ml={3}>
            <Divider isVertical variant="normal" height={4} />
          </Box>
          <Divider variant="thick" width={"100%"} height={2} />
        </Box>

        <Box $ml={2} $mt={2}>
          <SolidCheckbox
            name={"test_check"}
            value={"aa"}
            onChange={(e) => {
              setIsCheck(e.target.checked);
            }}
            $checked={isCheck}
            label={"사각형~"}
          />
          <RoundCheckbox
            name={"test_check"}
            value={"aa"}
            onChange={(e) => {
              setIsCheck(e.target.checked);
            }}
            $checked={isCheck}
            label={"둥근형~"}
          />
          <JustCheckbox
            name={"test_check"}
            value={"aa"}
            onChange={(e) => {
              setIsCheck(e.target.checked);
            }}
            $checked={isCheck}
            label={"체크만~"}
          />
        </Box>

        <Box $ml={2} $mt={2}>
          <SolidCheckbox
            name={"test_check"}
            value={"aa"}
            onChange={(e) => {
              setIsCheck(e.target.checked);
            }}
            $checked={isCheck}
            label={"사각형~"}
            disabled
          />
          <RoundCheckbox
            name={"test_check"}
            value={"aa"}
            onChange={(e) => {
              setIsCheck(e.target.checked);
            }}
            $checked={isCheck}
            label={"둥근형~"}
            disabled
          />
          <JustCheckbox
            name={"test_check"}
            value={"aa"}
            onChange={(e) => {
              setIsCheck(e.target.checked);
            }}
            $checked={isCheck}
            label={"체크만~"}
            disabled
          />
        </Box>

        <Box>
          <Radio name="size" value="normal" label="normal" size="normal" />
          <Radio name="size" value="small" label="small" size="small" />
          <br />
          <Radio name="state" value="unchecked" label="unchecked" />
          <Radio
            name="state"
            value="checked"
            label="checked"
            checked={isCheck}
            onChange={(e) => {
              setIsCheck(e.target.checked);
            }}
          />
          <br />
          <Radio name="disable" value="false" label="false" />
          <Radio name="disable" value="true" label="true" disabled />
          <br />
          <Radio
            name="interaction"
            value="normal"
            label="normal"
            interaction="normal"
          />
          <Radio
            name="interaction"
            value="hovered"
            label="hovered"
            interaction="hovered"
          />
          <Radio
            name="interaction"
            value="focused"
            label="focused"
            interaction="focused"
          />
          <Radio
            name="interaction"
            value="pressed"
            label="pressed"
            interaction="pressed"
          />
        </Box>

        <Box>
          <Switch
            size={"medium"}
            $checked={isCheck}
            onClick={() => {
              setIsCheck(!isCheck);
            }}
          />
          <Switch
            size={"medium"}
            $checked={!isCheck}
            onClick={() => {
              setIsCheck(!isCheck);
            }}
          />
        </Box>

        <FlexBox $gap={2}>
          <PrimaryTab
            items={primaryItems}
            activeTab={activeTab}
            onTabChange={setActiveTab}
          />
          <SecondaryTab
            items={secondaryItems}
            activeTab={activeTab}
            onTabChange={setActiveTab}
          />
          <ScrollableTab
            items={primaryItems}
            activeTab={activeTab}
            onTabChange={setActiveTab}
          />
        </FlexBox>

        <FlexBox $mt={2} $ml={2} $gap={1}>
          <PrimaryInput
            value={input}
            onChangeValue={setInput}
            placeholder="플레이스홀더"
            $isError={false}
            rightIcon={<BorderDeleteIcon />}
            label={"탭이름"}
          />
          <PrimaryInput
            value={input}
            onChangeValue={setInput}
            placeholder="플레이스홀더"
            $isError={false}
            isRound
            leftIcon={<IcSearch />}
            rightIcon={<BorderDeleteIcon />}
            label={"탭이름"}
            isRequire
          />
          <PrimaryInput
            value={input}
            onChangeValue={setInput}
            placeholder="에러"
            $isError={true}
            rightIcon={<BorderDeleteIcon />}
          />
          <PrimaryInput
            value={input}
            onChangeValue={setInput}
            placeholder="플레이스홀더"
            readonly
            rightIcon={<BorderDeleteIcon />}
          />
          <Textarea
            value={input}
            onChangeValue={setInput}
            placeholder="textarea"
          />

          <SelectBox
            onChangeValue={setInput}
            optList={[
              "aa",
              "bb",
              "cc",
              "dd",
              "gg",
              "ee",
              "hh",
              "11",
              "22",
              "33",
            ]}
            placeholder="플레이스"
            selOpt={input}
          />
        </FlexBox>
      </FlexBox>
      <FlexBox $flex={1}>
        <Typograpy fontStyle={fontStyle.display1} fontWeight="Bold">
          Organisms
        </Typograpy>

        <FlexBox $gap={1} $flexDirection="row">
          <Box
            $bgcolor="rgba(112, 115, 124, 0.08)"
            width={4.8}
            height={4.8}
            $shadowType="normal"
          />
          <Box
            $bgcolor="rgba(112, 115, 124, 0.08)"
            width={4.8}
            height={4.8}
            $shadowType="emphasize"
          />
          <Box
            $bgcolor="rgba(112, 115, 124, 0.08)"
            width={4.8}
            height={4.8}
            $shadowType="strong"
          />
          <Box
            $bgcolor="rgba(112, 115, 124, 0.08)"
            width={4.8}
            height={4.8}
            $shadowType="heavy"
          />

          <SolidButton
            onClick={() => {
              setAlertModal(true);
            }}
            buttonLabel="모달켜기"
            size={"large"}
          />
        </FlexBox>
        <FlexBox $mt={2} $flexDirection="row">
          <Menu menuList={menuList} isLeft>
            <Typograpy fontSize={16}>메뉴on</Typograpy>
          </Menu>
          <Menu menuList={menuList} isRight>
            <Typograpy fontSize={16}>메뉴on</Typograpy>
          </Menu>
        </FlexBox>
        <FlexBox>
          <SolidButton
            buttonLabel="토스트 on with action"
            size={"medium"}
            onClick={() => {
              showSnackbar("안녕하세요!", "취소", () => {
                alert("ddd");
              });
            }}
          />
          <OutlineButton
            buttonLabel="토스트 on only msg"
            size={"large"}
            onClick={() => {
              showSnackbar("이건 메세지만!!!");
            }}
          />
        </FlexBox>
        <FlexBox $gap={1} $mt={1}>
          {/* <Pagination
            btnType="dot"
            onChangePage={() => {}}
            rowCount={60}
            rowsPerPage={5}
            
          />

          <Pagination
            btnType="number"
            onChangePage={() => {}}
            rowCount={60}
            rowsPerPage={5}
          /> */}

          <Description
            desList={[
              "상세 설명 상세 설명 상세 설명 상세 설명 상세 설명",
              "상세 설명 상세 설명 상세 설명 상세 설명 상세 설명 상세 설명 상세 설명 상세 설명 상세 설명 상세 설명 상세 설명 상세 설명 상세 설명 상세 설명 상세 설명",
              "상세 설명 상세 설명 상세 설명 상세 설명 상세 설명 상세 설명 상세 설명 상세 설명 상세 설명 상세 설명 상세 설명",
            ]}
          />
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};
