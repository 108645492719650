import { MainSideBar } from "components/organisms/sidebar/saller/MainSideBar";
import { SubSideBar } from "components/organisms/sidebar/saller/SubSideBar";
import React from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { FlexBox } from "theme/globalStyle";
import { motion, AnimatePresence, LayoutGroup } from "framer-motion";
import { AdminSubMenuListType } from "utils/list";
import { AdminMainSideBar } from "components/organisms/sidebar/admin/AdminMainSideBar";
import Typograpy from "components/molecules/Typograpy";
import { fontStyle } from "theme/fontStyle";
import { colors } from "theme/colors";
import { Header } from "components/organisms/header/admin/Header";
import { useTokenStore } from "store/useTokenStore";
import { useAdminInfoStore } from "store/useAdminInfoStore";
import { NoticeBar } from "components/organisms/footer/NoticeBar";
import { CompanyInfo } from "components/organisms/footer/CompanyInfo";

export interface ToggleSubSideBarType {
  visible: boolean;
  label: keyof AdminSubMenuListType;
}

export const AdminStaticArea = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentPath = location.pathname.replace(/^\/admin\/?/, "");
  const [title, setTitle] = React.useState("");

  const { token } = useTokenStore((state) => state);
  const { admin_id } = useAdminInfoStore((state) => state);

  const [toggleSubSideBar, setToggleSubSideBar] =
    React.useState<ToggleSubSideBarType>({
      visible: false,
      label: "홈",
    });

  const [selSubPage, setSelSubPage] = React.useState("");

  const variants = {
    //subside 토글
    hidden: { width: 0, opacity: 0 },
    visible: { width: "auto", opacity: 1 },
  };

  React.useEffect(() => {
    if (token === "" || admin_id === 0) {
      navigate("/");
    }
    if (currentPath === "") {
      navigate("/admin/home", { replace: true });
    }
  }, [currentPath]);

  React.useEffect(() => {
    if (currentPath === "home") {
      setTitle("대시보드");
    } else if (currentPath === "sallerList") {
      setTitle("설계사목록");
    } else if (currentPath === "env") {
      setTitle("설정");
    } else if (currentPath === "notice") {
      setTitle("공지사항 관리");
    } else if (currentPath === "coupon") {
      setTitle("쿠폰관리");
    } else if (currentPath === "qna") {
      setTitle("문의사항");
    }
  }, [currentPath]);

  React.useEffect(() => {
    console.log(
      `%c
    ██████╗ ██╗     ██╗   ██╗███████╗███████╗ █████╗ ██╗     ███████╗███████╗
    ██╔══██╗██║     ██║   ██║██╔════╝██╔════╝██╔══██╗██║     ██╔════╝██╔════╝
    ██████╔╝██║     ██║   ██║█████╗  ███████╗███████║██║     █████╗  ███████╗
    ██╔══██╗██║     ██║   ██║██╔══╝  ╚════██║██╔══██║██║     ██╔══╝  ╚════██║
    ██████╔╝███████╗╚██████╔╝███████╗███████║██║  ██║███████╗███████╗███████║
    ╚═════╝ ╚══════╝ ╚═════╝ ╚══════╝╚══════╝╚═╝  ╚═╝╚══════╝╚══════╝╚══════╝

     █████╗ ██████╗ ███╗   ███╗██╗███╗   ██╗
    ██╔══██╗██╔══██╗████╗ ████║██║████╗  ██║
    ███████║██║  ██║██╔████╔██║██║██╔██╗ ██║
    ██╔══██║██║  ██║██║╚██╔╝██║██║██║╚██╗██║
    ██║  ██║██████╔╝██║ ╚═╝ ██║██║██║ ╚████║
    ╚═╝  ╚═╝╚═════╝ ╚═╝     ╚═╝╚═╝╚═╝  ╚═══╝
        `,
      "color: #5C60C8; font-weight: bold; font-size: 10px;"
    );

    //     console.log(
    //       `%c
    // |￣￣￣￣￣￣￣|
    // | 500 𝙎𝙄𝘽𝘼𝙇 |
    // |＿＿＿＿＿＿＿| 프론트는 잘못없어 응애~
    // (\__/) ||
    // (•ㅅ•).||
    // / . . . .づ
    //         `,
    //       "color: #5C60C8; font-weight: bold; font-size: 14px;"
    //     );
  }, []);

  return (
    <LayoutGroup>
      <FlexBox $isFullWidth height={"100%"} $minHeight={"100vh"}>
        {/* <Saller /> */}
        <FlexBox
          $flexDirection="row"
          $ai={"stretch"}
          $flex={1}
          $isFullWidth
          height={"100%"}
        >
          <AdminMainSideBar
            toggleSubSideBar={toggleSubSideBar}
            setToggleSubSideBar={setToggleSubSideBar}
            adminType="admin"
          />
          <FlexBox $isFullWidth $flexDirection="column" $minHeight={"100%"}>
            <Header title={title} />
            <Outlet
              context={{
                selSubPage: selSubPage,
              }}
            />
          </FlexBox>
        </FlexBox>
      </FlexBox>
      {/* <NoticeBar /> */}
      {/* <FooterTab /> */}
      <CompanyInfo />
    </LayoutGroup>
  );
};
