import { baseAxios } from "api/baseAxios";
import axios from "axios";
import React from "react";
import { useTokenStore } from "store/useTokenStore";

interface VerifySmsParamsType {
  access_token: string;
  mobile_num: string;
  sms_msg: string;
  callback_num: string;
}

export const verifySms = (params: VerifySmsParamsType) =>
  baseAxios
    .post(`/auth/verify/sms`, {
      ...params,
    })
    .then((res) => res.data);
