import {
  deleteNotice,
  GetNoticeListDataType,
  useGetNoticeList,
} from "api/api.notice";
import { TableTd, TableTr, TableWrapper } from "components/atoms/table/table";
import { SolidButton } from "components/molecules/button/SolidButton";
import Typograpy from "components/molecules/Typograpy";
import {
  BoardFilterType,
  BoardListFilter,
} from "components/organisms/filter/BoardListFilter";
import { AlertModal } from "components/organisms/modal/AlertModal";
import { NoticeModal } from "components/organisms/modal/NoticeModal";
import { NoticeTableHeader } from "components/organisms/notice/NoticeTableHeader";
import { Pagination } from "components/organisms/pagination/Pagination";
import React from "react";
import { useSnackbarStore } from "store/snackbarStore";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { Box, FlexBox, MainMaxWidth } from "theme/globalStyle";
import { convertUnixDate } from "utils/func";
import ModalPortal from "utils/Portal";

const NoticeShowState = ({ isShow }: { isShow: boolean }) => {
  return (
    <FlexBox
      $br={2}
      $bw={1}
      $bc={isShow ? colors.GREEN : colors.RED}
      $py={0.4}
      width={6}
      $ai={"center"}
    >
      <Typograpy fontSize={11} color={isShow ? colors.GREEN : colors.RED}>
        {isShow ? "노출" : "비노출"}
      </Typograpy>
    </FlexBox>
  );
};

export const AdminNoticeTemplate = () => {
  const {
    data: noticeData,
    isLoading: noticeLoading,
    isError: noticeIsError,
    error: noticeError,
    refetch: noticeRefetch,
  } = useGetNoticeList();

  const showSnackbar = useSnackbarStore((state) => state.showSnackbar);
  //공지사항 리스트
  const [noticeList, setNoticeList] = React.useState<GetNoticeListDataType[]>(
    []
  );
  const [selNoticeId, setSelNoticeId] = React.useState(0);

  const [boardFilter, setBoardFilter] = React.useState<BoardFilterType>({
    state: "전체",
    keyword: "",
    date_start: "",
    date_end: "",
  });

  const boardFilterHandler = (value: string, key?: string) => {
    if (key) {
      setBoardFilter({
        ...boardFilter,
        [key]: value,
      });
    }
  };

  const [noticePage, setNoticePage] = React.useState(1);
  const startPost = (noticePage - 1) * 10 + 1;
  const endPost = startPost + 10 - 1;

  const [noticeModal, setNoticeModal] = React.useState<{
    visible: boolean;
    type: "" | "create" | "detail";
  }>({
    visible: false,
    type: "",
  });

  const [alertModal, setAlertModal] = React.useState({
    visible: false,
    msg: "",
    type: "",
  });

  function noticeModalOn(type: "" | "create" | "detail") {
    setNoticeModal({
      visible: true,
      type,
    });
  }

  function alertModalOn(msg: string, type?: string) {
    setAlertModal({
      visible: true,
      msg,
      type: type || "",
    });
  }

  function alertAction() {
    if (alertModal.type === "notice_delete_confirm") {
      deleteNotice(selNoticeId).then((res) => {
        const { status, message } = res;
        if (status === 200) {
          showSnackbar("공지사항 삭제가 완료되었습니다.");
          noticeRefetch();
        } else {
          showSnackbar(`공지사항 삭제에 실패하였습니다.\n\n ${message}`);
          noticeRefetch();
        }
      });
    }
  }

  const applyFilters = () => {
    if (!noticeData) return;

    let filtered = [...noticeData.data];

    if (boardFilter.keyword) {
      filtered = filtered.filter((item) =>
        item.notice_name
          .toLowerCase()
          .includes(boardFilter.keyword.toLowerCase())
      );
    }

    if (boardFilter.date_start) {
      filtered = filtered.filter(
        (item) =>
          new Date(convertUnixDate(item.created_at || 0)) >=
          new Date(boardFilter.date_start)
      );
    }

    if (boardFilter.date_end) {
      filtered = filtered.filter(
        (item) =>
          new Date(convertUnixDate(item.created_at || 0)) <=
          new Date(boardFilter.date_end)
      );
    }

    setNoticeList(filtered);
  };

  React.useEffect(() => {
    if (noticeData) {
      setNoticeList([...noticeData.data.reverse()]);
    }
  }, [noticeData]);

  React.useEffect(() => {
    if (!noticeModal.visible) {
      noticeRefetch();
    }
  }, [noticeModal.visible]);

  return (
    <FlexBox
      $isFullWidth
      $ai={"center"}
      $bgcolor={colors.ADMIN_SEARCH_BG}
      height={"100%"}
    >
      <ModalPortal>
        <NoticeModal
          visible={noticeModal.visible}
          type={noticeModal.type}
          modalOff={() => {
            setNoticeModal({ visible: false, type: "" });
          }}
          noticeId={selNoticeId}
        />
      </ModalPortal>
      <ModalPortal>
        <AlertModal
          visible={alertModal.visible}
          type={alertModal.type}
          modalOff={() => {
            setAlertModal({ visible: false, type: "", msg: "" });
          }}
          action={alertAction}
          msg={alertModal.msg}
        />
      </ModalPortal>
      <FlexBox
        $isFullWidth
        $maxWidth={`calc(${MainMaxWidth}rem + 4rem)`}
        $pa={4}
      >
        <FlexBox
          $isFullWidth
          $flexDirection="row"
          $jc={"space-between"}
          $ai={"center"}
        >
          <BoardListFilter
            boardFilter={boardFilter}
            boardFilterHandler={boardFilterHandler}
            applyFilters={applyFilters}
          />
          <SolidButton
            buttonLabel="새 공지사항"
            $br={6}
            bgcolor={colors.ADMIN_MAIN_COLOR}
            size={"large"}
            onClick={() => {
              noticeModalOn("create");
            }}
          />
        </FlexBox>
        <FlexBox
          $flexDirection="row"
          $ai={"stretch"}
          $isFullWidth
          $gap={1.6}
          $mt={2}
        >
          <FlexBox $pa={2} $bgcolor={colors.WHITE} $isFullWidth>
            <TableWrapper>
              <thead>
                <TableTr>
                  <NoticeTableHeader title={"공지번호"} width={"10%"} />
                  <NoticeTableHeader title={"상태"} width={"10%"} />
                  <NoticeTableHeader title={"공지명"} width={"50%"} />
                  <NoticeTableHeader title={"등록일"} width={"20%"} />
                  <NoticeTableHeader title={""} width={"10%"} />
                  {/* <NoticeTableHeader title={"작성자"} width={"10%"} /> */}
                </TableTr>
              </thead>
              {noticeList.length > 0 && (
                <tbody>
                  {noticeList
                    .slice(startPost - 1, endPost)
                    .map((item, index) => {
                      return (
                        <TableTr key={"notice" + index}>
                          <TableTd>
                            <Typograpy
                              fontStyle={fontStyle.caption1}
                              color={colors.BLACK}
                            >
                              {item.notice_id}
                            </Typograpy>
                          </TableTd>
                          <TableTd>
                            <FlexBox
                              $px={0.8}
                              $py={0.4}
                              $jc={"center"}
                              $ai={"center"}
                            >
                              <NoticeShowState isShow={item.is_show === "Y"} />
                            </FlexBox>
                          </TableTd>
                          <TableTd>
                            <Box
                              $isPointer
                              onClick={() => {
                                setSelNoticeId(item.notice_id);
                                setNoticeModal({
                                  visible: true,
                                  type: "detail",
                                });
                              }}
                            >
                              <Typograpy
                                fontStyle={fontStyle.caption1}
                                color={colors.BLACK}
                                hoverColor={colors.ADMIN_MAIN_COLOR}
                              >
                                {item.notice_name}
                              </Typograpy>
                            </Box>
                          </TableTd>
                          <TableTd>
                            <Typograpy
                              fontStyle={fontStyle.caption1}
                              color={colors.BLACK}
                            >
                              {convertUnixDate(item.created_at)}
                            </Typograpy>
                          </TableTd>
                          <TableTd>
                            <FlexBox $isFullWidth $ai={"center"}>
                              <SolidButton
                                onClick={() => {
                                  setSelNoticeId(item.notice_id);
                                  alertModalOn(
                                    "해당 공지사항을 삭제하시겠습니까?",
                                    "notice_delete_confirm"
                                  );
                                }}
                                buttonLabel="삭제"
                                $br={4}
                                size={"small"}
                              />
                            </FlexBox>
                          </TableTd>
                        </TableTr>
                      );
                    })}
                </tbody>
              )}
            </TableWrapper>
            {noticeList.length === 0 && (
              <FlexBox $isFullWidth $ai={"center"} $jc={"center"} $mt={2}>
                <Typograpy>생성된 공지사항이 존재하지 않습니다.</Typograpy>
              </FlexBox>
            )}
            <FlexBox $isFullWidth $ai={"center"}>
              <Pagination
                page={noticePage}
                setPage={setNoticePage}
                rowCount={noticeList.length}
                rowPerPage={10}
              />
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};
