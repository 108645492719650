import { useUpdateSalesmanager } from "api/api.salesmanager";
import { verifyPassword } from "api/api.salesmanager.find";
import { IcEyeOff, IcEyeOn } from "components/atoms/icon/icon";
import { SolidButton } from "components/molecules/button/SolidButton";
import { Divider } from "components/molecules/divider/Divider";
import { PrimaryInput } from "components/molecules/Input/PrimaryInput";
import Typograpy from "components/molecules/Typograpy";
import React from "react";
import { useSnackbarStore } from "store/snackbarStore";
import { useLoadingStore } from "store/useLoadingStore";
import { useUserInfoStore } from "store/userInfoStore";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { Box, FlexBox } from "theme/globalStyle";

const ChangePasswordTemplate = () => {
  const sallerInfo = useUserInfoStore((state) => state);
  const showSnackBar = useSnackbarStore((state) => state.showSnackbar);
  const { updateLoading } = useLoadingStore((state) => state);

  const { mutate: changePasswordMutate, isPending: changePasswordPending } =
    useUpdateSalesmanager({
      salesmanager_id: sallerInfo.salesmanager_id,
    });

  const [changeInfo, setChangeInfo] = React.useState({
    password_prev: "",
    password: "",
    password_re: "",
  });

  const [inputError, setInputError] = React.useState({
    password_prev: false,
    password_prev_msg: "",
    password: false,
    password_msg: "",
    password_re: false,
    password_re_msg: "",
  });

  const [isInputShow, setIsInputShow] = React.useState({
    password_prev: false,
    password: false,
    password_re: false,
  });

  const [isCapslock, setIsCapslock] = React.useState(false);

  function inputHandler(value: string, key?: string) {
    if (key) {
      setChangeInfo((prev) => ({
        ...prev,
        [key]: value,
      }));
    }
  }

  function verifyPasswordAccess() {
    return verifyPassword({
      salesmanager_id: sallerInfo.salesmanager_id,
      salesmanager_password: changeInfo.password_prev,
    }).then((data) => {
      if (!data.isValid) {
        setInputError((prev) => ({
          ...prev,
          password_prev: true,
          password_prev_msg: "기존 비밀번호가 일치하지 않습니다.",
        }));
      }
      return data.isValid;
    });
  }

  function verificationHandler() {
    if (changeInfo.password_prev === "") {
      setInputError((prev) => ({
        ...prev,
        password_prev: true,
        password_prev_msg: "기존 비밀번호를 입력해주세요.",
      }));
      return false;
    } else if (changeInfo.password === "") {
      setInputError((prev) => ({
        ...prev,
        password: true,
        password_msg: "변경할 비밀번호를 입력해주세요.",
      }));
      return false;
    } else if (changeInfo.password_re === "") {
      setInputError((prev) => ({
        ...prev,
        password_re: true,
        password_re_msg: "변경할 비밀번호를 한번 더 입력해주세요.",
      }));
      return false;
    } else if (changeInfo.password !== changeInfo.password_re) {
      setInputError((prev) => ({
        ...prev,
        password_re: true,
        password_re_msg: "비밀번호가 일치하지 않습니다.",
      }));
      return false;
    }

    return true;
  }

  async function accessChangePassword() {
    if (!verificationHandler()) return;
    if (!(await verifyPasswordAccess())) return;

    changePasswordMutate(
      {
        salesmanager_password: changeInfo.password,
      },
      {
        onSuccess: () => {
          showSnackBar("비밀번호 변경이 완료되었습니다.");
          setChangeInfo({
            password: "",
            password_prev: "",
            password_re: "",
          });
        },
      }
    );
  }

  React.useEffect(() => {
    updateLoading(changePasswordPending);
  }, [changePasswordPending]);

  return (
    <FlexBox $isFullWidth $ai="center">
      <FlexBox
        $isFullWidth
        $flexDirection="row"
        $jc="space-between"
        $ai="center"
        height={6}
        $bbw={1}
        $bbc={colors.DEFAULT_BORDER_COLOR}
        $pl={2}
        $pr={4}
      >
        <Typograpy
          fontStyle={fontStyle.body1_normal}
          fontWeight="SemiBold"
          color={colors.SALLER_SUBSIDE_TITLE}
        >
          패스워드 변경
        </Typograpy>
      </FlexBox>

      <FlexBox $maxWidth={60} $my={4} $px={2} $isFullWidth>
        <FlexBox $isFullWidth $gap={1.6}>
          <PrimaryInput
            value={changeInfo.password_prev}
            placeholder="기존 비밀번호를 입력해주세요."
            onChangeValue={(value, key) => {
              inputHandler(value, key);
              setInputError((prev) => ({
                ...prev,
                password_prev: false,
                password_prev_msg: "",
              }));
            }}
            label={"기존 비밀번호"}
            stateKey="password_prev"
            inputType={isInputShow.password_prev ? "text" : "password"}
            rightIcon={isInputShow.password_prev ? <IcEyeOn /> : <IcEyeOff />}
            iconAction={() => {
              setIsInputShow((prev) => ({
                ...prev,
                password_prev: !prev.password_prev,
              }));
            }}
            capsLockCheck={(capslock) => {
              setIsCapslock(capslock);
            }}
            $isError={inputError.password_prev}
            errorMsg={inputError.password_prev_msg}
          />
          <PrimaryInput
            value={changeInfo.password}
            placeholder="새 비밀번호를 입력해주세요."
            onChangeValue={(value, key) => {
              inputHandler(value, key);
              setInputError((prev) => ({
                ...prev,
                password: false,
                password_msg: "",
              }));
            }}
            label={"새 비밀번호"}
            stateKey="password"
            inputType={isInputShow.password ? "text" : "password"}
            rightIcon={isInputShow.password ? <IcEyeOn /> : <IcEyeOff />}
            iconAction={() => {
              setIsInputShow((prev) => ({
                ...prev,
                password: !prev.password,
              }));
            }}
            capsLockCheck={(capslock) => {
              setIsCapslock(capslock);
            }}
            $isError={inputError.password}
            errorMsg={inputError.password_msg}
          />
          <PrimaryInput
            value={changeInfo.password_re}
            placeholder="비밀번호를 한번 더 입력해주세요."
            onChangeValue={(value, key) => {
              inputHandler(value, key);
              setInputError((prev) => ({
                ...prev,
                password_re: false,
                password_re_msg: "",
              }));
            }}
            label={"비밀번호 확인"}
            stateKey="password_re"
            inputType={isInputShow.password_re ? "text" : "password"}
            rightIcon={isInputShow.password_re ? <IcEyeOn /> : <IcEyeOff />}
            iconAction={() => {
              setIsInputShow((prev) => ({
                ...prev,
                password_re: !prev.password_re,
              }));
            }}
            capsLockCheck={(capslock) => {
              setIsCapslock(capslock);
            }}
            $isError={inputError.password_re}
            errorMsg={inputError.password_re_msg}
          />
          {isCapslock && (
            <Box>
              <Typograpy fontStyle={fontStyle.caption1} color={colors.RED}>
                Capslock이 켜져 있습니다.
              </Typograpy>
            </Box>
          )}

          <Divider
            variant="thick"
            width={"100%"}
            $bgcolor={colors.MAIN}
            height={0.2}
          />
          <SolidButton
            style={{
              width: "100%",
              paddingTop: "1.6rem",
              paddingBottom: "1.6rem",
            }}
            buttonLabel="변경하기"
            onClick={accessChangePassword}
          />
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};

export default ChangePasswordTemplate;
