import { useMutation } from "@tanstack/react-query";
import { CheckTokenResponse, useCheckToken } from "./CheckToken";
import { baseAxios } from "api/baseAxios";
import { AdminInfoType, useAdminInfoStore } from "store/useAdminInfoStore";
import { UserInfoType, useUserInfoStore } from "store/userInfoStore";
import { useTokenStore } from "store/useTokenStore";
import { useGetSallerInfo } from "api/api.salesmanager";
import { isDateInRange } from "utils/func";

//토큰 리프레쉬
export const useRefreshToken = () => {
  const { mutate: checkTokenMutate } = useCheckToken({
    onError: () => {},
  });

  return useMutation({
    mutationKey: ["refreshToken"],
    mutationFn: () => {
      return baseAxios
        .post("/auth/renewal/token")
        .then((res) => res.data)
        .then((data) => {
          useTokenStore.getState().setToken(data.data.token);
          checkTokenMutate(data.data.token);
        });
    },
  });
};
