export const colors = {
  BLACK: "#000",
  WHITE: "#FFF",
  GREEN: "#00BB2A",
  RED: "#FF4242",
  BLUE: "#06F",
  MAIN: "#5C60C8",
  CANCLE: "#9397A1",
  REQUIRE_COLOR: "#D33444",
  REQUIRE_FONT_COLOR: "rgba(102, 102, 102, 0.61)",

  DEFAULT_FONT_COLOR: "#171719",
  DEFAULT_BORDER_COLOR: "rgba(112, 115, 124, 0.22)",

  CAPTION_COLOR: "#A7A7A7",

  //button
  BTN_MAIN_COLOR: "#5C60C8",
  BTN_GRAY_COLOR: "rgba(112, 115, 124, 0.22)",
  BTN_DIS_COLOR: "#F4F4F5",

  SOLID_BTN_FONT: "#FFF",
  SOLID_DIS_BTN_FONT: "rgba(55, 56, 60, 0.28)",
  ASSIS_BTN_FONT: "#171719",

  //icon
  ICON_BACK: "#eaebeb",
  ICON_OUTLINE: "rgba(112, 115, 124, 0.22)",
  ICON_SOLID: "#06F",
  ICON_SOLID_DIS: "rgba(112, 115, 124, 0.08)",

  //divider
  DIVIDER_COLOR: "rgba(112, 115, 124, 0.22)",

  //check_box
  CHECK_IC_GRAY: "rgba(55, 56, 60, 0.28)",

  //switch
  SWITCH_OFF_BACK: "rgba(112, 115, 124, 0.16)",
  SWITCH_ON_BACK: "#06F",
  SWITCH_SALLER_ON_BACK: "#5B5FC7",

  //tab
  TAB_BORDER_BOTTOM: "#EBEEF1",
  TAB_ON: "#06F",
  TAB_OFF: "#9397A1",

  SEC_TAB_BORDER: "#E2E5EB",
  SEC_TAB_ON: "#222222",
  SEC_TAB_ON_FONT: "#fff",
  SEC_TAB_OFF_FONT: "#9397A1",

  //input
  INPUT_BACK: "#FFF",
  INPUT_DIS_BACK: "#F7F7F8",
  INPUT_PLACEHOLDER: "rgba(55, 56, 60, 0.28)",
  INPUT_BORDER: "rgba(112, 115, 124, 0.22)",
  INPUT_FOCUS_BORDEDR: "#06F",
  INPUT_ERROR_BORDER: "#FF4242",

  //selectbox
  SELECTBOX_BORDER: "rgba(112, 115, 124, 0.22)",
  SELECTBOX_FOCUS: "#0066FF",
  SELECT_FONT: "#06F",
  SELECTBOX_SEL_BG: "#F7F7F8",
  SELCTBOX_PLACEHOLDER: "rgba(55, 56, 60, 0.28)",

  //overlay menu
  SUBMENU_BORDER: "#E2E5EB",

  //snackbar
  SNACKBAR_BG: "#222222",
  SNACKBAR_FONT: "#FFF",
  SNACKBAR_ACTION: "#9397A1",

  //pagination
  PAGINATION_NOW_BG: "#EAF2FE",
  PAGINATION_NOW_FONT: "#0066FF",
  PAGINATION_BG: "#F7F7F8",
  PAGINATION_FONT: "rgba(55, 56, 60, 0.28)",

  PAGINATION_DOT: "#E2E5EB",
  PAGINATION_NOW_DOT: "#1C6BFF",

  DESCRIPTION_BG: "#FBFBFD",
  DESCRIPTION_FONT: "#ABB0B9",
  DESCRIPTION_TOP_BORDER: "#EBEEF1",

  //HEADER
  NONE_LOGIN_GRAY: "rgba(55, 56, 60, 0.61)",

  //MAIN
  MAIN_INTRODUCE_BG: "#FAFAFA",
  MAIN_CATEGORY_FONT: "#5C60C8",
  MAIN_TITLE_FONT: "#111111",
  MAIN_SUB_FONT: "#646464",

  PRODUCT_BORDER: "rgba(112, 115, 124, 0.22)",
  PRODUCT_BRAND_FONT: "rgba(55, 56, 60, 0.61)",
  PRODUCT_SUB_FONT: "rgba(55, 56, 60, 0.61)",
  PRODUCT_NONE_SALE_FONT: "rgba(55, 56, 60, 0.28)",
  PRODUCT_SAEL_COUNT: "#FF5E00",

  PRODUCT_TAG_BLUE: "#06F",
  PRODUCT_TAG_GRAY: "#B0B0B0",

  REVIEW_CARD_BG: "#F3F4F6",
  REVIEW_CARD_ID: "#5C60C8",
  REVIEW_SEL_BORDER: "rgba(112, 115, 124, 0.52)",
  NOTICE_MORE_FONT: "rgba(55, 56, 60, 0.61)",

  //login
  USER_HEADER_BG: "#5B5FC7",

  //join
  TYPE_SEL_BG: "#EBECFF",
  TYPE_SEL_BORDER: "#5B5FC7",
  TYPE_SEL_FONT: "#5B5FC7",
  TYPE_SEL_SUB_FONT: "#9A9BBC",

  TYPE_SEL_NONE_BG: "#FAFAFA",
  TYPE_SEL_NONE_BORDER: "#9C9EC4",
  TYPE_SEL_NONE_FONT: "#A3A3A3",
  TYPE_SEL_NONE_SUB_FONT: "#A6A6A6",

  SUBSCRIBE_INSURANCE: "#2699FB",
  SUBSCRIBE_FINACE: "#5B5FC7",
  SUBSCRIBE_PREMIUM: "#B85AEA",

  SUBSCRIBE_ENG_NAME: "#898989",
  SUBSCRIBE_SUB_NAME: "#505050",
  SUBSCRIBE_BENEFIT: "#6C6C6C",

  //footer
  FOOTER_DIVIDER: "#242424",
  COPYRIGHT_FONT: "rgba(46, 47, 51, 0.88)",

  //saller
  SALLER_HEADER: "#06F",
  SALLER_SUBSIDE_BG: "#F5F5F5",
  SALLER_SUBSIDE_TITLE: "#242424",
  SALLER_SUBSIDE_NONE_SEL_TITLE: "#424242",
  SALLER_DETAIL_CONTENT: "#242424",
  SALLER_DETAIL_TITLE_BG: "#F4F4F5",

  SALLER_DESCRIPTION: "#616161",

  SALLER_TAB_SEL: "#4D4D4D",
  SALLER_TAB_NONE_SEL: "rgba(77, 77, 77, 0.8)",

  INSURANCE_TITLE: "#333333",
  INSURANCE_CONTENT: "#111111",

  FILE_NAME_FONT: "#7B7B7B",

  //admin
  ADMIN_MAIN_COLOR: "#1777FF",
  ADMIN_SIDEBAR: "#011529",
  ADMIN_SIDEBAR_SEL: "#1777FF",
  ADMIN_SEARCH_BG: "#FAFAFA",
  ADMIN_CUSTOMER_SEL_BG: "#E7F6FF",
  ADMIN_CUSTOMER_SEL_BORDER: "#78C7FF",

  ADMIN_COUPON_PROCESS: "#2699FB",
  ADMIN_COUPON_PROCESS_FONT: "",
  ADMIN_COUPON_END: "#F3F3F3",
  ADMIN_COUPON_END_FONT: "#989898",

  //table
  TABLE_FONT: "#242424",
  REFUSE_BTN: "#EFB3B3",
  ACCEPT_BTN: "#7CC27A",

  TABLE_HEADER_BACK: "#EEEEEE",

  NAVER_BG: "#03C75A",
  KAKAO_BG: "#FEE500",
};
