import { CreateSubProductParamsWithType } from "api/api.subproduct";
import { IcEnv, IcTrash, IcTrashBlack } from "components/atoms/icon/icon";
import { SolidButton } from "components/molecules/button/SolidButton";
import Typograpy from "components/molecules/Typograpy";
import React from "react";
import { useAdminInfoStore } from "store/useAdminInfoStore";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { Box, FlexBox } from "theme/globalStyle";
import { getMonthLabel, NumberComma } from "utils/func";
import { SubscribeType } from "utils/list";

interface SubscribePlanCardProps {
  subscribeInfo: CreateSubProductParamsWithType;
  isSetting?: boolean;
  setManageSubModal?: (modalInfo: {
    visible: boolean;
    sub_product_id: number;
    type: "create" | "edit";
  }) => void;
}

//회원가입 - 구독플랜 카드
export const SubscribePlanCard: React.FC<SubscribePlanCardProps> = ({
  subscribeInfo,
  isSetting,
  setManageSubModal,
}) => {
  const cardColor =
    subscribeInfo.product_type === "insurances"
      ? colors.SUBSCRIBE_INSURANCE
      : subscribeInfo.product_type === "finance"
      ? colors.SUBSCRIBE_FINACE
      : colors.SUBSCRIBE_PREMIUM;

  return (
    <FlexBox
      $flex={1}
      $px={2}
      $py={3}
      $bc={cardColor}
      $br={10}
      $btw={3}
      $bbw={1}
      $blw={1}
      $brw={1}
      style={{
        opacity: 1,
      }}
    >
      <FlexBox $flexDirection="row" $isFullWidth $jc={"space-between"}>
        <FlexBox>
          <Typograpy
            fontStyle={fontStyle.label2}
            color={colors.SUBSCRIBE_ENG_NAME}
          >
            {subscribeInfo.product_type.charAt(0).toUpperCase() +
              subscribeInfo.product_type.slice(1)}
          </Typograpy>
          <Typograpy
            fontStyle={fontStyle.title3}
            color={cardColor}
            fontWeight="Bold"
          >
            {subscribeInfo.sub_product_name}
          </Typograpy>
        </FlexBox>
        {isSetting && (
          <FlexBox $flexDirection="row" $gap={0.4}>
            <Box
              $isPointer
              onClick={() => {
                if (setManageSubModal)
                  setManageSubModal({
                    visible: true,
                    sub_product_id: subscribeInfo.sub_product_id || 0,
                    type: "edit",
                  });
              }}
            >
              <IcEnv width={2} height={2} />
            </Box>
            {/* <Box $isPointer>
              <IcTrashBlack width={2} height={2} />
            </Box> */}
          </FlexBox>
        )}
      </FlexBox>
      <Box $mt={0.4}>
        <Typograpy
          fontStyle={fontStyle.label2}
          color={colors.SUBSCRIBE_SUB_NAME}
        >
          {subscribeInfo.sub_product_subname}
        </Typograpy>
      </Box>

      <FlexBox $mt={2}>
        <Box
          dangerouslySetInnerHTML={{
            __html: subscribeInfo.sub_product_content,
          }}
        ></Box>
      </FlexBox>

      <Box
        style={{ textAlign: "center" }}
        $mt={2}
        $isFullWidth
        $br={5}
        $bgcolor={cardColor}
        $py={0.5}
      >
        <Typograpy
          fontStyle={fontStyle.label2}
          color={colors.WHITE}
          fontWeight="Bold"
        >
          {`${NumberComma(subscribeInfo.sub_product_price)}원 / ${getMonthLabel(
            subscribeInfo.validity_term
          )}`}
        </Typograpy>
        {/* <SolidButton
          buttonLabel={`${NumberComma(subscribeInfo.price)}원 / 1년`}
          $br={5}
          style={{
            backgroundColor: cardColor,
            width: "100%",
            paddingTop: "0.5rem",
            paddingBottom: "0.5rem",
          }}
          onClick={() => {}}
        /> */}
      </Box>
    </FlexBox>
  );
};
