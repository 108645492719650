import Typograpy from "components/molecules/Typograpy";
import { Divider } from "components/molecules/divider/Divider";
import { AlertModal } from "components/organisms/modal/AlertModal";
import React from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useSnackbarStore } from "store/snackbarStore";
import { useTokenStore } from "store/useTokenStore";
import { useUserInfoStore } from "store/userInfoStore";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { Box, FlexBox } from "theme/globalStyle";
import ModalPortal from "utils/Portal";

export const SallerHeader = () => {
  const navigate = useNavigate();

  const showSnackBar = useSnackbarStore((state) => state.showSnackbar);
  const { salesmanager_name, deleteUserInfo } = useUserInfoStore(
    (state) => state
  );
  const { setToken } = useTokenStore((state) => state);

  const [alertModal, setAlertModal] = React.useState({
    visible: false,
    msg: "",
    type: "",
  });

  const alertModalOn = (msg: string, type?: string) => {
    setAlertModal({
      visible: true,
      msg,
      type: type || "",
    });
  };

  const alertAction = () => {
    if (alertModal.type === "logout_confirm") {
      deleteUserInfo();
      setToken("");
      showSnackBar("로그아웃이 완료되었습니다.");
      navigate("/");
    }
  };

  function logoutHandler() {
    alertModalOn("정말 로그아웃 하시겠습니까?", "logout_confirm");
  }

  return (
    <FlexBox
      $flexDirection="row"
      $jc="space-between"
      $ai="center"
      $isFullWidth
      $bgcolor={colors.MAIN}
      $py={1.6}
      $pl={2.4}
      $pr={4}
    >
      <ModalPortal>
        <AlertModal
          visible={alertModal.visible}
          msg={alertModal.msg}
          type={alertModal.type}
          action={alertAction}
          modalOff={() => {
            setAlertModal({ visible: false, msg: "", type: "" });
          }}
        />
      </ModalPortal>
      {/* <Box
        $isPointer
        onClick={() => {
          navigate("/");
        }}
      > */}
      <Link to={"/"}>
        <Typograpy
          fontStyle={fontStyle.heading2}
          color={colors.WHITE}
          fontWeight="ExtraBold"
        >
          Blue
          <Typograpy
            fontStyle={fontStyle.heading2}
            color={colors.WHITE}
            fontWeight="Regular"
          >
            sale
          </Typograpy>
        </Typograpy>
      </Link>
      {/* </Box> */}
      <FlexBox $flexDirection="row" $ai="center" $gap={0.8}>
        <Box $isPointer>
          <Typograpy fontStyle={fontStyle.label1_normal} color={colors.WHITE}>
            {salesmanager_name}님 환영합니다.
          </Typograpy>
        </Box>
        <Divider
          isVertical
          variant="normal"
          height={1}
          $bgcolor={colors.WHITE}
        />
        <Box
          $isPointer
          onClick={() => {
            logoutHandler();
          }}
        >
          <Typograpy fontStyle={fontStyle.label1_normal} color={colors.WHITE}>
            로그아웃
          </Typograpy>
        </Box>

        <Divider
          isVertical
          variant="normal"
          height={1}
          $bgcolor={colors.WHITE}
        />
        <Box
          $isPointer
          onClick={() => {
            navigate("/notice");
          }}
        >
          <Typograpy fontStyle={fontStyle.label1_normal} color={colors.WHITE}>
            공지사항
          </Typograpy>
        </Box>
      </FlexBox>
    </FlexBox>
  );
};
