import styled from "styled-components";
import { colors } from "theme/colors";
import { FlexBox } from "theme/globalStyle";

interface StylesSelectBoxProps {
  $disable?: boolean;
}

const StyleSelectBoxWrapper = styled(FlexBox)`
  border-radius: 4px;
  border: 1px solid ${colors.SELECTBOX_BORDER};
  flex-direction: column;

  &:focus {
    border: 1px solid ${colors.SELECTBOX_FOCUS};
  }
  width: 100%;
`;

const StyleSelectBox = styled(FlexBox)<StylesSelectBoxProps>`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1.1rem 1.2rem 1.1rem 1.4rem;
  width: 100%;
  cursor: ${({ $disable }) => ($disable ? "not-allowed" : "pointer")};
  background-color: ${({ $disable }) => ($disable ? "#e3e3e3" : colors.WHITE)};
`;

const StyleSelectOptBox = styled(FlexBox)`
  z-index: 999;
  border-radius: 4px;
  border: 1px solid ${colors.SELECTBOX_BORDER};
  margin-top: 0.2rem;

  position: absolute;
  width: 100%;

  max-height: 25rem;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-button {
    display: none;
  }

  &::-webkit-scrollbar-track {
    background-color: ${colors.WHITE};
  }
  &::-webkit-scrollbar-thumb {
    background-color: ${colors.TAB_BORDER_BOTTOM};
    border-radius: 4px;
  }
`;

const StyleSelectOption = styled(FlexBox)<{ $isSel?: boolean }>`
  width: 100%;
  padding: 1.1rem 1.2rem 1.1rem 1.4rem;
  background-color: ${({ $isSel }) =>
    $isSel ? colors.SELECTBOX_SEL_BG : "#fff"};
  border-bottom: 1px solid ${colors.SELECTBOX_BORDER};
  cursor: pointer;
`;

export {
  StyleSelectBoxWrapper,
  StyleSelectBox,
  StyleSelectOptBox,
  StyleSelectOption,
};
