import { IcSearch } from "components/atoms/icon/icon";
import { OutlineButton } from "components/molecules/button/OutlineButton";
import { SolidButton } from "components/molecules/button/SolidButton";
import { PrimaryInput } from "components/molecules/Input/PrimaryInput";
import { SelectBox } from "components/molecules/selectbox/SelectBox";
import React from "react";
import { colors } from "theme/colors";
import { Box, FlexBox } from "theme/globalStyle";
import searchIcon from "assets/img/ic_search.svg";

export interface BoardFilterType {
  state: string;
  keyword: string;
  date_start: string;
  date_end: string;
}

interface BoardListFilterProps {
  boardFilter: BoardFilterType;
  boardFilterHandler: (value: string, key?: string) => void;
  stateList?: { label: string; value: string }[];
  applyFilters: () => void;
}

export const BoardListFilter: React.FC<BoardListFilterProps> = ({
  boardFilter,
  boardFilterHandler,
  stateList,
  applyFilters,
}) => {
  return (
    <FlexBox $flexDirection="row" $ai={"flex-end"} $gap={1.2}>
      {stateList && (
        <SelectBox
          optList={stateList.map((el) => el.label)}
          onChangeValue={(value) => {
            boardFilterHandler(value, "state");
          }}
          placeholder="선택하세요."
          boxStyle={{ minWidth: "12rem" }}
          label={"상태"}
          fontStyle="font-size:1.3rem;"
          selOpt={boardFilter.state}
        />
      )}
      <PrimaryInput
        label={"검색어"}
        onChangeValue={(value) => {
          boardFilterHandler(value, "keyword");
        }}
        placeholder="검색어를 입력해주세요."
        value={boardFilter.keyword}
        inputStyle={{ height: "4.2rem", width: "20rem" }}
      />
      <PrimaryInput
        label={"검색시작일"}
        onChangeValue={(startDate) => {
          boardFilterHandler(startDate, "date_start");
        }}
        placeholder="0000-00-00"
        value={boardFilter.date_start}
        inputStyle={{ height: "4.2rem", width: "15rem" }}
        inputType="date"
        max={boardFilter.date_end}
      />
      <PrimaryInput
        label={"검색종료일"}
        onChangeValue={(endDate) => {
          boardFilterHandler(endDate, "date_end");
        }}
        placeholder="0000-00-00"
        value={boardFilter.date_end}
        inputStyle={{ height: "4.2rem", width: "15rem" }}
        inputType="date"
        min={boardFilter.date_start}
      />

      <OutlineButton
        buttonLabel="검색"
        $br={4}
        style={{ height: "4.2rem" }}
        bc={colors.DEFAULT_BORDER_COLOR}
        fontStyle="color:#171719; font-family:Regular;"
        leftIcon={searchIcon}
        onClick={() => {
          applyFilters();
        }}
      />
    </FlexBox>
  );
};
