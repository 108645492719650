import Typograpy from "components/molecules/Typograpy";
import { Divider } from "components/molecules/divider/Divider";
import { QrCodeCom } from "components/molecules/qrcode/QrCode";
import React from "react";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { Box, FlexBox, MainMaxWidth } from "theme/globalStyle";

const TitleFont = ({ label }: { label: string }) => {
  return (
    <Typograpy
      fontStyle={fontStyle.label1_reading}
      color={colors.DEFAULT_FONT_COLOR}
    >
      {label} :
    </Typograpy>
  );
};

const ContentFont = ({ label }: { label: string }) => {
  return (
    <Typograpy
      fontStyle={fontStyle.label1_reading}
      color={colors.DEFAULT_FONT_COLOR}
    >
      {label}
    </Typograpy>
  );
};

export const CompanyInfo = () => {
  const navigate = useNavigate();

  return (
    <FlexBox
      $py={2}
      $bgcolor={colors.MAIN_INTRODUCE_BG}
      $ai="center"
      $isFullWidth
      $btc={colors.DEFAULT_BORDER_COLOR}
      $btw={1}
    >
      <FlexBox
        $maxWidth={MainMaxWidth}
        $isFullWidth
        $flexDirection="row"
        $jc="space-between"
        $ai="center"
      >
        <FlexBox>
          <Box $mb={0.8}>
            <Typograpy
              fontStyle={fontStyle.title2}
              color={colors.DEFAULT_FONT_COLOR}
              fontWeight="Bold"
            >
              BLUELIONS
            </Typograpy>
          </Box>
          <FlexBox $flexDirection="row" $ai="center" $gap={0.8}>
            <TitleFont label={"상호명"} />
            <ContentFont label={"주식회사 블루라이언스"} />
            <Divider
              variant="normal"
              height={1}
              isVertical
              $bgcolor={colors.FOOTER_DIVIDER}
            />
            <TitleFont label={"대표자명"} />
            <ContentFont label={"지환국"} />
          </FlexBox>
          <FlexBox $flexDirection="row" $gap={0.8} $ai={"center"}>
            <TitleFont label={"사업장 주소"} />
            <ContentFont
              label={"부산광역시 남구 문현금융로40, 부산국제금융센터 21층"}
            />
            <Divider
              variant="normal"
              height={1}
              isVertical
              $bgcolor={colors.FOOTER_DIVIDER}
            />
            <TitleFont label={"사업자 등록번호"} />
            <ContentFont label={" 658-86-02418"} />
          </FlexBox>
          <FlexBox $flexDirection="row" $ai="center" $gap={0.8}>
            <TitleFont label={"대표 전화"} />
            <ContentFont
              label={"051-747-8563 (영업시간 - 평일 09:30 ~ 19:00)"}
            />
            <Divider
              variant="normal"
              height={1}
              isVertical
              $bgcolor={colors.FOOTER_DIVIDER}
            />
            <Link to={"/agree/privacy"}>
              <Typograpy
                fontWeight="SemiBold"
                fontStyle={fontStyle.label1_reading}
                color={colors.DEFAULT_FONT_COLOR}
              >
                개인정보처리방침
              </Typograpy>
            </Link>
            <Divider
              variant="normal"
              height={1}
              isVertical
              $bgcolor={colors.FOOTER_DIVIDER}
            />
            <Link to={"/agree/service"}>
              <Typograpy
                fontWeight="SemiBold"
                fontStyle={fontStyle.label1_reading}
                color={colors.DEFAULT_FONT_COLOR}
              >
                서비스 이용약관
              </Typograpy>
            </Link>
          </FlexBox>
        </FlexBox>
      </FlexBox>
      <Box $isFullWidth $maxWidth={MainMaxWidth} $my={2}>
        <Divider variant="normal" width={"100%"} />
      </Box>

      <FlexBox
        $isFullWidth
        $maxWidth={MainMaxWidth}
        $flexDirection="row"
        $jc="space-between"
      >
        <Typograpy
          fontStyle={fontStyle.caption1}
          fontWeight="Medium"
          color={colors.COPYRIGHT_FONT}
        >
          Copyright © 블루라이언스 | 블루세일. All Rights Reserved.
        </Typograpy>
        {/* <FlexBox $flexDirection="row" $gap={0.8}>
          <Typograpy
            fontStyle={fontStyle.caption1}
            fontWeight="Medium"
            color={colors.COPYRIGHT_FONT}
          >
            모바일 앱으로 만나보세요.
          </Typograpy>
          <QrCodeCom qrData="https://bluelions.kr/" />
        </FlexBox> */}
      </FlexBox>
    </FlexBox>
  );
};
