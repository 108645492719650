import React from "react";
import Modal from "react-modal";

import { CommonModalType } from "./commonModalType";
import { Box, FlexBox } from "theme/globalStyle";
import Typograpy from "components/molecules/Typograpy";
import { IcClose } from "components/atoms/icon/icon";
import { fontStyle } from "theme/fontStyle";
import { Divider } from "components/molecules/divider/Divider";
import { SolidButton } from "components/molecules/button/SolidButton";
import { SolidCheckbox } from "components/molecules/checkbox/SolidCheckkbox";
import { colors } from "theme/colors";
import useScrollLock from "hooks/useScrollLock";
import { CommonModal } from "./CommonModal";
import { useUserInfoStore } from "store/userInfoStore";
import { updatePayment } from "api/api.payment";
import { useRefreshToken } from "api/auth/RefreshToken";
import { useSnackbarStore } from "store/snackbarStore";
import { convertUnixDate, formatDate, getUnixDate } from "utils/func";

interface CancleSubscribeModalProps extends CommonModalType {
  payment_id?: number | "";
}

export const CancleSubscribeModal: React.FC<CancleSubscribeModalProps> = ({
  visible,
  modalOff,
  payment_id,
}) => {
  const { showSnackbar } = useSnackbarStore((state) => state);
  const [cancleAgree, setCancleAgree] = React.useState(false);
  const { payment } = useUserInfoStore((state) => state);
  const { mutate: refreshToken } = useRefreshToken();

  const cancleSubscribeHanlder = async () => {
    if (payment_id !== 0) {
      let selPaymentInfo = payment.filter(
        (el) => el.payment_id === payment_id
      )[0];

      const selPaymentIndex = payment.findIndex(
        (el) => el.payment_id === payment_id
      );

      const startDate = new Date(
        convertUnixDate(selPaymentInfo.created_at || 0)
      );
      const endDate = new Date(startDate);
      endDate.setMonth(
        startDate.getMonth() +
          selPaymentInfo.sub_product_information.validity_term
      );
      endDate.setDate(endDate.getDate() - 1);
      const [endYear, endMonth, endDay] = formatDate(endDate).split(".");
      const endUnixDate = getUnixDate(endYear, endMonth, endDay);

      selPaymentInfo = {
        ...selPaymentInfo,
        expiration_date: endUnixDate,
      };
      const updateResult = await updatePayment(payment_id || 0, selPaymentInfo);

      const { status, message, data } = updateResult;

      if (status === 200) {
        showSnackbar("구독취소 신청이 완료되었습니다.");
        refreshToken();
        modalOff();
      } else {
        showSnackbar(`${message}\n구독취소에 실패하였습니다.`);
      }
    }
  };

  useScrollLock(visible);
  React.useEffect(() => {
    if (visible) setCancleAgree(false);
  }, [visible]);

  return (
    <CommonModal
      visible={visible}
      modalOff={modalOff}
      customStyle={{
        overlay: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0,0,0,0.4)",
          zIndex: 999,
        },
        content: {
          width: "30%",
          //   height: "70%",
          position: "relative",
          padding: 0,
          paddingTop: "2rem",
          paddingBottom: "2rem",
          inset: 0,
        },
      }}
    >
      <FlexBox
        $isFullWidth
        $flexDirection="row"
        $jc={"space-between"}
        $ai={"center"}
        $px={2}
        $pb={2}
      >
        <Typograpy fontStyle={fontStyle.heading2}>{"구독(결제)취소"}</Typograpy>

        <Box $isPointer onClick={modalOff}>
          <IcClose />
        </Box>
      </FlexBox>
      <Divider variant="normal" width={"100%"} />
      <FlexBox $pa={2} $gap={1.2}>
        <Typograpy fontStyle={fontStyle.headline1}>구독해지 안내</Typograpy>
        <Typograpy
          style={{ whiteSpace: "pre-wrap" }}
          fontStyle={fontStyle.body1_reading}
        >
          {`다음 년도부터 정기 구독을 원하지 않을 경우, 구독을 해지할 수 있습니다. 
구독 해지는 구독 상품을 결제한 해지 절차를 통해 진행할 수 있습니다. 
구독을 해지할 경우, 구독 만료 예정일까지 구독 상품사용이 가능합니다. `}
        </Typograpy>
        <SolidCheckbox
          fontStyle={fontStyle.headline1}
          fontColor={colors.BLACK}
          name={"cancle_agree"}
          value={"Y"}
          onChange={(e) => {
            setCancleAgree(e.target.checked);
          }}
          $checked={cancleAgree}
          label={"동의합니다."}
        />
      </FlexBox>

      <Divider variant="normal" width={"100%"} />
      <FlexBox
        $isFullWidth
        $flexDirection="row"
        $jc={"flex-end"}
        $pt={2}
        $px={2}
      >
        <SolidButton
          buttonLabel="구독취소"
          $br={4}
          size="large"
          disable={!cancleAgree}
          onClick={cancleSubscribeHanlder}
        />
      </FlexBox>
    </CommonModal>
  );
};
