import {
  AgreeTableHeader,
  AgreeTableTd,
  AgreeTableTr,
  AgreeTableWrapper,
} from "components/atoms/table/table";
import Typograpy from "components/molecules/Typograpy";
import React from "react";
import { fontStyle } from "theme/fontStyle";
import { Box, FlexBox } from "theme/globalStyle";

export const PrivacyAgree = () => {
  return (
    <FlexBox $gap={1.2} $mb={4}>
      <Typograpy fontStyle={fontStyle.title3} fontWeight="Bold">
        개인정보처리방침(블루세일)
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        2024.09.15
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`주식회사 블루라이언스(이하 “회사”)는 「신용정보의 이용 및 보호에 관한법률」, 「전자금융거래법」, 「정보통신망 이용촉진 및 정보보호 등에 관한법률」, 「개인정보 보호법」 등 관련 법규에 따라 이용자의 개인정보 및신용정보를 보호하고 개인신용정보와 관련한 이용자의 고충을 원활하게처리할 수 있도록 본 개인정보처리방침에 따라 개인신용정보를 처리하고있습니다. 회사가 개인정보처리방침을 개정하는 경우에는 홈페이지에게시하거나 개별적으로 공지할 것입니다. `}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        제1조 (개인신용정보 처리 목적과처리 항목)
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`회사는 서비스 이용에 반드시 필요한 개인신용정보를 다음과 같은목적을 위하여 처리합니다. 처리하는 개인신용정보는 다음의 목적 이외의용도로는 처리되지 않으며, 처리 목적과 항목이 변경되는 경우에는 별도의동의를 받는 등 필요한 조치를 이행할 것입니다. 
회사는 서비스 이용에 필요한 최소한의 개인신용정보만을 수집하고 있습니다. 따라서, 필수항목만을 처리하고 있으며, 선택 항목은 없습니다. 
처음으로 이용하는 서비스의 경우 회사는 이용자에게 필수적인 정보의 입력을 요청할 수 있으며,회사가 이미 보유한 정보가 있다면 입력을 생략하거나 이용자를 위하여 입력편의성을 제공할 수 있습니다. 
회사는 대부분의 서비스에 대하여 ‘취소’ 또는 ‘철회’ 기능을 제공함으로써 이미 동의한 개인신용정보의 수집과 이용에 대하여 언제든지 취소하고 삭제할 수 있는 방법을 제공하고 있습니다. 다만, 관련 법령에 따라 개인신용정보가 삭제되지 않을 수도 있습니다. 
회사는제공하는 핵심 서비스와 직접 관련된 경우만을 선별하여 필수적인 동의로 처리하고 있습니다. 하지만, 개인신용정보가 곧바로 처리되는 것이 아니라, 정보주체가 특정 메뉴를 선택하고 추가적인 정보를 입력한 후에 비로소 개인신용정보의 처리가 시작됨을 알려 드립니다.`}
      </Typograpy>

      <AgreeTableWrapper>
        {/* <AgreeTableHeader> */}
        <AgreeTableTr>
          <AgreeTableHeader $tdWidth={"10%"}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              fontWeight="SemiBold"
            >
              No
            </Typograpy>
          </AgreeTableHeader>
          <AgreeTableHeader $tdWidth={"60%"}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              fontWeight="SemiBold"
            >
              처리목적
            </Typograpy>
          </AgreeTableHeader>
          <AgreeTableHeader $tdWidth={"30%"}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              fontWeight="SemiBold"
            >
              처리항목
            </Typograpy>
          </AgreeTableHeader>
        </AgreeTableTr>
        {/* </AgreeTableHeader> */}
        <AgreeTableTr>
          <AgreeTableTd style={{ textAlign: "center" }}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              1
            </Typograpy>
          </AgreeTableTd>
          <AgreeTableTd>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {`
① 본인확인, 연령확인, 계좌정보확인 
② 금융거래 관련 본인인증 및 금융서비스 제공 
③ 민원처리, 분쟁해결, 법령상 의무이행을 위한 의사소통 경로 확보 
④ 서비스 관련 공지사항 전달, 고객만족도 조사 
⑤ 이벤트/경품 당첨결과 통보, 상품배송, 사은행사 안내 
⑥ 서비스 이용현황 통계분석 및 활용  
⑦ 인구통계학적 특성에 따른 서비스 제공, 맞춤형 UI 제공 등 서비스품질 개선, 신규서비스 개발을 위한 연구분석, 맞춤서비스 제공, 맞춤형 리포트와 (선택하신 분에 한하여) 맞춤형 광고 제공 
⑧ 신규서비스 이용 시 또는 본인인증 시, 보유정보를 이용한 입력편의성 제공  
⑨ 부정이용방지, 사고조사, 보안정책 수립
              `}
            </Typograpy>
          </AgreeTableTd>
          <AgreeTableTd>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {`이름, 생년월일, 성별, 휴대전화번호, 금융기관명/계좌번호, 예금주명, 이메일주소, PIN, 국적, 통신사, 집 주소, 집 전화번호, 직업, 직장 이름, 직장 주소, 직장 전화번호
좌측 6번 내지 9번에는 서비스 이용 중 다양한 개인신용정보와 자동생성정보, 행태정보 등이 이용될 수 있습니다. 위치정보는 별도의 동의 절차에 따라 처리됩니다.`}
            </Typograpy>
          </AgreeTableTd>
        </AgreeTableTr>
        <AgreeTableTr>
          <AgreeTableTd style={{ textAlign: "center" }}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              2
            </Typograpy>
          </AgreeTableTd>
          <AgreeTableTd>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {`① 본인인증 (본인인증 시 본인확인기관 등에 의한 별도 동의절차가 진행됩니다.)`}
            </Typograpy>
          </AgreeTableTd>
          <AgreeTableTd>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {`성별, 중복가입확인정보(DI), 연계정보(CI), 아이핀번호, 내/외국인 정보`}
            </Typograpy>
          </AgreeTableTd>
        </AgreeTableTr>
        <AgreeTableTr>
          <AgreeTableTd style={{ textAlign: "center" }}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              3
            </Typograpy>
          </AgreeTableTd>
          <AgreeTableTd>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {`보험 가입설계를 위하여 각 보험회사가 정한 개인신용정보 수집/이용, 조회, 제3자 제공, 민감정보 및 고유식별정보 처리에 대한 동의`}
            </Typograpy>
          </AgreeTableTd>
          <AgreeTableTd>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {`보험회사가 제공한 동의문에서 처리 항목이 나열됩니다.`}
            </Typograpy>
          </AgreeTableTd>
        </AgreeTableTr>
        <AgreeTableTr>
          <AgreeTableTd style={{ textAlign: "center" }}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              4
            </Typograpy>
          </AgreeTableTd>
          <AgreeTableTd>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {`블루버튼, 레드버튼 서비스의 “고객조회”를 통한 보험 신규 가입 및 기존 가입된 보험 보장 분석에 대한 상담`}
            </Typograpy>
          </AgreeTableTd>
          <AgreeTableTd>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {`이름, 생년월일, 보험연령, 성별, 050 안심번호, 내 보험 조회 서비스를 통해서 조회한 보험 가입 내역 및 보험 특약 내역, 보험 계약 관련 사항, 상담을 원하는 지역`}
            </Typograpy>
          </AgreeTableTd>
        </AgreeTableTr>
        <AgreeTableTr>
          <AgreeTableTd style={{ textAlign: "center" }}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              5
            </Typograpy>
          </AgreeTableTd>
          <AgreeTableTd>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {`블루버튼, 렙드버튼 서비스의 회원이 선택한 보험상품의 가입, 청약, 보상 청구 처리 등의 유지 보수`}
            </Typograpy>
          </AgreeTableTd>
          <AgreeTableTd>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {`이름, 주민등록번호, 외국인등록번호, 주소, 성별, 직업, 휴대전화번호, 이메일주소, 연계정보(CI), 보험사, 신용정보집중기관 및 보험개발원에서 수집·관리하는 보험계약정보, 피보험자의 질병·상해에 관한 정보, 보험금지급 관련 정보(사고정보 및 관련 영상정보ㆍ음성정보, 본인의 위임을 받아 취득한 각종 조사서, 판결문, 증명서, 확인서, 진료기록 등), 계약전 알릴의무사항, 법률 및 국제협약 등의 의무이행을 위한 정보, 금융거래 업무(보험료 및 보험금 등 출·수납) 관련 정보 [단, 보험상품에 따라 제공항목이 다를 수 있음]`}
            </Typograpy>
          </AgreeTableTd>
        </AgreeTableTr>

        <AgreeTableTr>
          <AgreeTableTd style={{ textAlign: "center" }}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              6
            </Typograpy>
          </AgreeTableTd>
          <AgreeTableTd>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {`[선택] 광고/마케팅 수신: 회사 또는 보험사의 상품/서비스에 대한 광고/홍보/프로모션 제공 

(반드시 ‘선택’으로 동의 받습니다.)`}
            </Typograpy>
          </AgreeTableTd>
          <AgreeTableTd>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {`이름, 휴대전화번호, 이메일주소 
광고/마케팅 정보 수신 동의 후에 선택적으로 SMS문자, 앱푸쉬, 이메일, 전화 등 수신방법을 On/Off 선택할 수 있습니다.`}
            </Typograpy>
          </AgreeTableTd>
        </AgreeTableTr>
      </AgreeTableWrapper>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        개인신용정보의 수집방법
      </Typograpy>
      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`① 고객이 직접 입력한 정보 (홈페이지, 앱, 서면, 전화, 팩스 등) 
② 블루버튼, 레드버튼 서비스를 통해 고객을 조회한 정보 중 보험가입리스트, 금융상품 가입 리스트의 제3자 제공에 고객이 동의한 정보 
③ 특정 서비스의 사용을 위해서 은행,카드사,보험사가 고객의 사전동의를 받은 후 회사에 제공한 정보 
④ 상담과 민원처리 과정에서 생성된 정보 
⑤ 서비스 이용과정에서 자동으로 수집되는 정보 

자동 생성/수집 정보는 서비스 이용과정이나 개인신용정보 처리과정에서 다음과 같은 정보들이 생성/수집되고 다음의 목적으로 이용될 수 있습니다.  
① 서비스 이용내역(거래조건, 거래일시, 거래금액 등 거래설정 및 내역정보), 요금청구, 결제내역, 이용정지/해지기록, 불량이용기록, 접속로그: 서비스 제공 관련 고객상담, 민원처리, 맞춤형 UI 제공, 맞춤형 리포트 등의 목적 
② 휴대폰 모델명, 휴대폰 제조사, OS버전, 통신사: 통계 및 서비스 품질 향상을 위한 목적 
③ 휴대폰 기기고유번호(Android_ID): 사용자 기기 구별 목적으로 암호화된 정보를 수집 
④ 접속 IP주소: 의심거래 유저에 대한 수사협조 목적과 위치기반서비스 제공 
⑤ 쿠키: 웹뷰에서의 인증연동 목적 등 
⑥ 광고식별자(GAID, IDFA): 마케팅 프로모션 시 사용자 구분과 사용자 기기 구분 목적
- GAID: Google Advertising ID (Android)
- IDFA: Identifier for Advertisers (iOS)
⑦ 온라인 행태정보: 맞춤형 서비스의 제공 
⑧ 위치정보: 위치기반서비스 이용약관에 명시된 목적과 부정이용 방지 목적 
*이용안내: 이용자는 위치기반서비스 이용약관을 철회하거나 단말기의 설정을 변경함으로써 위치정보의 수집을 차단할 수 있습니다. `}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        [참고안내] 쿠키(cookie) 차단에 대한 안내
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`쿠키는 이용자가 웹사이트를 접속할 때에 해당 웹사이트에서 이용자의 웹브라우저를 통해 이용자의 PC에 저장하는 매우 작은 크기의 텍스트 파일입니다. 이후 이용자가 다시 웹사이트를 방문할 경우, 웹사이트 서버는 이용자 PC에 저장된 쿠키의 내용을 읽어 이용자가 설정한 서비스 이용 환경을 유지하여 편리한 인터넷 서비스 이용을 가능케 합니다. 또한 방문한 서비스 정보, 서비스 접속 시간 및 빈도, 서비스 이용 과정에서 생성된 또는 제공(입력)한 정보 등을 분석하여 이용자의 취향과 관심에 특화된 서비스(광고 포함)를 제공할 수 있습니다. 이용자는 쿠키에 대한 선택권을 가지고 있으며, 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다. `}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        [참고안내] 보유기간
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`보유기간이 기재되어 있지 않은 경우에는 
1) 제공 목적이 달성되는 시점 
2) 회원 탈퇴 시 
3) 관련법령에 따른 보존기간까지 제공된 정보가 이용됩니다. 
추가적인 정보는 제2조를 참고하여 주시기 바랍니다.`}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        [안내] 고유식별정보의 처리 관련
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`보험의 계약 및 보험금 지급 업무와 관련하여 주민등록번호와 외국인등록번호 등 고유식별정보를 사전 동의 받은 후 처리하고 있습니다. 블루라이언스는 보험 모집을 주관하는 각 보험사 및 소속 보험 모집 법인 대리점, 각 보험사 모집인에 피보험자 및 보험수익자의 고유식별정보를 전달하고, 서비스는 해당 상품을 공급하는 보험회사에 고유식별정보를 정보를 제공할 수 있습니다. `}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        [안내] 민감정보의 처리 관련
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`회사는 원칙적으로 민감정보를 수집하지 않습니다. 다만, 예외적으로 특정 보험상품의 경우에 한하여 보험의 계약 및 보험금 지급 업무와 관련하여 사전 동의를 받은 후 처리하고 있습니다. (주)블루라이언스는 보험 모집을 주관하는 각 제휴사 및 회원가입 보험설계사, 재무설계사에 피보험자 및 보험수익자의 정보를 전달할 수 있고, 해당 상품을 공급하는 보험회사에 그 정보를 제공할 수 있습니다. `}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        제2조 (개인신용정보의 보유기간)
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`회사는 법령에 따른 개인신용정보 보유기간 또는 정보주체로부터 개인신용정보를 수집 시에 동의 받은 개인신용정보 보유기간 내에서 개인신용정보를 처리합니다. 회사는 회원이 탈퇴를 요청하거나 개인신용정보 수집ㆍ이용에 대한 동의를 철회하는 경우, 수집ㆍ이용 목적이 달성되거나 보유기간이 종료한 경우에는 해당 개인신용정보를 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 아래에 명시한 기간 동안 보존할 수 있습니다. (제1조에 보유기간이 명시되어 있는 경우에는 그것이 우선합니다.)

① 회원 가입 및 관리: 회원가입 시점부터 회원 탈퇴시까지 (다만, 다음의 사유에 해당하는 경우에는 해당 사유 종료시까지)
- 관계 법령 위반에 따른 수사/조사 등이 진행 중인 경우에는 해당 수사/조사 종료 시까지
- 서비스 이용에 따른 채권/채무관계 정산 시까지
② 재화/서비스 제공: 재화/서비스 공급 완료 및 요금결제/정산완료 시까지 (다만, 다음의 사유에 해당하는 경우에는 해당 기간 종료 시까지)
- 전자금융거래에 관한 기록: 5년
- 계약 또는 청약철회, 대금결제, 재화 등의 공급기록: 5년
- 소비자 불만 또는 분쟁처리에 관한 기록: 3년
③ 부정사용 방지를 위한 회사 내부 방침
- 부정사용 기록: 10년
④ 광고/마케팅 수신: 동의 철회 또는 회원 탈퇴 시까지
- 광고/마케팅 수신 동의 후 매 2년마다 수신동의 여부를 확인하는 안내를 드립니다.
⑤ ‘050 안심번호’는 60일간 보존`}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        제3조 (개인신용정보의 제3자 제공)
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`회사는 서비스 이용에 반드시 필요한 개인신용정보를 다음과 같은 목적을 위하여 처리합니다. 처리하는 개인신용정보는 다음의 목적 이외의 용도로는 처리되지 않으며, 처리 목적과 항목이 변경되는 경우에는 별도의 동의를 받는 등 필요한 조치를 이행할 것입니다. 
회사는 서비스 이용에 필요한 최소한의 개인신용정보만을 수집하고 있으며, 제휴사에 제공하고 있습니다. 따라서, 필수 항목들만 처리하고 있으며, 선택 항목은 없습니다. 
처음으로 이용하는 서비스의 경우 회사는 이용자에게 필수적인 정보의 입력을 요청할 수 있으며, 회사가 이미 보유한 정보가 있다면 입력을 생략하거나 이용자를 위하여 입력편의성을 제공할 수 있습니다. 
회사는 대부분의 서비스에 대하여 ‘취소’ 또는 ‘철회’ 기능을 제공함으로써 이미 동의한 개인신용정보의 수집과 이용에 대하여 언제든지 취소하고 삭제할 수 있는 방법을 제공하고 있습니다. 다만, 다만, 관련 법령에 따라 개인신용정보가 삭제되지 않을 수도 있습니다. `}
      </Typograpy>

      <AgreeTableWrapper>
        {/* <AgreeTableHeader> */}
        <AgreeTableTr>
          <AgreeTableHeader $tdWidth={"33%"}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              fontWeight="SemiBold"
            >
              제공목적
            </Typograpy>
          </AgreeTableHeader>
          <AgreeTableHeader $tdWidth={"33%"}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              fontWeight="SemiBold"
            >
              제공받는 곳
            </Typograpy>
          </AgreeTableHeader>
          <AgreeTableHeader $tdWidth={"34%"}>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              fontWeight="SemiBold"
            >
              제공항목
            </Typograpy>
          </AgreeTableHeader>
        </AgreeTableTr>
        {/* </AgreeTableHeader> */}
        <AgreeTableTr>
          <AgreeTableTd>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              보험계약
            </Typograpy>
          </AgreeTableTd>
          <AgreeTableTd>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              보험회사
            </Typograpy>
          </AgreeTableTd>
          <AgreeTableTd>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              {`보험회사가 제공한 동의문에서 처리 항목이 나열됩니다.`}
            </Typograpy>
          </AgreeTableTd>
        </AgreeTableTr>
        <AgreeTableTr>
          <AgreeTableTd>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              회원이 선택한 보험상품의 가입, 청약, 및 보상 청구 처리 등의 유지
              보수
            </Typograpy>
          </AgreeTableTd>
          <AgreeTableTd>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              삼성화재해상보험, 에이스아메리칸해상보험, 교보라이프플래닛생명보험
              등 보험상품 제공자
            </Typograpy>
          </AgreeTableTd>
          <AgreeTableTd>
            <Typograpy
              fontStyle={fontStyle.body2_reading}
              style={{ whiteSpace: "pre-wrap" }}
            >
              이름, 주민등록번호, 외국인등록번호, 주소, 성별, 직업,
              휴대전화번호, 이메일주소, 연계정보(CI), 보험사, 신용정보집중기관
              및 보험개발원에서 수집·관리하는 보험계약정보, 피보험자의
              질병·상해에 관한 정보, 보험금지급 관련 정보(사고정보 및 관련
              영상정보ㆍ음성정보, 본인의 위임을 받아 취득한 각종 조사서, 판결문,
              증명서, 확인서, 진료기록 등), 계약전 알릴의무사항, 법률 및
              국제협약 등의 의무이행을 위한 정보, 금융거래 업무(보험료 및 보험금
              등 출·수납) 관련 정보 [단, 각 제공받는 자의 이용목적을 위해 필요한
              정보에 한함. 보험상품에 따라 제공항목이 다를 수 있음]
            </Typograpy>
          </AgreeTableTd>
        </AgreeTableTr>
      </AgreeTableWrapper>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        [참고] 보유기간
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`보유기간이 기재되어 있지 않은 경우에는 1) 제공 목적이 달성되는 시점 2) 회원 탈퇴 시 3) 관련법령에 따른 보존기간까지 제공된 정보가 이용됩니다. 추가적인 정보는 제2조를 참조하여 주시기 바랍니다. `}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        [안내] 고유식별정보의 처리 관련
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`보험의 계약 및 보험금 지급 업무와 관련하여 주민등록번호와 외국인등록번호 등 고유식별정보를 사전 동의 받은 후 처리하고 있습니다. 보험 모집을 주관하는 법인 보험 대리점에 피보험자 및 보험수익자의 고유식별정보를 전달할 수 있고, 해당 상품을 공급하는 보험회사에 고유식별정보를 정보를 제공할 수 있습니다. `}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        [안내] 민감정보의 처리 관련
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`회사는 원칙적으로 민감정보를 수집하지 않습니다. 다만, 예외적으로 특정 보험상품의 경우에 한하여 보험의 계약 및 보험금 지급 업무와 관련하여 사전 동의를 받은 후 처리하고 있습니다. `}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        제4조 (정보주체의 권리ㆍ의무 및 행사방법)
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`(1) 정보주체는 회사에 대해 언제든지 다음 각 호의 개인신용정보 보호 관련 권리를 행사할 수 있습니다.  
① 개인신용정보 열람 요구 및 통지 청구 
② 오류 등이 있을 경우 정정 요구 
③ 삭제 요구 및 동의 철회 요구 
④ 처리정지 요구  
⑤ 신용정보 이용 및 제공사실 통보 요구

(2) 제1항에 따른 권리 행사는 회사에 대해 서면, 전화, 이메일, 팩스 등을 통하여 하실 수 있습니다. 고객지원 전화, 이메일, 팩스 번호에 대한 안내는 제8조 고객센터 연락처를 참조하시기 바랍니다. 
(3) 정보주체가 개인신용정보의 오류 등에 대한 정정 또는 삭제를 요구한 경우에는 회사는 정정 또는 삭제를 완료할 때까지 당해 개인신용정보를 이용하거나 제공하지 않습니다. 다만, 금융거래를 정상적으로 처리하기 위해서 일부 서비스의 경우에는 요청이 즉시에 처리되지 않을 수도 있습니다. 광고/마케팅 수신 동의의 경우에는 철회 요청을 즉시에 처리합니다. 
(4) 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을 받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우 개인신용정보 보호법 시행규칙 별지 제11호 서식에 따른 위임장을 제출해야 합니다. 
(5) 정보주체는 관계법령을 위반하여 회사가 처리하고 있는 타인의 개인신용정보 및 사생활을 침해하여서는 아니 됩니다. 
(6) 회사는 정보주체 이외로부터 수집한 개인신용정보를 처리하는 때에는 정당한 사유가 없는 한 정보주체의 요구가 있은 날로부터 3일 이내에 수집 출처, 처리 목적, 개인신용정보 처리의 정지를 요구할 권리가 있다는 사실을 정보주체에게 알려 드립니다. 
(7) 회사는 개인신용정보 보호법 제20조 제4항 각 호에 근거하여 제1항에 따른 정보주체의 요구를 거부하는 경우에는 정당한 사유가 없는 한 정보주체의 요구가 있은 날로부터 3일 이내에 그 거부의 근거와 사유를 정보주체에게 알려 드립니다. `}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        제5조 (개인신용정보의 파기)
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`(1) 회사는 개인신용정보 보유기간이 경과하거나 처리목적이 달성되었을 때에는 지체 없이 해당 개인신용정보를 파기합니다. 
(2) 정보주체로부터 동의 받은 개인신용정보 보유기간이 경과하거나 처리목적이 달성되었음에도 불구하고 다른 법령에 따라 개인신용정보를 계속 보존하여야 하는 경우에는, 해당 개인신용정보를 별도의 데이터베이스(DB)로 옮기거나 보관장소를 달리하여 보존합니다. 
(3) 개인신용정보 파기의 절차 및 방법은 다음과 같습니다.  
- 파기절차: 회사는 파기 사유가 발생한 개인신용정보를 선정하고 아래 방법에 따라 개인신용정보를 파기합니다. 
- 파기방법: 회사는 법령에 따라 전자적 파일 형태로 저장된 개인신용정보는 복구 또는 재생되지 아니하도록 파기하며, 종이 문서에 기록된 개인신용정보는 파쇄하는 방법으로 파기합니다. `}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        제6조(개인신용정보의 안전성 확보조치)
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`(1) 회사는 개인신용정보의 안전성 확보를 위해 다음과 같은 조치를 취하고 있습니다. 
① 관리적 조치: 내부관리계획 수립 및 시행, 직원 대상 정기적 개인신용정보 보호 교육 등 
② 기술적 조치: 개인신용정보처리시스템 등의 접근권한 관리, 접근통제시스템 설치, 고유식별정보 등의 암호화, 보안프로그램 설치 등 
③ 물리적 조치: 전산실, 자료보관실 등의 접근통제 등 `}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        제7조 (개인정보보호책임자)
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`회사는 개인신용정보 처리에 관한 업무를 총괄해서 책임지고, 개인신용정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보보호책임자 겸 신용정보관리ㆍ보호인을 지정하고 있습니다.

▶ 개인정보보호책임자 및 신용정보관리ㆍ보호인
- 이름: 지환국 / 직책: CEO (대표자) / 이메일: info@bluelions.kr
▶ 개인신용정보 열람 청구 및 개인신용정보 관련 고충처리 담당부서  
- 부서명: 고객센터 / 담당자: 고객센터장  
- 연락처: <전화> 051-747-8563, <팩스> 051-980-7563, <이메일> info@bluelions.kr`}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        제8조 (권익침해 구제방법)
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`정보주체는 아래의 기관에 대해 개인신용정보 침해에 대한 피해구제, 상담 등을 문의하실 수 있습니다. 아래의 기관은 회사와는 별개의 기관으로서, 회사의 자체적인 개인신용정보 불만처리, 피해구제 결과에 만족하지 못하시거나 보다 자세한 도움이 필요하시면 문의하여 주시기 바랍니다. 

▶ 개인정보 침해신고센터 (한국인터넷진흥원 운영)  
- 소관업무: 개인정보 침해사실 신고, 상담 신청 
- 홈페이지: privacy.kisa.or.kr 
- 전화: (국번없이) 118 
- 주소: (58324) 전남 나주시 진흥길 9 (빛가람동 301-2) 3층 개인정보침해신고센터 

▶ 개인정보 분쟁조정위원회 사무국 
- 소관업무: 개인정보 분쟁조정신청, 집단분쟁조정 
- 홈페이지: www.kopico.go.kr 
- 전화: 1833-6972 
- 주소: 서울시 종로구 세종로 209 정부서울청사 (개인정보보호위원회) 

▶ 대검찰청 사이버수사과: (국번없이) 1301 (www.spo.go.kr) 

▶ 경찰청 사이버안전국: 경찰민원콜센터 (국번없이) 182 (cyberbureau.police.go.kr) `}
      </Typograpy>

      <Typograpy fontStyle={fontStyle.body2_reading} fontWeight="Bold">
        제9조 (시행일)
      </Typograpy>

      <Typograpy
        fontStyle={fontStyle.body2_reading}
        style={{ whiteSpace: "pre-wrap" }}
      >
        {`이 <개인정보 처리방침>은 2024. 09. 15부터 적용됩니다.`}
      </Typograpy>
    </FlexBox>
  );
};
