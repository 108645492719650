export const customModalStyles: ReactModal.Styles = {
  overlay: {
    backgroundColor: " rgba(34, 34, 34, 0.5)",
    width: "100%",
    height: "100vh",
    zIndex: "10",
    position: "fixed",
    top: "0",
    left: "0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    width: "30rem",
    height: "auto",
    position: "absolute",
    inset: "unset",
    borderRadius: 8,
    boxShadow:
      "0px 0px 4px 0px rgba(0, 0, 0, 0.08), 0px 4px 8px 0px rgba(0, 0, 0, 0.08), 0px 6px 12px 0px rgba(0, 0, 0, 0.12)",
  },
};
