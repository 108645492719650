import { useQueryClient } from "@tanstack/react-query";
import {
  CouponDataWithParams,
  deleteCoupon,
  GetCouponDataListResponse,
} from "api/api.coupon";
import { SolidButton } from "components/molecules/button/SolidButton";
import Typograpy from "components/molecules/Typograpy";
import { AlertModal } from "components/organisms/modal/AlertModal";
import { CouponManageModal } from "components/organisms/modal/CouponManageModal";
import React from "react";
import { useSnackbarStore } from "store/snackbarStore";
import { useCouponStore } from "store/useCouponStore";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { FlexBox } from "theme/globalStyle";
import { convertUnixDate, isDateInRange } from "utils/func";
import ModalPortal from "utils/Portal";

interface CouponDetailAreaProps {
  selCouponIdx: number;
}

export const CouponDetailArea: React.FC<CouponDetailAreaProps> = ({
  selCouponIdx,
}) => {
  const showSnackbar = useSnackbarStore((state) => state.showSnackbar);
  const { couponList, updateCouponList } = useCouponStore((state) => state);

  const [couponInfo, setCouponInfo] = React.useState<CouponDataWithParams>({
    coupon_id: 0,
    coupon_name: "",
    coupon_content: "",
    coupon_code: "",
    coupon_type: "",
    discount_rate: 0,
    expiration_end_date: 0,
    expiration_start_date: 0,
  });
  const [alertModal, setAlertModal] = React.useState({
    visible: false,
    msg: "",
    type: "",
  });

  const [couponModal, setCouponModal] = React.useState(false);

  const couponModalOn = () => {
    setCouponModal(true);
  };

  const couponModalOff = () => {
    setCouponModal(false);
  };

  const alertModalOn = (msg: string, type?: string) => {
    setAlertModal({
      visible: true,
      msg,
      type: type || "",
    });
  };

  const alertModalOff = () => {
    setAlertModal({ visible: false, msg: "", type: "" });
  };
  const alertAction = () => {
    if (alertModal.type === "delete_coupon_confirm") {
      deleteCouponHandler();
    }
  };

  function deleteCouponHandler() {
    deleteCoupon(selCouponIdx)
      .then((res) => {
        showSnackbar("쿠폰삭제가 완료되었습니다.");
        updateCouponList([
          ...couponList.filter((el) => el.coupon_id !== selCouponIdx),
        ]);
      })
      .catch((err) => {
        alertModalOn(err.response.data.message, "");
      });
  }

  React.useEffect(() => {
    if (couponList) {
      if (couponList.length > 0) {
        // console.log(couponData);
        const selCouponInfo = couponList.filter(
          (el) => el.coupon_id === selCouponIdx
        );
        setCouponInfo({
          ...selCouponInfo[0],
        });
      }
    }
  }, [couponList, selCouponIdx]);

  return (
    <FlexBox $isFullWidth $pa={2}>
      <ModalPortal>
        <AlertModal
          visible={alertModal.visible}
          msg={alertModal.msg}
          type={alertModal.type}
          action={alertAction}
          modalOff={alertModalOff}
        />
      </ModalPortal>
      <ModalPortal>
        <CouponManageModal
          visible={couponModal}
          modalOff={couponModalOff}
          detailCouponInfo={couponInfo}
          mode={"edit"}
          // selCouponIdx={couponModal.selCouponIdx}
        />
      </ModalPortal>
      <FlexBox $flexDirection="row" $gap={1.2}>
        <FlexBox
          $py={0.5}
          width={5.5}
          $jc={"center"}
          $ai={"center"}
          $br={100}
          $bgcolor={
            isDateInRange(
              couponInfo.expiration_start_date,
              couponInfo.expiration_end_date
            )
              ? colors.ADMIN_COUPON_PROCESS
              : colors.ADMIN_COUPON_END
          }
          $bw={1}
          $bc={colors.ADMIN_COUPON_END_FONT}
        >
          <Typograpy
            color={
              isDateInRange(
                couponInfo.expiration_start_date,
                couponInfo.expiration_end_date
              )
                ? colors.WHITE
                : colors.ADMIN_COUPON_END_FONT
            }
            fontStyle={fontStyle.caption2}
          >
            {isDateInRange(
              couponInfo.expiration_start_date,
              couponInfo.expiration_end_date
            )
              ? "진행중"
              : "종료"}
          </Typograpy>
        </FlexBox>
        <Typograpy
          fontStyle={fontStyle.headline2}
          fontWeight="Bold"
          color={colors.BLACK}
        >
          {couponInfo.coupon_name}
        </Typograpy>
      </FlexBox>
      <FlexBox
        $isFullWidth
        $flexDirection="row"
        $jc={"space-between"}
        $mt={1.2}
      >
        <FlexBox $gap={1.2}>
          <FlexBox $flexDirection="row" $ai={"center"} $gap={4}>
            <Typograpy
              color={colors.MAIN_TITLE_FONT}
              fontStyle={fontStyle.label2}
            >
              사용기간 : {convertUnixDate(couponInfo.expiration_start_date)} ~{" "}
              {convertUnixDate(couponInfo.expiration_end_date)}
            </Typograpy>
            <Typograpy
              color={colors.MAIN_TITLE_FONT}
              fontStyle={fontStyle.label2}
            >
              발행번호(코드) : {couponInfo.coupon_code}
            </Typograpy>
            <Typograpy
              color={colors.MAIN_TITLE_FONT}
              fontStyle={fontStyle.label2}
            >
              할인율 : {couponInfo.discount_rate}%
            </Typograpy>
          </FlexBox>
          <Typograpy
            color={colors.MAIN_TITLE_FONT}
            fontStyle={fontStyle.label2}
          >
            비고 : {couponInfo.coupon_content}
          </Typograpy>

          <Typograpy
            color={colors.MAIN_TITLE_FONT}
            fontStyle={fontStyle.label2}
          >
            대상 : {couponInfo.coupon_type}
          </Typograpy>
        </FlexBox>
        <FlexBox $flexDirection="row" $gap={1.2}>
          <SolidButton
            $br={4}
            buttonLabel="쿠폰정보 수정"
            onClick={couponModalOn}
          />
          <SolidButton
            $br={4}
            buttonLabel="삭제"
            bgcolor={colors.RED}
            onClick={() => {
              alertModalOn(
                "해당 쿠폰을 삭제하시겠습니까?",
                "delete_coupon_confirm"
              );
            }}
          />
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};
