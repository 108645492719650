import { InquiryParamsWithDataType, useGetInquiryList } from "api/api.inquiry";
import { IconOutlineLoading } from "components/atoms/icon/animated";
import { TableTd, TableTr, TableWrapper } from "components/atoms/table/table";
import { SolidButton } from "components/molecules/button/SolidButton";
import Typograpy from "components/molecules/Typograpy";
import {
  BoardFilterType,
  BoardListFilter,
} from "components/organisms/filter/BoardListFilter";
import { QnaModal } from "components/organisms/modal/QnaModal";
import { NoticeTableHeader } from "components/organisms/notice/NoticeTableHeader";
import { Pagination } from "components/organisms/pagination/Pagination";
import { QnaShowState } from "components/templates/admin/AdminQnaTemplate";
import React from "react";
import { useUserInfoStore } from "store/userInfoStore";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { Box, FlexBox } from "theme/globalStyle";
import { convertUnixDate } from "utils/func";
import ModalPortal from "utils/Portal";

type QnaModalType = {
  visible: boolean;
  qnaId?: number;
  mode: "create" | "salesUpdate" | "admin" | "detail";
};

const QnaTemplate: React.FC = () => {
  const { salesmanager_id } = useUserInfoStore((state) => state);

  const { data: inquiryData, isPending: inquiryLoading } = useGetInquiryList({
    salesmanager_id,
  });

  const [inquiryList, setInquiryList] = React.useState<
    InquiryParamsWithDataType[]
  >([]);

  const [boardFilter, setBoardFilter] = React.useState<BoardFilterType>({
    state: "전체",
    keyword: "",
    date_start: "",
    date_end: "",
  });

  const boardFilterHandler = (value: string, key?: string) => {
    if (key) {
      setBoardFilter({
        ...boardFilter,
        [key]: value,
      });
    }
  };

  const [qnaPage, setQnaPage] = React.useState(1);
  const startPost = (qnaPage - 1) * 10 + 1;
  const endPost = startPost + 10 - 1;

  const [qnaModal, setQnaModal] = React.useState<QnaModalType>({
    visible: false,
    qnaId: 0,
    mode: "detail",
  });

  const stateList = [
    { label: "전체", value: "" },
    { label: "답변대기", value: "" },
    { label: "답변완료", value: "" },
  ];

  const applyFilters = () => {
    if (!inquiryData) return;

    let filtered = [...inquiryData.data.reverse()];
    if (boardFilter.state) {
      filtered = filtered.filter((item) => {
        if (boardFilter.state === "전체") {
          return true;
        } else if (boardFilter.state === "답변대기") {
          return !item.admin_id;
        } else {
          return item.admin_id;
        }
      });
    }

    if (boardFilter.keyword) {
      filtered = filtered.filter((item) =>
        item.title.toLowerCase().includes(boardFilter.keyword.toLowerCase())
      );
    }

    if (boardFilter.date_start) {
      filtered = filtered.filter(
        (item) =>
          new Date(convertUnixDate(item.created_at || 0)) >=
          new Date(boardFilter.date_start)
      );
    }

    if (boardFilter.date_end) {
      filtered = filtered.filter(
        (item) =>
          new Date(convertUnixDate(item.created_at || 0)) <=
          new Date(boardFilter.date_end)
      );
    }

    setInquiryList(filtered);
  };

  React.useEffect(() => {
    if (inquiryData) {
      if (inquiryData.status === 200) {
        setInquiryList([...inquiryData.data.reverse()]);
      }
    }
  }, [inquiryData]);

  return (
    <FlexBox $isFullWidth $ai={"center"} height={"100%"}>
      <ModalPortal>
        <QnaModal
          qnaId={qnaModal.qnaId}
          visible={qnaModal.visible}
          modalOff={() => {
            setQnaModal({ ...qnaModal, visible: false });
          }}
          mode={qnaModal.mode}
        />
      </ModalPortal>
      <FlexBox
        $isFullWidth
        $flexDirection="row"
        $jc="space-between"
        $ai="center"
        height={6}
        $bbw={1}
        $bbc={colors.DEFAULT_BORDER_COLOR}
        $pl={2}
        $pr={4}
      >
        <Typograpy
          fontStyle={fontStyle.body1_normal}
          fontWeight="SemiBold"
          color={colors.SALLER_SUBSIDE_TITLE}
        >
          문의하기
        </Typograpy>
      </FlexBox>
      {inquiryList.length === 0 ? (
        !inquiryLoading ? (
          <FlexBox
            $isFullWidth
            height={"100%"}
            $ai={"center"}
            $jc={"center"}
            $mt={4}
            $gap={2}
          >
            <Typograpy fontStyle={fontStyle.heading2} fontWeight="Bold">
              등록된 문의사항이 없습니다.
            </Typograpy>
            <SolidButton
              buttonLabel="문의하기"
              size={"large"}
              $br={4}
              onClick={() => {
                setQnaModal({
                  visible: true,
                  mode: "create",
                  qnaId: 0,
                });
              }}
            />
          </FlexBox>
        ) : (
          <FlexBox
            $isFullWidth
            height={"100%"}
            $ai={"center"}
            $jc={"center"}
            $mt={4}
            $gap={2}
          >
            <FlexBox
              $flex={1}
              $isFullWidth
              height={"100%"}
              $jc={"center"}
              $ai={"center"}
            >
              <IconOutlineLoading width={5} height={5} />
            </FlexBox>
          </FlexBox>
        )
      ) : (
        <FlexBox $maxWidth={100} $my={4} $px={2} $isFullWidth $ai={"center"}>
          <FlexBox
            $isFullWidth
            $flexDirection={"row"}
            $ai={"flex-end"}
            $jc={"space-between"}
          >
            <BoardListFilter
              boardFilter={boardFilter}
              boardFilterHandler={boardFilterHandler}
              stateList={stateList}
              applyFilters={applyFilters}
            />
            <SolidButton
              buttonLabel="문의하기"
              size={"large"}
              $br={4}
              onClick={() => {
                setQnaModal({
                  visible: true,
                  mode: "create",
                  qnaId: 0,
                });
              }}
            />
          </FlexBox>

          <FlexBox $py={2} $bgcolor={colors.WHITE} $isFullWidth>
            <TableWrapper>
              <thead>
                <TableTr>
                  <NoticeTableHeader title={"문의번호"} width={"10%"} />
                  <NoticeTableHeader title={"상태"} width={"10%"} />
                  <NoticeTableHeader title={"문의명"} width={"40%"} />
                  <NoticeTableHeader title={"등록일"} width={"15%"} />
                  <NoticeTableHeader title={"답변일"} width={"15%"} />
                  {/* <NoticeTableHeader title={"작성자"} width={"10%"} /> */}
                </TableTr>
              </thead>
              <tbody>
                {inquiryList
                  .slice(startPost - 1, endPost)
                  .map((item, index) => {
                    return (
                      <TableTr key={"notice" + index}>
                        <TableTd>
                          <Typograpy
                            fontStyle={fontStyle.caption1}
                            color={colors.BLACK}
                          >
                            {inquiryList.length - startPost - index + 1}
                            {/* {item.notice_id} */}
                          </Typograpy>
                        </TableTd>
                        <TableTd>
                          <FlexBox
                            $px={0.8}
                            $py={0.4}
                            $jc={"center"}
                            $ai={"center"}
                          >
                            <QnaShowState isDone={item.admin_id !== null} />
                            {/* <NoticeShowState isShow={item.is_show === "Y"} /> */}
                          </FlexBox>
                        </TableTd>
                        <TableTd>
                          <Box
                            $isPointer
                            onClick={() => {
                              setQnaModal({
                                visible: true,
                                qnaId: item.inquiry_id,
                                mode: "detail",
                              });
                            }}
                          >
                            <Typograpy
                              fontStyle={fontStyle.caption1}
                              color={colors.BLACK}
                              hoverColor={colors.ADMIN_MAIN_COLOR}
                            >
                              {item.title}
                            </Typograpy>
                          </Box>
                        </TableTd>
                        <TableTd>
                          <Typograpy
                            fontStyle={fontStyle.caption1}
                            color={colors.BLACK}
                          >
                            {convertUnixDate(item.created_at || 0)}
                          </Typograpy>
                        </TableTd>
                        <TableTd>
                          <Typograpy
                            fontStyle={fontStyle.caption1}
                            color={colors.BLACK}
                          >
                            {item.answer_date
                              ? convertUnixDate(item.answer_date)
                              : "-"}
                          </Typograpy>
                        </TableTd>
                        {/* <TableTd>
                      <Typograpy
                        fontStyle={fontStyle.caption1}
                        color={colors.BLACK}
                      >
                        {item.writer}
                      </Typograpy>
                    </TableTd> */}
                      </TableTr>
                    );
                  })}
              </tbody>
            </TableWrapper>
            <FlexBox $isFullWidth $ai={"center"}>
              <Pagination
                page={qnaPage}
                setPage={setQnaPage}
                rowCount={inquiryList.length}
                rowPerPage={10}
              />
            </FlexBox>
          </FlexBox>
        </FlexBox>
      )}
    </FlexBox>
  );
};

export default QnaTemplate;
