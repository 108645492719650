import React from "react";
import Typograpy from "../Typograpy";
import {
  CheckIcon,
  CheckboxProps,
  CheckboxWrapper,
  HiddenCheckbox,
  StyleSolidCheckbox,
} from "components/atoms/checkbox/checkbox";
import { Box } from "theme/globalStyle";

export const SolidCheckbox: React.FC<CheckboxProps> = ({
  size,
  $checked,
  disabled,
  onChange,
  name,
  value,
  label,
  fontStyle,
  fontWeight,
  fontColor,
}) => (
  <CheckboxWrapper disabled={disabled}>
    <HiddenCheckbox
      defaultChecked={$checked}
      value={value}
      name={name}
      onChange={onChange}
      disabled={disabled}
    />
    <StyleSolidCheckbox
      size={size}
      name={name}
      value={value}
      $checked={$checked}
    >
      <CheckIcon viewBox="0 0 24 24">
        <polyline points="20 6 9 17 4 12" />
      </CheckIcon>
    </StyleSolidCheckbox>
    {label && (
      <Box $ml={0.8}>
        <Typograpy
          color={fontColor}
          fontWeight={fontWeight}
          fontStyle={fontStyle}
        >
          {label}
        </Typograpy>
      </Box>
    )}
  </CheckboxWrapper>
);
