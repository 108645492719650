import styled from "styled-components";
import { colors } from "theme/colors";
import { FlexBox } from "theme/globalStyle";

const PrimaryWrapper = styled(FlexBox)`
  /* border-bottom: 1px solid ${colors.TAB_BORDER_BOTTOM}; */
  flex-direction: row;
  gap: 2rem;
  /* padding-left: 1.2rem; */
  /* padding-right: 1.2rem; */
`;

const PrimaryBtnWrapper = styled(FlexBox)<{ $isSel: boolean }>`
  justify-content: center;
  align-items: center;
  padding: 1.2rem 0;

  ${({ $isSel }) => $isSel && `border-bottom: 2px solid ${colors.TAB_ON};`};
  cursor: pointer;
`;

const SecondaryWrapper = styled(FlexBox)`
  border-radius: 6px;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid ${colors.SEC_TAB_BORDER};
  overflow: hidden;
`;

const SecondaryBtnWrapper = styled(FlexBox)<{ $isSel: boolean }>`
  justify-content: center;
  align-items: center;
  padding: 1.2rem 1.6rem;
  border-right: 1px solid ${colors.SEC_TAB_BORDER};
  ${({ $isSel }) => $isSel && `background-color : ${colors.SEC_TAB_ON};`};
  cursor: pointer;
  flex: 1;
`;

const TabButton = styled.button<{ active: boolean }>`
  background-color: ${(props) => (props.active ? "#0000FF" : "#FFFFFF")};
  color: ${(props) => (props.active ? "#FFFFFF" : "#000000")};
  border: none;
  padding: 1rem 2rem;
  cursor: pointer;
`;

const TabContent = styled.div`
  padding: 20px;
`;

export {
  PrimaryWrapper,
  PrimaryBtnWrapper,
  SecondaryWrapper,
  SecondaryBtnWrapper,
  TabButton,
  TabContent,
};
