import {
  PrimaryBtnWrapper,
  PrimaryWrapper,
} from "components/atoms/tab/tabStyles";
import React from "react";
import Typograpy from "../Typograpy";
import { colors } from "theme/colors";

interface PrimaryTabType {
  items: string[];
  activeTab: number;
  onTabChange: (index: number) => void;
  fontStyle?: string;
}

export const PrimaryTab: React.FC<PrimaryTabType> = ({
  items,
  activeTab,
  onTabChange,
  fontStyle,
}) => {
  return (
    <PrimaryWrapper>
      {items.map((item, index) => {
        return (
          <PrimaryBtnWrapper
            onClick={() => onTabChange(index)}
            $isSel={activeTab === index}
            key={"tab" + index}
          >
            <Typograpy
              fontSize={16}
              color={activeTab === index ? colors.TAB_ON : colors.TAB_OFF}
              fontWeight={activeTab === index ? "Medium" : "Regular"}
              style={{ whiteSpace: "nowrap" }}
              fontStyle={fontStyle}
            >
              {item}
            </Typograpy>
          </PrimaryBtnWrapper>
        );
      })}
    </PrimaryWrapper>
  );
};
