import { useQueryClient } from "@tanstack/react-query";
import { GetPaymentListResponseData } from "api/api.payment";
import { GetSallerListResponse } from "api/api.salesmanager";
import { DatatablePagination } from "components/organisms/pagination/DatatablePagination";
import React from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { Box, FlexBox } from "theme/globalStyle";
import { tableCustomStyles } from "theme/table.custom";
import { convertUnixDate, NumberComma } from "utils/func";

interface SallerPaymentListProps {
  selCustomerIndex: number;
  sallerData?: GetSallerListResponse;
}

export const SallerPaymentList: React.FC<SallerPaymentListProps> = ({
  selCustomerIndex,
  sallerData,
}) => {
  const [paymentList, setPaymentList] = React.useState<
    GetPaymentListResponseData[]
  >([]);

  const columns: TableColumn<GetPaymentListResponseData>[] = [
    {
      name: "NO",
      // selector: (row: any, index: number) => index,
      sortable: false,
      cell: (_, index: number) => index + 1,
      width: "6rem",
    },

    {
      name: "상품명",
      selector: (row) => row.sub_product_information.sub_product_name,
      sortable: true,
      grow: 2,
    },
    {
      name: "할인금액",
      selector: (row) => NumberComma(row.final_discount) + "원",
      sortable: true,
    },
    {
      name: "결제일",
      selector: (row) => convertUnixDate(row.created_at || 0),
      sortable: false,
    },
    {
      name: "결제금액",
      selector: (row) => NumberComma(row.final_price) + "원",
      sortable: true,
    },
    {
      name: "결제방법",
      selector: (row) => row.payment_method,
      sortable: false,
    },
    {
      name: "결제여부",
      selector: (row) => row.payment_state,
      sortable: false,
    },
    {
      name: "결제ID",
      selector: (row) => row.toss_id,
      sortable: false,
      grow: 2,
    },
  ];

  React.useEffect(() => {
    if (sallerData) {
      if (sallerData.status === 200) {
        const selPaymentInfo: GetPaymentListResponseData[] =
          sallerData.data.filter(
            (el) => el.salesmanager_id === selCustomerIndex
          )[0].payment;

        setPaymentList([...selPaymentInfo]);
      }
    }
  }, [sallerData, selCustomerIndex]);

  return (
    <FlexBox $pa={2} $isFullWidth>
      {/* <CustomerListFilter
        filterInfo={filterInfo}
        filterSetting={filterSetting}
      /> */}

      <Box $isFullWidth>
        <DataTable
          columns={columns}
          data={[...paymentList]}
          defaultSortFieldId={1}
          pagination
          paginationComponent={DatatablePagination}
          customStyles={tableCustomStyles}
        />
      </Box>
    </FlexBox>
  );
};
