import Typograpy from "components/molecules/Typograpy";
import React from "react";
import { FlexBox } from "theme/globalStyle";

interface NoneDataComponentProps {
  comment?: string;
}

export const NoneDataComponent: React.FC<NoneDataComponentProps> = ({
  comment,
}) => {
  return (
    <FlexBox $isFullWidth $ai={"center"}>
      <Typograpy> {comment || "검색한 데이터가 존재하지 않습니다."}</Typograpy>
    </FlexBox>
  );
};
