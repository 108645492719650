import { Button } from "components/atoms/button/Button";
import React from "react";
import Typograpy from "../Typograpy";
import { colors } from "theme/colors";
import { FlexBox } from "theme/globalStyle";
import { CSSProperties } from "styled-components";
import {
  IconOutlineLoading,
  IconSolidLoading,
} from "components/atoms/icon/animated";

interface SolidButtonType {
  leftIcon?: string;
  rightIcon?: string;
  iconSize?: number;
  size?: "large" | "medium" | "small";
  disable?: boolean;
  buttonLabel: string;
  fontStyle?: string;
  onClick?: () => void;
  style?: CSSProperties;
  $br?: number;
  bgcolor?: string;

  isLoading?: boolean;
}

export const SolidButton = React.memo(
  ({
    leftIcon,
    rightIcon,
    iconSize,
    size = "medium",
    disable,
    buttonLabel,
    fontStyle,
    onClick,
    style,
    $br,
    bgcolor,
    isLoading,
  }: SolidButtonType) => {
    return (
      <Button
        $bgc={
          bgcolor || (disable ? colors.BTN_DIS_COLOR : colors.BTN_MAIN_COLOR)
        }
        $br={$br ? $br : 0}
        $px={size === "large" ? 2.8 : size === "medium" ? 2 : 1.4}
        $py={size === "large" ? 1.2 : size === "medium" ? 0.9 : 0.7}
        disabled={disable}
        onClick={onClick}
        style={{
          whiteSpace: "nowrap",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          ...style,
        }}
      >
        {isLoading ? (
          <IconSolidLoading />
        ) : (
          <Typograpy
            fontSize={size === "large" ? 16 : size === "medium" ? 15 : 13}
            color={disable ? colors.SOLID_DIS_BTN_FONT : colors.SOLID_BTN_FONT}
            fontWeight="Bold"
            fontStyle={fontStyle}
            leftIcon={leftIcon}
            rightIcon={rightIcon}
            iconSize={iconSize}
          >
            {buttonLabel}
          </Typograpy>
        )}
      </Button>
    );
  }
);
