import { useGetSallerInfo } from "api/api.salesmanager";
import { useCheckSNSCode } from "api/auth/CheckSNSCode";
import { CheckTokenResponse, useCheckToken } from "api/auth/CheckToken";
import axios from "axios";
import { AlertModal } from "components/organisms/modal/AlertModal";
import { LoadingModal } from "components/organisms/modal/LoadingModal";
import React from "react";
import { useNavigate } from "react-router";
import { AdminInfoType, useAdminInfoStore } from "store/useAdminInfoStore";
import { useTokenStore } from "store/useTokenStore";
import { UserInfoType, useUserInfoStore } from "store/userInfoStore";
import ModalPortal from "utils/Portal";
import { isDateInRange } from "utils/func";

export const SocialLogin = () => {
  //카카오 서버에서 리다이렉트 쿼리

  const navigate = useNavigate();
  const KAKAO_REDIRECT_URL = process.env.REACT_APP_KAKAO_REDIRECT_URL;
  const CALLBACK_URL = `${KAKAO_REDIRECT_URL}/auth`;

  const rescode = new URL(window.location.href).searchParams.get("code");
  const restype = new URL(window.location.href).searchParams.get("type");

  const { data } = useCheckSNSCode({
    rescode,
    restype,
  });

  const [alertModal, setAlertModal] = React.useState({
    visible: false,
    msg: "",
    type: "",
  });

  //토큰 검증 및 유저정보 불러오기
  const {
    mutate: tokenCheck,
    isError,
    error,
  } = useCheckToken({
    goHome: () => {
      navigate("/");
    },
    onError: (error) => {
      console.log(error);
      setAlertModal({
        visible: true,
        msg: `예상치 못한 에러가 발생하였습니다. \n ${error}`,
        type: "",
      });
    },
  });

  const alertAction = () => {
    navigate(-1);
  };
  const isMemberCheck = () => {
    //social_id 및 type이 같이오면 회원x
    if (data?.data.social_id && data?.data.type) {
      navigate("/join/writeUserInfo", {
        state: {
          socialId: data?.data.social_id,
          type: data?.data.type,
          kakao_data: data?.data.userProfile,
        },
      });
    } else if (data?.data.token) {
      useTokenStore.getState().setToken(data?.data.token.token);
      tokenCheck(data?.data.token.token);
    }
  };

  //카카오 서버에서 리다이렉트 code 감지
  React.useEffect(() => {
    console.log(data);
    isMemberCheck();
  }, [data]);

  return (
    <>
      <ModalPortal>
        <AlertModal
          visible={alertModal.visible}
          modalOff={() => {
            setAlertModal({ visible: false, msg: "", type: "" });
          }}
          msg={alertModal.msg}
          type={alertModal.type}
          action={alertAction}
        />
      </ModalPortal>
      <LoadingModal />
    </>
  );
};
