import { GetPaymentListResponseData } from "api/api.payment";
import { create } from "zustand";
import { persist } from "zustand/middleware";

export interface UserInfoType {
  salesmanager_id: number;
  salesmanager_name: string;
  salesmanager_kakao_account: string;
  salesmanager_account: string;
  salesmanager_password: string;
  zip_code: number;
  salesmanager_address: string;
  salesmanager_address_detail: string;
  salesmanager_birthday: number;
  salesmanager_gender: string;
  sales_phone: string;
  email_address: string;
  payment: GetPaymentListResponseData[];
  sales_area: { local_id: number }[];
  company_name: string;
  company_zip_code: number;
  company_address: string;
  company_address_detail: string;
  created_at: number;
  updated_at: number;
  type: string;
  sales_item: string;
  customer_key: string;
}

interface UserInfoStoreType extends UserInfoType {
  updateUserInfo: (userInfo: UserInfoType) => void;
  deleteUserInfo: () => void;
}

export const useUserInfoStore = create<UserInfoStoreType>()(
  persist(
    (set) => ({
      salesmanager_id: 0,
      salesmanager_name: "",
      salesmanager_kakao_account: "",
      salesmanager_account: "",
      salesmanager_password: "",
      zip_code: 0,
      salesmanager_address: "",
      salesmanager_address_detail: "",
      salesmanager_birthday: 0,
      salesmanager_gender: "",
      sales_phone: "",
      email_address: "",
      payment: [],
      sales_area: [],
      company_zip_code: 0,
      company_name: "",
      company_address: "",
      company_address_detail: "",
      created_at: 0,
      updated_at: 0,
      type: "",
      sales_item: "",
      customer_key: "",
      updateUserInfo: (userInfo: UserInfoType) => set({ ...userInfo }),
      deleteUserInfo: () =>
        set({
          salesmanager_id: 0,
          salesmanager_name: "",
          salesmanager_kakao_account: "",
          salesmanager_account: "",
          salesmanager_password: "",
          zip_code: 0,
          salesmanager_address: "",
          salesmanager_address_detail: "",
          salesmanager_birthday: 0,
          salesmanager_gender: "",
          sales_phone: "",
          email_address: "",
          payment: [],
          sales_area: [],
          company_name: "",
          company_zip_code: 0,
          company_address: "",
          company_address_detail: "",
          created_at: 0,
          updated_at: 0,
          type: "",
          sales_item: "",
          customer_key: "",
        }),
    }),
    {
      name: "user-info-storage", // Unique name for the storage (you can choose any name)
      getStorage: () => sessionStorage, // Use sessionStorage for persistence
    }
  )
);
