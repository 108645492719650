import styled, { keyframes } from "styled-components";

const rightRolling = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
`;

const leftRolling = keyframes`
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-100%);
  }
`;

const BannerContainer = styled.div`
  display: flex;
  overflow: hidden;
  white-space: nowrap;
  width: 100%; // 컨테이너의 너비를 설정
`;

const BannerTrack = styled.div<{
  $rolling: "left" | "right";
  $duration: number;
}>`
  display: flex;
  animation: ${({ $rolling }) =>
      $rolling === "right" ? rightRolling : leftRolling}
    ${({ $duration }) => $duration + "s"} linear infinite;
`;

const BannerItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2rem;
`;

export { BannerContainer, BannerTrack, BannerItem };
