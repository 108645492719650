import { BannerItem } from "components/atoms/rollingBanner/rollingBanner";
import React from "react";

interface BannerProps {
  children: React.ReactNode;
}

const Banner: React.FC<BannerProps> = ({ children }) => {
  return <BannerItem>{children}</BannerItem>;
};

export default Banner;
