import { Button } from "components/atoms/button/Button";
import React from "react";
import Typograpy from "../Typograpy";
import { colors } from "theme/colors";
import { CSSProperties } from "styled-components";
import { IconOutlineLoading } from "components/atoms/icon/animated";

interface SolidButtonType {
  leftIcon?: string;
  rightIcon?: string;
  size?: "large" | "medium" | "small";
  type?: "pri" | "sec" | "assis";
  disable?: boolean;
  buttonLabel: string;
  fontStyle?: string;
  onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  style?: CSSProperties;
  isInputLabel?: boolean;
  iconSize?: number;

  $br?: number;
  bc?: string;
  bgcolor?: string;

  isLoading?: boolean;
}

export const OutlineButton = React.memo(
  ({
    leftIcon,
    rightIcon,
    size = "medium",
    type = "pri",
    disable,
    buttonLabel,
    fontStyle,
    onClick,
    style,
    isInputLabel,
    iconSize,
    $br,
    bc,
    bgcolor,

    isLoading,
  }: SolidButtonType) => {
    return (
      <Button
        $bgc={bgcolor || colors.WHITE}
        $br={$br || 0}
        $px={size === "large" ? 2.8 : size === "medium" ? 2 : 1.4}
        $py={size === "large" ? 1.2 : size === "medium" ? 0.9 : 0.7}
        disabled={disable}
        $bw={1}
        height={isInputLabel ? 4.2 : "auto"}
        $bc={
          bc ||
          (disable
            ? colors.BTN_DIS_COLOR
            : type === "pri"
            ? colors.BTN_MAIN_COLOR
            : colors.BTN_GRAY_COLOR)
        }
        onClick={(e) => {
          if (onClick) onClick(e);
        }}
        style={{
          whiteSpace: "nowrap",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          ...style,
        }}
      >
        {isLoading ? (
          <IconOutlineLoading />
        ) : (
          <Typograpy
            fontSize={size === "large" ? 16 : size === "medium" ? 15 : 13}
            color={
              disable
                ? colors.SOLID_DIS_BTN_FONT
                : type === "assis"
                ? colors.ASSIS_BTN_FONT
                : colors.BTN_MAIN_COLOR
            }
            fontWeight="Bold"
            leftIcon={leftIcon}
            rightIcon={rightIcon}
            iconSize={iconSize}
            fontStyle={fontStyle}
          >
            {buttonLabel}
          </Typograpy>
        )}
      </Button>
    );
  }
);
