import React, { ReactChild } from "react";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Autoplay } from "swiper/modules";

interface SwiperCarouselPropsType {
  isVertical?: boolean;
  autoplay?: boolean;
  isLoop?: boolean;
  conHeight?: number;
  children: React.ReactElement[];
}

const SwiperCarousel: React.FC<SwiperCarouselPropsType> = ({
  children,
  autoplay,
  isVertical,
  isLoop,
  conHeight,
}) => {
  return (
    <Swiper
      autoplay={{
        delay: 3000,
        disableOnInteraction: true,
      }}
      autoHeight
      direction={isVertical ? "vertical" : "horizontal"}
      loop={isLoop}
      height={conHeight}
      modules={autoplay ? [Autoplay] : []}
    >
      {children.map((item, index) => {
        return <SwiperSlide key={"carousel" + index}>{item}</SwiperSlide>;
      })}
    </Swiper>
  );
};

export default SwiperCarousel;
