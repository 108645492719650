import Typograpy from "components/molecules/Typograpy";
import React from "react";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { Box, FlexBox } from "theme/globalStyle";
import { SearchArea } from "./SearchArea";
import { dummyProfileList } from "utils/dummyList";
import { PrimaryTab } from "components/molecules/tab/PrimaryTab";
import { convertUnixDate } from "utils/func";
import { SallerList } from "./SallerList";
import { SallerSendList } from "./SallerSendList";
import { SallerPaymentList } from "./SallerPaymentList";
import { SallerLocationList } from "./SallerLocationList";
import { GetSallerData, useGetSallerList } from "api/api.salesmanager";

//설계사 목록
const AdminSallerListTemplate = () => {
  const { data: sallerData, refetch: sallerRefetch } = useGetSallerList();

  const [selCustomerIndex, setSelCustomerIndex] = React.useState<number | "">(
    ""
  );
  const [selCustomerInfo, setCustomerInfo] = React.useState<GetSallerData>();
  //이름검색
  const [searchName, setSearchName] = React.useState<string>("");

  const [selTab, setSelTab] = React.useState(0);

  React.useEffect(() => {
    //설계사 선택
    if (sallerData && selCustomerIndex !== "") {
      if (sallerData.status === 200) {
        const selProfileInfo: GetSallerData = sallerData.data.filter(
          (el) => el.salesmanager_id === selCustomerIndex
        )[0];

        setCustomerInfo(selProfileInfo);
      }
    }
  }, [sallerData, selCustomerIndex]);

  return (
    <FlexBox $flex={1} $flexDirection={"row"} height={"100%"} $isFullWidth>
      <SearchArea
        setSelCustomerIndex={setSelCustomerIndex}
        selCustomerIndex={selCustomerIndex}
        searchName={searchName}
        setSearchName={setSearchName}
      />

      <FlexBox $flex={8} height={"100%"} $maxHeight={"calc(100vh - 6.8rem)"}>
        {selCustomerIndex !== "" && (
          <FlexBox $isFullWidth style={{ overflowY: "scroll" }} height={"100%"}>
            <FlexBox $gap={0.8} $px={2} $py={2}>
              <Typograpy fontStyle={fontStyle.headline2} fontWeight="Bold">
                {selCustomerInfo?.salesmanager_name}
              </Typograpy>
              <FlexBox $flexDirection="row" $gap={4}>
                <FlexBox $gap={0.8}>
                  <Typograpy fontStyle={fontStyle.label2}>
                    소속 : {selCustomerInfo?.company_name}
                  </Typograpy>
                  {/* <Typograpy fontStyle={fontStyle.label2}>
                    지역 : {selProfileInfo.location}
                  </Typograpy> */}
                </FlexBox>
                <FlexBox $gap={0.8}>
                  {/* <Typograpy fontStyle={fontStyle.label2}>
                    유형 : {selProfileInfo.type} 이용중
                  </Typograpy> */}
                  <Typograpy fontStyle={fontStyle.label2}>
                    가입일 : {convertUnixDate(selCustomerInfo?.created_at || 0)}
                  </Typograpy>
                </FlexBox>
              </FlexBox>
            </FlexBox>

            <Box
              $isFullWidth
              $px={2}
              $bbc={colors.DEFAULT_BORDER_COLOR}
              $bbw={1}
            >
              <PrimaryTab
                items={["고객목록", "발송목록", "결제목록", "결제지역"]}
                activeTab={selTab}
                onTabChange={setSelTab}
              />
            </Box>
            {/* {selCustomerIndex !== '' && */}
            {selTab === 0 ? (
              <SallerList
                selCustomerIndex={selCustomerIndex}
                sallerData={sallerData}
              />
            ) : selTab === 1 ? (
              <SallerSendList
                selCustomerIndex={selCustomerIndex}
                sallerData={sallerData}
              />
            ) : selTab === 2 ? (
              <SallerPaymentList
                selCustomerIndex={selCustomerIndex}
                sallerData={sallerData}
              />
            ) : (
              <SallerLocationList
                selCustomerIndex={selCustomerIndex}
                sallerData={sallerData}
              />
            )}
            {/* } */}
          </FlexBox>
        )}
      </FlexBox>
    </FlexBox>
  );
};

export default AdminSallerListTemplate;
