import { CouponDataWithParams } from "api/api.coupon";
import { create } from "zustand";

interface CouponStoreType {
  couponList: {
    coupon_id?: number; //쿠폰 id
    coupon_type: string;
    coupon_name: string; //쿠폰이름
    coupon_content: string; //쿠폰설명
    coupon_code: string; //쿠폰코드
    expiration_start_date: number; //쿠폰만료일
    expiration_end_date: number; //쿠폰만료일
    discount_rate: number; //할인율
    created_at?: number; //생성일
    updated_at?: number; //수정일
  }[];
  updateCouponList: (couponList: CouponDataWithParams[]) => void;
}

export const useCouponStore = create<CouponStoreType>()((set) => ({
  couponList: [],
  updateCouponList: (couponList: CouponDataWithParams[]) =>
    set({ couponList: [...couponList] }),
}));
