import React from "react";
import Typograpy from "components/molecules/Typograpy";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { Box, FlexBox, MainMaxWidth } from "theme/globalStyle";
import SwiperCarousel from "components/molecules/SwiperCarousel/Index";
import { IcRightArrow } from "components/atoms/icon/icon";
import { useNavigate } from "react-router";
import { useGetNoticeList } from "api/api.notice";
import { Link } from "react-router-dom";

export const NoticeBar = () => {
  const navigate = useNavigate();

  const {
    data: noticeData,
    isLoading: noticeLoading,
    isError: noticeisError,
    error: noticeError,
  } = useGetNoticeList();

  React.useEffect(() => {
    if (noticeData) {
    }
  }, [noticeData]);

  return (
    <FlexBox
      $isFullWidth
      $bgcolor={colors.MAIN_INTRODUCE_BG}
      $py={2}
      $btc={colors.DEFAULT_BORDER_COLOR}
      $btw={1}
      $ai="center"
    >
      <FlexBox
        $isFullWidth
        $maxWidth={MainMaxWidth}
        $flexDirection="row"
        $jc="space-between"
        $ai="center"
      >
        <FlexBox $flex={2} $flexDirection="row" $gap={2}>
          <Typograpy
            fontWeight="SemiBold"
            color={colors.BLACK}
            fontStyle={fontStyle.label1_normal}
          >
            공지사항
          </Typograpy>
          {noticeData && (
            <Box $isFullWidth $maxWidth={"100%"} $flex={1}>
              <SwiperCarousel isVertical conHeight={20} autoplay isLoop>
                {noticeData?.data
                  .filter((el) => el.is_show === "Y")
                  .map((item, index) => {
                    return (
                      <Link
                        key={"noticeList" + index}
                        to={`/notice/${item.notice_id}`}
                      >
                        <Typograpy fontStyle={fontStyle.label1_reading}>
                          {item.notice_name}
                        </Typograpy>
                      </Link>
                    );
                  })}
              </SwiperCarousel>
            </Box>
          )}
        </FlexBox>
        <Link to={"/notice"}>
          <FlexBox $isPointer $flexDirection="row" $gap={0.4} $ai="center">
            <Typograpy
              fontStyle={fontStyle.label1_reading}
              color={colors.NOTICE_MORE_FONT}
            >
              더보기
            </Typograpy>
            <IcRightArrow width={1.2} height={1.2} />
          </FlexBox>
        </Link>
      </FlexBox>
    </FlexBox>
  );
};
