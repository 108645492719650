import { Img } from "components/atoms/img/img";
import Typograpy from "components/molecules/Typograpy";
import { motion, useAnimation } from "framer-motion";
import React from "react";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { Box, FlexBox, MainMaxWidth } from "theme/globalStyle";
import { DescriptionListType } from "utils/list";
import { useInView } from "react-intersection-observer";
interface SalesDescriptionPropsType {
  desInfo: DescriptionListType;
  isBgGray?: boolean;
}

export const SalesDescription: React.FC<SalesDescriptionPropsType> = ({
  desInfo,
  isBgGray,
}) => {
  const controls = useAnimation();
  const [ref, inView] = useInView({ triggerOnce: true });

  //motion 컨테이너 셋팅
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      //보여질때 설정
      opacity: 1,
      transition: {
        staggerChildren: 0.3, // 각 자식 요소 사이의 지연 시간
      },
    },
  };

  const itemVariants = {
    //각 이미지 motion 설정
    hidden: { opacity: 0, y: 50 }, //초기엔 y위치가 50
    visible: (custom: number) => ({
      opacity: 1,
      y: 0, //보여지면 정위치로 이동
      transition: {
        duration: 0.5,
        delay: custom * 0.5,
      },
    }),
  };

  React.useEffect(() => {
    if (inView) {
      controls.start("visible");
    } else {
      controls.start("hidden");
    }
  }, [controls, inView]);

  return (
    <FlexBox
      $flexDirection="row"
      $jc="center"
      $isFullWidth
      $py={12}
      $bgcolor={isBgGray ? colors.MAIN_INTRODUCE_BG : colors.WHITE}
    >
      <motion.div
        ref={ref}
        variants={containerVariants}
        initial="hidden"
        animate={controls}
      >
        <FlexBox
          $flexDirection="row"
          $ai="center"
          $isFullWidth
          $maxWidth={MainMaxWidth}
          $gap={10}
        >
          {!desInfo.isRight && (
            <motion.div custom={1} variants={itemVariants}>
              <Box $flex={3}>
                <Img src={desInfo.contentsImg} width={"auto"} />
              </Box>
            </motion.div>
          )}
          <motion.div custom={0} variants={itemVariants}>
            <FlexBox $flex={7}>
              <Box $mb={0.8}>
                <Typograpy
                  fontStyle={fontStyle.title3}
                  color={colors.MAIN_CATEGORY_FONT}
                  fontWeight="Bold"
                >
                  {desInfo.title}
                </Typograpy>
              </Box>
              <Typograpy
                fontStyle={fontStyle.title1}
                color={colors.MAIN_TITLE_FONT}
                fontWeight="ExtraBold"
              >
                {desInfo.subTitle1}
              </Typograpy>
              <Typograpy
                fontStyle={fontStyle.title1}
                color={colors.MAIN_TITLE_FONT}
                fontWeight="ExtraBold"
              >
                {desInfo.subTitle2}
              </Typograpy>
              <Box $mt={0.8}>
                <Typograpy
                  fontStyle={fontStyle.headline1}
                  color={colors.MAIN_SUB_FONT}
                  fontWeight="Regular"
                  style={{ whiteSpace: "pre-wrap" }}
                >
                  {desInfo.description}
                </Typograpy>
              </Box>
            </FlexBox>
          </motion.div>
          {desInfo.isRight && (
            <motion.div custom={1} variants={itemVariants}>
              <Box $flex={3}>
                <Img src={desInfo.contentsImg} width={"auto"} />
              </Box>
            </motion.div>
          )}
        </FlexBox>
      </motion.div>
    </FlexBox>
  );
};
