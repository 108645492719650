import styled from "styled-components";
import { colors } from "theme/colors";
import React from "react";
import { FlexBox } from "theme/globalStyle";

interface InputType {
  $isError?: boolean;
  $isRound?: boolean;
  $isLeftIcon?: React.ReactElement;
  $isRightIcon?: React.ReactElement;
}

const InputWrapper = styled.div`
  position: relative;
`;

const Input = styled.input<InputType>`
  width: 100%;
  box-sizing: border-box;
  background-color: ${colors.INPUT_BACK};
  border-radius: ${({ $isRound }) => ($isRound ? "30px" : "4px")};
  color: ${colors.DEFAULT_FONT_COLOR};
  padding: 1.2rem 2rem;

  ${({ $isLeftIcon }) => $isLeftIcon && `padding-left:4rem;`}
  ${({ $isRightIcon, $isError }) =>
    $isError && $isRightIcon
      ? "padding-right : 7rem;"
      : ($isError || $isRightIcon) && `padding-right:4rem;`}
  font-family: "Regular";
  outline: none;
  border: 1px solid
    ${({ $isError }) =>
      $isError ? colors.INPUT_ERROR_BORDER : colors.INPUT_BORDER};
  font-family: "Regular";
  &::placeholder {
    color: ${colors.INPUT_PLACEHOLDER};
  }
  &:focus {
    border: 1px solid ${colors.INPUT_FOCUS_BORDEDR};
  }
  &:read-only {
    background-color: ${colors.INPUT_DIS_BACK};
  }
`;

const TextLeftIcon = styled.div`
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
`;

const TextRightIcon = styled(FlexBox)`
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  flex-direction: row;
  $gap: 0.8rem;
  align-items: center;
`;

const TextArea = styled.textarea<{ $isError?: boolean }>`
  resize: none;
  min-height: 12rem;
  border-radius: 4px;
  padding: 2rem;
  outline: none;
  border: 1px solid
    ${({ $isError }) =>
      $isError ? colors.INPUT_ERROR_BORDER : colors.INPUT_BORDER};
  font-family: "Regular";
  &::placeholder {
    color: ${colors.INPUT_PLACEHOLDER};
  }
  &:focus {
    border: 1px solid ${colors.INPUT_FOCUS_BORDEDR};
  }
  &:read-only {
    background-color: ${colors.INPUT_DIS_BACK};
  }
`;

export { Input, InputWrapper, TextLeftIcon, TextRightIcon, TextArea };
