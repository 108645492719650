import { AlertModal } from "components/organisms/modal/AlertModal";
import MyCustomerTemplate from "components/templates/saller/customerList/MyCustomerTemplate";
import NewCustomerTemplate from "components/templates/saller/customerList/NewCustomerTemplate";
import React from "react";
import { useNavigate, useOutletContext } from "react-router";
import { useUserInfoStore } from "store/userInfoStore";
import ModalPortal from "utils/Portal";

interface SallerCustomerListType {
  selSubPage: string;
}

export const SallerCustomerList = () => {
  const navigate = useNavigate();
  const { selSubPage } = useOutletContext<SallerCustomerListType>();
  const { payment } = useUserInfoStore((state) => state);

  const [alertModal, setAlertModal] = React.useState({
    visible: false,
    msg: "",
    type: "",
  });

  const alertModalOn = (msg: string, type?: string) => {
    setAlertModal({
      visible: true,
      msg,
      type: type || "",
    });
  };

  const alertAction = () => {
    if (alertModal.type === "go_payment") {
      navigate("/payment", { replace: true });
    }
  };

  React.useEffect(() => {
    if (payment.length === 0) {
      alertModalOn("결제된 구독상품이 존재하지 않습니다.", "go_payment");
    }
  }, []);

  return (
    <>
      <ModalPortal>
        <AlertModal
          visible={alertModal.visible}
          msg={alertModal.msg}
          type={alertModal.type}
          action={alertAction}
          modalOff={() => {
            setAlertModal({ visible: false, msg: "", type: "" });
          }}
        />
      </ModalPortal>
      {payment.length !== 0 && selSubPage === "my" ? (
        <MyCustomerTemplate />
      ) : (
        <NewCustomerTemplate />
      )}
    </>
  );
};
