import styled from "styled-components";
import { colors } from "theme/colors";

const HiddenFileInput = styled.input.attrs({ type: "file" })`
  display: none;
`;

const UploadOutlineBtn = styled.label`
  height: 4.2rem;
  border-radius: 4px;
  padding-left: 1.4rem;
  padding-right: 1.4rem;
  border: 1px solid ${colors.DEFAULT_BORDER_COLOR};

  color: ${colors.DEFAULT_FONT_COLOR};
  font-size: 1.3rem;
  line-height: 1.8rem;
  letter-spacing: 1.94%;
  font-family: "Regular";
  white-space: nowrap;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export { HiddenFileInput, UploadOutlineBtn };
