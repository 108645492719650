import { Divider } from "components/molecules/divider/Divider";
import Typograpy from "components/molecules/Typograpy";
import React from "react";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { Box, FlexBox } from "theme/globalStyle";

interface InputHeaderPropsType {
  title: string;
}

export const InputHeader: React.FC<InputHeaderPropsType> = ({ title }) => {
  return (
    <Box>
      <FlexBox
        $flexDirection="row"
        $ai="center"
        $jc="space-between"
        $isFullWidth
        $mt={6}
      >
        <Typograpy
          fontStyle={fontStyle.title3}
          color={colors.MAIN}
          fontWeight="Bold"
        >
          {title}
        </Typograpy>
        <Box>
          <Typograpy fontSize={12} color={colors.REQUIRE_COLOR}>
            *{" "}
          </Typograpy>
          <Typograpy
            fontStyle={fontStyle.caption1}
            style={{ lineHeight: "2.4rem" }}
            color={colors.REQUIRE_FONT_COLOR}
          >
            필수 입력사항
          </Typograpy>
        </Box>
      </FlexBox>
      <Divider
        variant="normal"
        width={"100%"}
        height={0.1}
        $bgcolor={colors.MAIN}
      />
    </Box>
  );
};
