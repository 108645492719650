import React from "react";
import { Box, FlexBox } from "theme/globalStyle";
import { SelectBox } from "components/molecules/selectbox/SelectBox";
import { PrimaryInput } from "components/molecules/Input/PrimaryInput";
import { OutlineButton } from "components/molecules/button/OutlineButton";

import SearchIcon from "assets/img/ic_search.svg";
import { GetUsersParams } from "api/blueButton/GetUsers";
import { AxiosError } from "axios";
import { useLocation } from "react-router";
import { useUserInfoStore } from "store/userInfoStore";
import { useAdminInfoStore } from "store/useAdminInfoStore";
import { useLocationStore } from "store/useLocationStore";

interface CustomerListFilterProps {
  filterInfo?: GetUsersParams;
  filterSetting?: (value: string, key?: string) => void;
  searchAction?: () => void;
  isAllLocation?: boolean;
}

//테이블 상단 필터 옵션
export const CustomerListFilter: React.FC<CustomerListFilterProps> = React.memo(
  ({ filterInfo, filterSetting, searchAction, isAllLocation }) => {
    const location = useLocation();

    const { locationList } = useLocationStore((state) => state);
    const { payment } = useUserInfoStore((state) => state);
    const { type } = useAdminInfoStore((state) => state);

    const [localList, setLocalList] = React.useState<string[]>([]);

    React.useEffect(() => {
      if (locationList && locationList.length > 0) {
        // localListData는 GetLocalListDataType[] 타입이므로 여기에 접근할 수 있습니다.
        let tempLocalList: string[] = [];
        //결제한 지역만 리스트 표시
        locationList.map((item) => {
          if (type !== "ADMIN" && !isAllLocation) {
            if (
              payment.some(
                (el) => el.sub_product_information.local_id === item.regionId
              )
            ) {
              if (!tempLocalList.includes(item.regionName)) {
                tempLocalList.push(item.regionName);
              }
            }
          } else {
            tempLocalList.push(item.regionName);
            if (filterSetting) filterSetting("전체", "regionId");
          }
        });
        setLocalList([...tempLocalList]);
      }
    }, [locationList]);

    return (
      <FlexBox
        $isFullWidth
        $flexDirection="column"
        $jc={"flex-start"}
        $ai={"flex-start"}
        style={{
          flexWrap: "wrap",
        }}
      >
        {/* {currentPath !== "proposal" && (
          <SolidButton
            buttonLabel="고객리스트 요청"
            leftIcon={DownloadIcon}
            $br={4}
          />
        )} */}
        <Box style={{ whiteSpace: "wrap" }}>
          <FlexBox
            $gap={1.2}
            $flexDirection="row"
            $ai={"flex-end"}
            style={{ flexWrap: "wrap" }}
          >
            <Box>
              <SelectBox
                optList={
                  type !== "ADMIN" && !isAllLocation
                    ? localList
                    : ["전체", ...localList]
                }
                selOpt={String(filterInfo?.regionId)}
                onChangeValue={(value: string) => {
                  if (filterSetting) filterSetting(value, "regionId");
                }}
                placeholder="지역선택"
                label={"지역"}
                fontStyle="font-size:1.3rem;"
                boxStyle={{ width: "15rem" }}
              />
            </Box>
            <Box>
              <SelectBox
                optList={["전체", "보험", "금융"]}
                selOpt={filterInfo?.type1}
                onChangeValue={(value: string) => {
                  if (filterSetting) filterSetting(value, "type1");
                }}
                placeholder="구분"
                label={"구분"}
                fontStyle="font-size:1.3rem;"
              />
            </Box>
            <Box></Box>

            <FlexBox $flexDirection="row" $ai="flex-end" $gap={0.4}>
              <PrimaryInput
                value={filterInfo?.searchKeyword || ""}
                onChangeValue={(value: string) => {
                  if (filterSetting) filterSetting(value, "searchKeyword");
                }}
                placeholder="검색어를 입력해주세요"
                label={"회사명"}
                wrapperStyle={{ maxWidth: "17rem" }}
                inputStyle={{ height: "4.2rem" }}
              />
              {/* <Box>
                <SelectBox
                  optList={["이름", "회사"]}
                  onChangeValue={(value: string) => {
                    if (filterSetting) filterSetting(value, "type2");
                  }}
                  placeholder="선택"
                  selOpt={filterInfo?.type2}
                  fontStyle="font-size:1.3rem;"
                />
              </Box> */}
            </FlexBox>

            {/* <FlexBox $flexDirection="row" $ai="flex-end" $gap={0.4}>
              <PrimaryInput
                value={String(filterInfo?.totalIncome)}
                onChangeValue={(value: string) => {
                  if (filterSetting) filterSetting(value, "totalIncome");
                }}
                placeholder="00,000,000원"
                label={"연봉"}
                wrapperStyle={{ maxWidth: "15rem" }}
                inputStyle={{ height: "4.2rem" }}
              />
              <Box>
                <SelectBox
                  optList={["초과", "이상", "동일", "이하", "미만"]}
                  onChangeValue={(value: string) => {
                    if (filterSetting)
                      filterSetting(value, "totalIncomeOperatorType");
                  }}
                  placeholder="선택"
                  selOpt={filterInfo?.totalIncomeOperatorType}
                  fontStyle="font-size:1.3rem;"
                />
              </Box>
            </FlexBox> */}
            <FlexBox $flexDirection="row" $ai="flex-end" $gap={0.4}>
              <PrimaryInput
                value={String(filterInfo?.age)}
                onChangeValue={(value: string) => {
                  if (filterSetting) filterSetting(value, "age");
                }}
                placeholder="00세"
                label={"나이"}
                wrapperStyle={{ maxWidth: "10rem" }}
                inputStyle={{ height: "4.2rem" }}
              />
              <Box>
                <SelectBox
                  optList={["초과", "이상", "동일", "이하", "미만"]}
                  onChangeValue={(value: string) => {
                    if (filterSetting) filterSetting(value, "ageOperatorType");
                  }}
                  placeholder="선택"
                  selOpt={filterInfo?.ageOperatorType}
                  fontStyle="font-size:1.3rem;"
                />
              </Box>
            </FlexBox>
            <Box>
              <OutlineButton
                isInputLabel
                buttonLabel="검색"
                $br={4}
                type={"assis"}
                fontStyle="font-family:'Regular'; font-size:1.3rem;"
                leftIcon={SearchIcon}
                style={{ height: "4.2rem" }}
                iconSize={15}
                onClick={() => {
                  if (searchAction && filterInfo) searchAction();
                }}
              />
            </Box>
          </FlexBox>
        </Box>
      </FlexBox>
    );
  }
);
