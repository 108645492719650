import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { baseAxios } from "api/baseAxios";
import { AxiosError } from "axios";

export interface CreateCouponBoxParams {
  coupon_id: number;
  salesmanager_id: number;
  expired_date: number;
}

interface GetCouponBoxParams {
  salesmanager_id: number;
}

export interface CouponBoxData {
  couponbox_id: number;
  coupon_id: number;
  salesmanager_id: number;
  use_date: number;
  expired_date: number;
  coupon_usage_status: string;
  created_at: number;
  updated_at: number;
}
export interface CreateCouponBoxResponse {
  status: number;
  message: string;
  data: CouponBoxData;
}

export interface GetCouponBoxDataListResponse {
  status: number; //응답
  message: string; //메세지
  data: CouponBoxData[];
}

export interface PatchCouponBoxUpdateParams {
  couponbox_ids: string;
  coupon_usage_status: "Y" | "N";
}

//쿠폰 생성
export const useCreateCouponBox = () =>
  useMutation<CreateCouponBoxResponse, AxiosError, CreateCouponBoxParams>({
    mutationKey: ["createCouponBox"],
    mutationFn: (params: CreateCouponBoxParams) =>
      baseAxios
        .post("/couponbox", params)
        .then((res) => res.data)
        .catch((err) => console.log(err)),
  });

//쿠폰리스트 불러오기
export const useGetCouponBoxList = ({ salesmanager_id }: GetCouponBoxParams) =>
  useQuery<GetCouponBoxDataListResponse, AxiosError>({
    queryKey: ["couponBoxList"],
    queryFn: () =>
      baseAxios
        .get(`/couponbox`, { params: { salesmanager_id } })
        .then((res) => res.data),
  } as UseQueryOptions<GetCouponBoxDataListResponse, AxiosError>);

export const updateCouponBox = ({
  couponbox_ids,
  coupon_usage_status,
}: PatchCouponBoxUpdateParams) => {
  return baseAxios
    .post("/couponbox/update-status", {
      couponbox_ids,
      coupon_usage_status,
    })
    .then((res) => res.data);
};
