import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { baseAxios } from "api/baseAxios";
import { PageType } from "api/commonType";
import axios, { AxiosError, AxiosResponse } from "axios";

export interface CheckSNSCodeParams {
  rescode: string | null;
  restype: string | null;
}

export type KakaoResponseDataType = {
  connected_at: string;
  id: number;
  kakao_account: {
    birthday: string;
    birthday_needs_agreement: boolean;
    birthday_type: "SOLAR" | "LUNAR"; //양력 음력
    birthyear: string;
    birthyear_needs_agreement: boolean;
    email: string;
    email_needs_agreement: boolean;
    has_birthday: boolean;
    has_birthyear: boolean;
    has_email: boolean;
    is_email_valid: boolean;
    is_email_varified: boolean;
    name: string;
    name_needs_agreement: boolean;
    profile: {
      is_default_image: boolean;
      is_default_nickname: boolean;
      nickname: string;
      profile_image_url: string;
      thumbnail_image_url: string;
    };
    profile_image_needs_agreement: boolean;
    profile_nickname_needs_agreement: boolean;
  };
  properties: {
    nickname: string;
    profile_image: string;
    thumbnail_image: string;
  };
};

export type SociaDataType = {
  social_id?: number;
  type?: "kakao" | "naver";
  userProfile?: KakaoResponseDataType;
  token?: {
    token: string;
  };
};

export interface CheckSNSCodeResponse {
  success: boolean;
  msg: string;
  data: SociaDataType;
}

// 영업자 - 고객정보 - 상품정보 (보험,금융)
export const useCheckSNSCode = (params: CheckSNSCodeParams) =>
  useQuery<CheckSNSCodeResponse, AxiosError>({
    queryKey: ["checkSnsCode"],
    queryFn: () => {
      if (params.rescode && params.restype) {
        return baseAxios
          .get(
            `/auth/social/callback?code=${params.rescode}&type=${params.restype}`
          )
          .then((res) => {
            return res.data;
          })
          .catch((err) => console.log(err));
      }
    },
  } as UseQueryOptions<CheckSNSCodeResponse, AxiosError>);
