import { useQueryClient } from "@tanstack/react-query";

import { SolidButton } from "components/molecules/button/SolidButton";
import { AlertModal } from "components/organisms/modal/AlertModal";
import React from "react";
import { Box, FlexBox } from "theme/globalStyle";
import ModalPortal from "utils/Portal";

import "@atlaskit/css-reset";
import { SubscribePlanCard } from "components/organisms/card/SubscribePlanCard";
import {
  CreateSubProductParamsWithType,
  useGetSubProductList,
} from "api/api.subproduct";
import { ManageSubscribeModal } from "components/organisms/modal/ManageSubscribeModal";

export const SubscribeSetting = () => {
  const queryClient = useQueryClient();

  const { data: subProductData } = useGetSubProductList();

  const [subProductList, setSubProductList] = React.useState<
    CreateSubProductParamsWithType[]
  >([]);

  const [manageSubModal, setManageSubModal] = React.useState<{
    visible: boolean;
    sub_product_id: number;
    type: "create" | "edit";
  }>({
    visible: false,
    sub_product_id: 0,
    type: "create",
  });

  const [tempDeleteId, setTempDeleteId] = React.useState<number | "">("");

  const [alertModal, setAlertModal] = React.useState({
    visible: false,
    msg: "",
    type: "",
  });

  const alertModalOn = (msg: string, type?: string) => {
    setAlertModal({
      visible: true,
      msg,
      type: type || "",
    });
  };

  const alertModalOff = () => {
    setAlertModal({
      visible: false,
      msg: "",
      type: "",
    });
  };

  const alertAction = () => {
    // if (alertModal.type === "delete_confirm") {
    //   if (tempDeleteId !== "") {
    //   }
    // } else if (alertModal.type === "delete_success") {
    //   queryClient.invalidateQueries({
    //     queryKey: ["localList"],
    //   });
    // }
  };

  React.useEffect(() => {
    if (subProductData) {
      if (subProductData.status === 200) {
        setSubProductList([...subProductData.data]);
      }
    }
  }, [subProductData]);

  return (
    <FlexBox $pa={2} $isFullWidth>
      <ModalPortal>
        <AlertModal
          visible={alertModal.visible}
          modalOff={alertModalOff}
          msg={alertModal.msg}
          type={alertModal.type}
          action={alertAction}
        />
      </ModalPortal>
      <ModalPortal>
        <ManageSubscribeModal
          visible={manageSubModal.visible}
          modalOff={() => {
            setManageSubModal({
              visible: false,
              sub_product_id: 0,
              type: "create",
            });
          }}
          mode={manageSubModal.type}
          sub_product_id={manageSubModal.sub_product_id}
        />
      </ModalPortal>

      <FlexBox $ai={"center"} $isFullWidth>
        <Box
          $isFullWidth
          $px={4}
          style={{
            flexWrap: "wrap",
            gap: "2rem",
            gridTemplateColumns: "repeat(4,1fr)",
          }}
          display={"grid"}
        >
          {subProductList.map((item, index) => {
            return (
              <SubscribePlanCard
                isSetting
                subscribeInfo={item}
                key={"subitem" + index}
                setManageSubModal={setManageSubModal}
              />
            );
          })}
        </Box>
      </FlexBox>
      <FlexBox $px={4} $mt={3} $isFullWidth $flexDirection="row" $jc={"center"}>
        <SolidButton
          buttonLabel="구독상품 생성"
          size={"large"}
          $br={4}
          onClick={() => {
            setManageSubModal({
              visible: true,
              sub_product_id: 0,
              type: "create",
            });
          }}
        />
      </FlexBox>
    </FlexBox>
  );
};
