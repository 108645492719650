import { TextArea } from "components/atoms/input/input";
import React from "react";
import { CSSProperties } from "styled-components";

interface TextareaPropsType {
  value: string;
  onChangeValue: (value: string, key?: string) => void;
  placeholder: string;
  stateKey?: string;
  $isError?: boolean;
  readonly?: boolean;
  style?: CSSProperties;

  inputMax?: number;
}

export const Textarea: React.FC<TextareaPropsType> = ({
  value,
  onChangeValue,
  placeholder,
  stateKey,
  $isError,
  readonly,
  inputMax,
  style,
}) => {
  return (
    <TextArea
      value={value}
      onChange={({ target }) => {
        if (stateKey) {
          onChangeValue(target.value, stateKey);
        } else {
          onChangeValue(target.value);
        }
      }}
      $isError={$isError}
      readOnly={readonly}
      placeholder={placeholder}
      maxLength={inputMax}
      style={{ ...style }}
    />
  );
};
