import { useUpdateSalesmanager } from "api/api.salesmanager";
import { useRefreshToken } from "api/auth/RefreshToken";
import { IcEyeOff, IcKakao } from "components/atoms/icon/icon";
import { SolidButton } from "components/molecules/button/SolidButton";
import { Divider } from "components/molecules/divider/Divider";
import { PrimaryInput } from "components/molecules/Input/PrimaryInput";
import { Label } from "components/molecules/label/Label";
import { Radio } from "components/molecules/radio/Radio";
import { SelectBox } from "components/molecules/selectbox/SelectBox";
import Typograpy from "components/molecules/Typograpy";
import { PostCodeModal } from "components/organisms/modal/PostCodeModal";
import { InputHeader } from "components/organisms/user/InputHeader";
import React from "react";
import { useSnackbarStore } from "store/snackbarStore";
import { useLoadingStore } from "store/useLoadingStore";
import { useUserInfoStore } from "store/userInfoStore";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { Box, FlexBox } from "theme/globalStyle";
import {
  convertUnixDate,
  getUnixDate,
  renderDayOptions,
  renderMonthOptions,
  renderYearOptions,
} from "utils/func";
import ModalPortal from "utils/Portal";

const InfoTemplate = () => {
  const showSnackbar = useSnackbarStore((state) => state.showSnackbar);
  const sallerInfo = useUserInfoStore((state) => state);
  const { updateLoading } = useLoadingStore((state) => state);
  const { mutate: updateSalesmanager, isPending: updateSalesPending } =
    useUpdateSalesmanager({
      salesmanager_id: sallerInfo.salesmanager_id,
    });
  const { mutate: refreshToken } = useRefreshToken();

  const [myInfo, setMyInfo] = React.useState({
    sales_phone: sallerInfo.sales_phone,
    sales_phone_authcode: "",
    slaes_phone_check: false,
    salesmanager_year: "",
    salesmanager_month: "",
    salesmanager_day: "",
    salesmanager_gender: sallerInfo.salesmanager_gender,
    salesmanager_zip: String(sallerInfo.zip_code || ""),
    salesmanager_address: sallerInfo.salesmanager_address,
    salesmanager_address_detail: sallerInfo.salesmanager_address_detail,
    company_name: sallerInfo.company_name,
    email_address: sallerInfo.email_address,
    company_zip_code: String(sallerInfo.company_zip_code || ""),
    company_address: sallerInfo.company_address,
    company_address_detail: sallerInfo.company_address_detail,
    sales_item: sallerInfo.sales_item,
  });

  const [inputError, setInputError] = React.useState({
    salesmanager_address: false,
    salesmanager_address_detail: false,
    company_name: false,
    company_address: false,
  });

  const [phoneAuthCode, setPhoneAuthCode] = React.useState("");
  const [postCodeModal, setPostCodeModal] = React.useState(false);
  const [salesPostCodeModal, setSalesPostCodeModal] = React.useState(false);

  function inputHandler(value: string, key?: string) {
    if (key) {
      setMyInfo({
        ...myInfo,
        [key]: value,
      });
    }
  }

  function inputValidate() {
    if (myInfo.salesmanager_address === "") {
      setInputError((prev) => ({ ...prev, salesmanager_address: true }));
      showSnackbar("주소를 입력해주세요.");
      return false;
    } else if (myInfo.salesmanager_address_detail === "") {
      setInputError((prev) => ({ ...prev, salesmanager_address_detail: true }));
      showSnackbar("상세주소를 입력해주세요.");
      return false;
    } else if (myInfo.company_name === "") {
      setInputError((prev) => ({ ...prev, company_name: true }));
      showSnackbar("소속정보를 입력해주세요.");
      return false;
    } else if (myInfo.company_address === "") {
      setInputError((prev) => ({ ...prev, company_address: true }));
      showSnackbar("영업회사 주소를 입력해주세요.");
      return false;
    }

    return true;
  }

  //내정보 변경
  function changeInfoAccess() {
    if (!inputValidate()) return false;

    const params = {
      company_zip_code: Number(myInfo.company_zip_code),
      company_address: myInfo.company_address,
      company_address_detail: myInfo.company_address_detail,
      company_name: myInfo.company_name,
      email_address: myInfo.email_address,
      zip_code: Number(myInfo.salesmanager_zip),
      salesmanager_address: myInfo.salesmanager_address,
      salesmanager_address_detail: myInfo.salesmanager_address_detail,
      salesmanager_birthday: getUnixDate(
        myInfo.salesmanager_year,
        myInfo.salesmanager_month.padStart(2, "0"),
        myInfo.salesmanager_day.padStart(2, "0")
      ),
      salesmanager_gender: sallerInfo.salesmanager_gender,
      salesmanager_name: sallerInfo.salesmanager_name,
      sales_item: myInfo.sales_item,
    };
    updateSalesmanager(params, {
      onSuccess: () => {
        showSnackbar("정보변경이 완료되었습니다.");
        refreshToken();
      },
      onError: (error) => {
        console.error(error);
        showSnackbar("정보변경에 실패하였습니다.");
      },
    });
  }

  React.useEffect(() => {
    const convertDate = convertUnixDate(sallerInfo.salesmanager_birthday);

    const [year, month, day] = convertDate.split(".");

    setMyInfo({
      ...myInfo,
      salesmanager_year: year,
      salesmanager_month: String(Number(month)),
      salesmanager_day: String(Number(day)),
    });
  }, []);

  React.useEffect(() => {
    updateLoading(updateSalesPending);
  }, [updateSalesPending]);

  return (
    <FlexBox $isFullWidth $ai="center">
      <ModalPortal>
        <PostCodeModal
          visible={postCodeModal}
          modalOff={() => {
            setPostCodeModal(false);
          }}
          onChange={(data) => {
            setPostCodeModal(false);
            setMyInfo({
              ...myInfo,
              salesmanager_zip: data.zonecode,
              salesmanager_address: data.roadAddress,
            });
            setInputError((prev) => ({ ...prev, salesmanager_address: false }));
          }}
        />
      </ModalPortal>
      <ModalPortal>
        <PostCodeModal
          visible={salesPostCodeModal}
          modalOff={() => {
            setSalesPostCodeModal(false);
          }}
          onChange={(data) => {
            setSalesPostCodeModal(false);
            setMyInfo({
              ...myInfo,
              company_zip_code: data.zonecode,
              company_address: data.roadAddress,
            });
            setInputError((prev) => ({ ...prev, company_address: false }));
          }}
        />
      </ModalPortal>
      <FlexBox
        $isFullWidth
        $flexDirection="row"
        $jc="space-between"
        $ai="center"
        height={6}
        $bbw={1}
        $bbc={colors.DEFAULT_BORDER_COLOR}
        $pl={2}
        $pr={4}
      >
        <Typograpy
          fontStyle={fontStyle.body1_normal}
          fontWeight="SemiBold"
          color={colors.SALLER_SUBSIDE_TITLE}
        >
          기본정보
        </Typograpy>
      </FlexBox>

      <FlexBox $maxWidth={60} $mb={4} $px={2} $isFullWidth>
        <Box $isFullWidth>
          <InputHeader title={"가입정보"} />
          <Divider variant="normal" width={"100%"} $bgcolor={colors.MAIN} />
          {/* <Box $mb={2} />
          <FlexBox
            $flexDirection="row"
            $ai="center"
            $jc="center"
            $gap={0.8}
            $flex={1}
            $py={1.5}
            $br={4}
            $bgcolor={colors.KAKAO_BG}
            $isPointer
            onClick={() => {
              // handleSocialLogin("kakao");
            }}
          >
            <IcKakao />
            <Typograpy
              fontStyle={fontStyle.headline1}
              color={colors.BLACK}
              fontWeight="Medium"
            >
              카카오 연동
            </Typograpy>
          </FlexBox> */}
          <Box $mt={2} />
          <PrimaryInput
            value={sallerInfo.salesmanager_account}
            placeholder="아이디를 입력해주세요."
            onChangeValue={() => {}}
            label={"아이디"}
            isRequire
            wrapperStyle={{ width: "100%" }}
            readonly
            rightButton={
              <Box />
              // <SolidButton
              //   buttonLabel="중복확인"
              //   style={{ width: "100%", height: "100%" }}
              // />
            }
          />
          <Box $mt={2}>
            <PrimaryInput
              value={sallerInfo.salesmanager_name}
              placeholder="이름을 입력해주세요"
              onChangeValue={() => {}}
              label={"이름"}
              isRequire
              wrapperStyle={{ width: "100%" }}
              readonly
              rightButton={<Box />}
            />
          </Box>
          {/* <Box $mt={2}>
            <PrimaryInput
              value={myInfo.email_address}
              placeholder="예:bluelions@bluelions.kr"
              onChangeValue={inputHandler}
              label={"이메일"}
              isRequire
              wrapperStyle={{ width: "100%" }}
              rightButton={
                myInfo.email_address === myInfo.email_address ? (
                  <Box />
                ) : (
                  <SolidButton
                    buttonLabel="중복확인"
                    style={{ width: "100%", height: "100%" }}
                  />
                )
              }
              stateKey={"email_address"}
            />
          </Box> */}

          <Box $mt={2}>
            <Label label={"성별"} />
            <FlexBox $flexDirection="row" $gap={0.8}>
              <Radio
                name={"gender"}
                value={"M"}
                label={"남자"}
                checked={myInfo.salesmanager_gender === "M"}
                onChange={(e) => {
                  inputHandler(e.target.value, "salesmanager_gender");
                }}
              />
              <Radio
                name={"gender"}
                value={"F"}
                label={"여자"}
                checked={myInfo.salesmanager_gender === "F"}
                onChange={(e) => {
                  inputHandler(e.target.value, "salesmanager_gender");
                }}
              />
              <Radio
                name={"gender"}
                value={"N"}
                label={"선택안함"}
                checked={myInfo.salesmanager_gender === "N"}
                onChange={(e) => {
                  inputHandler(e.target.value, "salesmanager_gender");
                }}
              />
            </FlexBox>
          </Box>
          <FlexBox $flexDirection="row" $gap={1.2} $mt={2} $ai="flex-end">
            <Box $flex={3}>
              <SelectBox
                selOpt={myInfo.salesmanager_year}
                optList={renderYearOptions()}
                onChangeValue={(value: string, key?: string) => {
                  inputHandler(value, key);
                  // setInputError((prev) => ({
                  //   ...prev,
                  //   birth: false,
                  // }));
                }}
                placeholder="년도"
                label="생년월일"
                footerLabel="년"
                stateKey="salesmanager_year"
              />
            </Box>
            <Box $flex={2}>
              <SelectBox
                selOpt={myInfo.salesmanager_month}
                optList={renderMonthOptions()}
                onChangeValue={(value: string, key?: string) => {
                  inputHandler(value, key);
                  // setInputError((prev) => ({
                  //   ...prev,
                  //   birth: false,
                  // }));
                }}
                placeholder="월"
                footerLabel="월"
                stateKey="salesmanager_month"
              />
            </Box>
            <Box $flex={2}>
              <SelectBox
                selOpt={myInfo.salesmanager_day}
                optList={renderDayOptions()}
                onChangeValue={(value: string, key?: string) => {
                  inputHandler(value, key);
                  // setInputError((prev) => ({
                  //   ...prev,
                  //   birth: false,
                  // }));
                }}
                placeholder="일"
                footerLabel="일"
                stateKey="salesmanager_day"
              />
            </Box>
          </FlexBox>
          {/* {inputError.birth && (
            <Box $mt={0.4}>
              <Typograpy fontStyle={fontStyle.caption1} color={colors.RED}>
                생년월일을 선택해주세요.
              </Typograpy>
            </Box>
          )} */}
          <FlexBox $mt={2} $isFullWidth>
            <Label label={"주소"} isRequire />
            <FlexBox $isFullWidth $flexDirection="row" $ai="stretch" $gap={1.2}>
              <Box $flex={3}>
                <PrimaryInput
                  readonly
                  value={myInfo.salesmanager_zip}
                  placeholder="우편번호"
                  onChangeValue={() => {}}
                  wrapperStyle={{ width: "100%" }}
                />
              </Box>
              <Box $flex={2}>
                <SolidButton
                  buttonLabel="주소찾기"
                  style={{ width: "100%", height: "100%" }}
                  onClick={() => {
                    setPostCodeModal(true);
                  }}
                />
              </Box>
              <Box $flex={2}></Box>
            </FlexBox>
            <Box $mt={1.2} $isFullWidth>
              <PrimaryInput
                readonly
                value={myInfo.salesmanager_address}
                placeholder="기본 주소"
                onChangeValue={(value, key) => {
                  inputHandler(value, key);
                  setInputError((prev) => ({
                    ...prev,
                    salesmanager_address: false,
                  }));
                }}
                wrapperStyle={{ width: "100%" }}
                stateKey="salesmanager_address"
                $isError={inputError.salesmanager_address}
                errorMsg="주소를 입력해주세요."
              />
            </Box>
            <Box $mt={1.2} $isFullWidth>
              <PrimaryInput
                value={myInfo.salesmanager_address_detail}
                placeholder="상세 주소"
                onChangeValue={(value, key) => {
                  inputHandler(value, key);
                  setInputError((prev) => ({
                    ...prev,
                    salesmanager_address_detail: false,
                  }));
                }}
                wrapperStyle={{ width: "100%" }}
                stateKey="salesmanager_address_detail"
                $isError={inputError.salesmanager_address_detail}
                errorMsg="상세주소를 입력해주세요."
              />
            </Box>
          </FlexBox>

          <InputHeader title={"영업정보"} />
          <Divider variant="normal" width={"100%"} $bgcolor={colors.MAIN} />

          <Box $isFullWidth $mt={2}>
            <PrimaryInput
              value={myInfo.company_name}
              placeholder="소속을 입력해주세요."
              onChangeValue={(value: string, key?: string) => {
                inputHandler(value, key);
                // setInputError((prev) => ({ ...prev, sales_group: false }));
              }}
              label={"소속"}
              isRequire
              wrapperStyle={{ width: "100%" }}
              rightButton={<Box />}
              stateKey="company_name"
              // $isError={inputError.sales_group}
              errorMsg={"소속을 입력해주세요."}
            />
          </Box>
          <Box $mt={2}>
            <PrimaryInput
              value={myInfo.email_address}
              placeholder="예:bluelions@bluelions.kr"
              onChangeValue={() => {}}
              label={"영업 이메일"}
              wrapperStyle={{ width: "100%" }}
              readonly
            />
          </Box>
          <Box $mt={2}>
            <PrimaryInput
              readonly
              value={myInfo.sales_phone}
              placeholder="휴대폰번호를 입력해주세요."
              onChangeValue={inputHandler}
              label={"휴대폰번호"}
              isRequire
              wrapperStyle={{ width: "100%" }}
              rightButton={
                myInfo.sales_phone !== sallerInfo.sales_phone ? (
                  <SolidButton
                    buttonLabel="인증번호발송"
                    style={{ width: "100%", height: "100%" }}
                  />
                ) : (
                  <></>
                )
              }
              stateKey={"sales_phone"}
            />
            {phoneAuthCode !== "" && (
              <Box $mt={1}>
                <PrimaryInput
                  value={""}
                  placeholder="예:bluelions@bluelions.kr"
                  onChangeValue={() => {}}
                  isRequire
                  wrapperStyle={{ width: "100%" }}
                  rightButton={
                    <SolidButton
                      buttonLabel="인증확인"
                      style={{ width: "100%", height: "100%" }}
                    />
                  }
                />
              </Box>
            )}
          </Box>
          <FlexBox $mt={2} $isFullWidth>
            <Label label={"회사주소"} isRequire />
            <FlexBox $isFullWidth $flexDirection="row" $ai="stretch" $gap={1.2}>
              <Box $flex={3}>
                <PrimaryInput
                  readonly
                  value={myInfo.company_zip_code}
                  placeholder="우편번호"
                  onChangeValue={() => {}}
                  wrapperStyle={{ width: "100%" }}
                  // $isError={inputError.sales_address}
                />
              </Box>
              <Box $flex={2}>
                <SolidButton
                  buttonLabel="주소찾기"
                  style={{ width: "100%", height: "100%" }}
                  onClick={() => {
                    setSalesPostCodeModal(true);
                  }}
                />
              </Box>
              <Box $flex={2}></Box>
            </FlexBox>
            <Box $mt={1.2} $isFullWidth>
              <PrimaryInput
                readonly
                value={myInfo.company_address}
                placeholder="기본 주소"
                onChangeValue={() => {}}
                wrapperStyle={{ width: "100%" }}
              />
            </Box>
            <Box $mt={1.2} $isFullWidth>
              <PrimaryInput
                value={myInfo.company_address_detail}
                placeholder="상세 주소"
                onChangeValue={(value: string, key?: string) => {
                  inputHandler(value, key);
                }}
                wrapperStyle={{ width: "100%" }}
                stateKey="company_address_detail"
              />
            </Box>
          </FlexBox>
          <Box $isFullWidth $mt={2}>
            <PrimaryInput
              value={myInfo.sales_item}
              placeholder="영업품목 입력해주세요."
              onChangeValue={(value: string, key?: string) => {
                inputHandler(value, key);
              }}
              label={"영업품목"}
              wrapperStyle={{ width: "100%" }}
              rightButton={<Box />}
              stateKey="sales_item"
              // $isError={inputError.sales_group}
              errorMsg={"영업품목을 입력해주세요."}
            />
          </Box>
        </Box>

        <Box $isFullWidth $mt={4}>
          <Divider
            variant="thick"
            width={"100%"}
            height={0.2}
            $bgcolor={colors.MAIN}
          />
        </Box>
        <Box $mt={2} $isFullWidth>
          <SolidButton
            size={"large"}
            buttonLabel="저장"
            style={{
              width: "100%",
              paddingTop: "2rem",
              paddingBottom: "2rem",
            }}
            onClick={() => {
              changeInfoAccess();
              //   navigate("/join/payment");
            }}
          />
        </Box>
      </FlexBox>
    </FlexBox>
  );
};

export default InfoTemplate;
