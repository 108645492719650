import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { baseAxios } from "api/baseAxios";
import { PageType } from "api/commonType";
import axios, { AxiosError, AxiosResponse } from "axios";
import { base64Encode } from "utils/func";

export interface GetProductTypeParams {
  id: number; //선택한 회원 id
}

export interface CardsType {
  companyName: string; //카드회사
  amount: number; //한해 사용금액
}

export interface HousingFundType {
  housingFundName: string; //대출이름
  redemptionAmount: number; //현재 상환액
  interestAmount: number; //현재 상환 이자액
}

export interface LongTermCollectiveType {
  insitution: string; //상품을 만든 기관
  fundName: string; // 펀드이름
  annualTotalAmount: number; //펀드 연간납입 금액
  deductionTargetAmount: number; //공제대상 금액
}

//금융정보 리스트 type
export interface GetFinanceReponseType {
  creditCards: CardsType[]; //개인사용 카드
  debitCards: CardsType[]; //직불카드
  housingFundLoans: HousingFundType[]; //주택자금대출
  longTermCollectiveInvestments: LongTermCollectiveType[]; //증권
}

export interface GetUsersResponse {
  status: number; //응답
  message: string; //메세지
  data: {
    success: boolean;
    response: GetFinanceReponseType;
    error: null | string;
    page: PageType;
  };
}

// 영업자 - 고객정보 - 상품정보 (금융)
export const useGetFinancesInfo = (params: GetProductTypeParams) =>
  useQuery<GetUsersResponse, AxiosError>({
    queryKey: ["getFinancesInfo"],
    queryFn: () => {
      return baseAxios
        .get(
          `/blue-button-api/fetch/year-end-settlement/finance-infos?filter=${JSON.stringify(
            params
          )}`,
          {}
        )
        .then((res) => res.data)
        .catch((err) => console.log(err));
    },
  } as UseQueryOptions<GetUsersResponse, AxiosError>);
