import {
  IconOutlineLoading,
  IconSolidLoading,
} from "components/atoms/icon/animated";
import React from "react";
import { FlexBox } from "theme/globalStyle";

export const LoadingModal = () => {
  return (
    <FlexBox
      $position="fixed"
      top={0}
      $left={0}
      width={"100vw"}
      height={"100vh"}
      $bgcolor="rgba(0,0,0,0.5)"
      $ai={"center"}
      $jc={"center"}
      style={{ zIndex: 999999 }}
    >
      <IconOutlineLoading width={8} height={8} />
    </FlexBox>
  );
};
