import { useMutation, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { baseAxios } from "api/baseAxios";
import { AxiosError, AxiosResponse } from "axios";
import { UserInfoType } from "store/userInfoStore";

//제안서 생성 type
export interface CreateProposalParams {
  userInfo: {
    user_id: number; //유저 id
    email: string; //이메일
    name: string; //이름
    address: string; //주소
    year: number; //데이터 연도
    businessName: string; //회사이름
    age: number; //나이
    totalIncome: number; //총소득
    isInsuranceInfo: boolean; //보험 데이터가 있는지 여부
    isFinancialInfo: boolean; // 금융 데이터 있는지 여부
    createdDate: number;
  };
  salesmanager_id: number;
  sales_proposal: {
    salesmanager_id: number;
    product_information: {
      //삭제예정
      product_name: string;
      product_content: string;
      is_show: string;
    };
    proposal_type: "insurances" | "finance";
    proposal_title: string;
    proposal_content: string;
    notification: "YES" | "NO";
    file_info: {
      proposal_file_id?: number;
      file_name: string;
      file_url: string;
    }[];
  };
  proposition_status: "WAIT";
  user_proposal_id: number;
}

//제안서 반환 타입
export interface GetProposalListData {
  user_proposal_id: number; //제안서 id
  user_id: number; //user id
  salesmanager_id: number; //제안서 작성한 설계사 id
  proposition_status: string; //제안서 상태
  updated_at: number; //수정일자
  created_at: number; //작성일자
  sales_proposal: {
    //제안서 정보
    created_at: number;
    notification: string;
    proposal_title: string;
    proposal_content: string;
    proposal_type: string;
    salesmanager_id: number;
    file_info: {
      proposal_file_id?: number;
      file_name: string;
      file_url: string;
    }[];
    product_information: {
      //상품정보 (사용 x)
      created_at: number;
      is_show: string;
      product_content: string;
      product_id: number;
      product_name: string;
      updated_at: number;
    };
  };
  salesmanager: UserInfoType;
}

//유저 제안서 생성
export interface CreateProposalResponse {
  status: number; //응답
  message: string; //메세지
  data: CreateProposalParams;
}

//유저 제안서 리스트
export interface GetProposalListResponse {
  status: number;
  message: string;
  data: GetProposalListData[];
}

//유저 제안서 detail
export interface GetProposalDetailResponse {
  status: number;
  message: string;
  data: GetProposalListData;
}

//유저제안서 생성 완료 후 action props
interface CreateProposalProps {
  onSuccess: (data: AxiosResponse<CreateProposalResponse>) => void;
  onError: (error: AxiosError) => void;
}

//영업제안서 생성
export const useCreateProposal = ({
  onSuccess,
  onError,
}: CreateProposalProps) =>
  useMutation<
    AxiosResponse<CreateProposalResponse>,
    AxiosError,
    CreateProposalParams
  >({
    mutationKey: ["userProposal"],
    mutationFn: (params: CreateProposalParams) =>
      baseAxios.post("/user_proposal", params),
    onSuccess,
    onError,
  });

//유저제안서 detail
export const useGetProposalDetail = (userProposalId: number) =>
  useQuery<GetProposalDetailResponse, AxiosError>({
    queryKey: ["proposalDetail"],
    queryFn: () =>
      baseAxios.get(`/user_proposal/${userProposalId}`).then((res) => res.data),
  } as UseQueryOptions<GetProposalDetailResponse, AxiosError>);

//유저제안서 리스트
export const useGetProposalList = (salesmanager_id: number) =>
  useQuery<GetProposalListResponse, AxiosError>({
    queryKey: ["proposalList"],
    queryFn: () =>
      baseAxios
        .get("user_proposal", { params: { salesmanager_id } })
        .then((res) => res.data),
  } as UseQueryOptions<GetProposalListResponse, AxiosError>);
