import AdminHomeTemplate from "components/templates/admin/AdminHomeTemplate";
import SalesHomeTemplate from "components/templates/saller/SalesHomeTemplate";
import React from "react";
import { useOutletContext } from "react-router";

interface SallerMainPageType {
  selSubPage: string;
}

export const SallerMainPage = () => {
  const { selSubPage } = useOutletContext<SallerMainPageType>();

  return <SalesHomeTemplate />;
};
