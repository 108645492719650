import { baseUrl } from "api/baseAxios";

export const getEmailAuthTemplate = (authCode: string) => {
  return `
    <html>
      <body>
        <div style="max-width: 768px; width: 100%; background-color: white">
          <div style="padding: 20px">
            <div
              style="
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 8px;
              "
            >
              <img
                src="https://bluesales-store.s3.ap-northeast-2.amazonaws.com/logo/bluesales_logo.png"
                style="width: 40px; height: 40px"
              />
              <span
                style="
                  font-size: 24px;
                  font-weight: 400;
                "
                ><span style="font-weight: bold">Blue</span>sale</span
              >
            </div>
            <div
              style=""
              style="
                font-size: 28px;
                font-weight: 400;
                margin-top: 12px;
              "
            >
              요청하신 <span style="color: #5c60c8">인증번호</span>를
              발송해드립니다.
            </div>
          </div>
          <hr
            style="border: none; height: 2px; background-color: #e7e7e7; margin: 0"
          />
          <div style="padding: 20px">
            <p
              style="
                font-size: 18px;
                font-weight: 400;
                color: #848484;
              "
            >
              아래의 인증번호를 인증번호 입력창에 입력해 주세요.
            </p>
            <p
              style="
                font-size: 18px;
                font-weight: 400;
                color: #848484;
                margin-top: 30px;
                margin-bottom: 30px;
              "
            >
              <span>인증번호 :</span>
              <span style="color: #5c60c8; font-weight: bold">${authCode}</span>
            </p>
            <p
              style="
                font-size: 18px;
                font-weight: 400;
                color: #848484;
              "
            >
              블루세일즈를 이용해주셔서 감사합니다.
            </p>
            <p
              style="
                font-size: 18px;
                font-weight: 400;
                
                color: #848484;
              "
            >
              더욱 편리한 서비스를 제공하기 위해 항상 최선을 다하겠습니다.
            </p>
          </div>
          <div style="padding: 20px; background-color: #fafafb">
            <p
              style="
                font-size: 16px;
                font-weight: 400;
                color: #848484;
              "
            >
              본 메일은 발신전용입니다. 블루세일즈 서비스 관련 궁금하신 사항은
              고객센터에서 확인해주세요.
            </p>
            <p
              style="
                font-size: 16px;
                font-weight: 400;
                font-family: 'Apple SD Gothic Neo';
                color: #848484;
              "
            >
              Copyright © 블루라이언스 | 블루세일즈. All Rights Reserved.
            </p>
          </div>
        </div>
      </body>
    </html>
  `;
};

export const proposalEmailTemplate = (
  proposal_id: number,
  productType: "insurances" | "finance",
  salesmanager_name: string,
  company_name: string,
  proposal_contents: string,
  fileList?: string
) => {
  return `
  <html>
    <body>
      <div style="max-width: 768px; width: 100%; background-color: white">
        <div style="padding: 20px; padding-top:30px; padding-bottom:30px;">
          <div
            style="
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 8px;
            "
          >
            <img
              src="https://bluesales-store.s3.ap-northeast-2.amazonaws.com/logo/bluesales_logo.png"
              style="width: 40px; height: 40px"
            />
            <span
              style="
                font-size: 24px;
                font-weight: 400;
              "
              ><span style="font-weight: bold">Blue</span>sale</span
            >
          </div>
          <div
            style="
              font-size: 28px;
              font-weight: 400;
              margin-top: 12px;
            "
          >
            블루세일즈에서 <span style="color: #5c60c8; font-weight:bold;">${
              productType === "insurances" ? "보험상품" : "금융상품"
            }</span>을
            제안드립니다.
          </div>
        </div>
        <hr
          style="border: none; height: 2px; background-color: #e7e7e7; margin: 0"
        />
        <div style="padding: 20px; padding-top:30px; padding-bottom:30px;">
          <p
              style="
                font-size: 18px;
                font-weight: 400;
                color: #848484;
                margin-bottom:40px;
              "
            >
              영업담당자 : ${salesmanager_name}
            </p>
            <p
              style="
                font-size: 18px;
                font-weight: 400;
                color: #848484;
                margin-bottom:40px;
              "
            >
              회사명 : <span style="font-weight:bold; color:#5c60c8;">${company_name}</span>
            </p>
            ${proposal_contents}
        </div>
        <hr
          style="border: none; height: 2px; background-color: #e7e7e7; margin: 0"
        />
        <div style="padding:20px; padding-top:30px; padding-bottom:30px;">
        ${
          fileList
            ? `
            <div style="margin-bottom:30px">
              <p
                style="
                  font-size: 18px;
                  font-weight: 400;
                  color: #848484;
                  margin-bottom:8px;
                "
              >
                첨부파일
              </p>
            ${fileList}
            </div>
          `
            : ``
        }
          <div>
            <p
              style="
                font-size: 16px;
                font-weight: 400;
                color: #848484;
              "
            >
              제안 수락시 해당 설계사에게만 고객님의 정보가 표시됩니다.
            </p>
            <p
              style="
                font-size: 16px;
                font-weight: 400;
                color: #848484;
                margin-top:10px;
              "
            >
              - 표시정보 : 고객명, 휴대폰 번호
            </p>
          </div>
          <div style="margin-top:20px">
              <a 
                href="${baseUrl}/api/v1/user_proposal/update-status?user_proposal_id=${proposal_id}&proposition_status=REJECT" 
                target="_blank" 
                style="text-decoration: none; padding:10px 30px; background-color:#DBDBDB; color:black;"
              >
                거절
              </a>
              <a 
                href="${baseUrl}/api/v1/user_proposal/update-status?user_proposal_id=${proposal_id}&proposition_status=ACCEPT" 
                target="_blank" 
                style="text-decoration: none; padding:10px 30px; background-color:#5c60c8; color:white; margin-left:12px;"
              >
                수락
              </a>
            </div>
         </div>
        
        <div style="padding: 20px; padding-top:30px; padding-bottom:30px; background-color: #fafafb">
          <p
            style="
              font-size: 16px;
              font-weight: 400;
              color: #848484;
            "
          >
            본 메일은 발신전용입니다. 블루세일즈 서비스 관련 궁금하신 사항은
            고객센터에서 확인해주세요.
          </p>
          <p
            style="
              font-size: 16px;
              font-weight: 400;
              font-family: 'Apple SD Gothic Neo';
              color: #848484;
            "
          >
            Copyright © 블루라이언스 | 블루세일즈. All Rights Reserved.
          </p>
        </div>
      </div>
    </body>
  </html>
  `;
};
