import styled from "styled-components";
import { Box, borderMixin, marginPaddingMixin } from "theme/globalStyle";
import { CustomImgType } from "theme/styles.type";

const Img = styled.img<CustomImgType>`
  ${({ opacity }) => opacity && `opacity : ${opacity};`}
  ${({ width }) =>
    width && `width: ${typeof width === "string" ? width : `${width}rem`};`}
  ${({ height }) =>
    height &&
    `
        height: ${typeof height === "string" ? height : `${height}rem`};
    `}
    object-fit : ${({ object_fit }) => object_fit || "unset"};
  ${marginPaddingMixin}
  ${borderMixin}
`;

const RatioImgCon = styled(Box)`
  position: relative;
  width: 100%;
  overflow: hidden;
`;

const RatioImg = styled(Img)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export { Img, RatioImgCon, RatioImg };
