import { useMutation, useQuery } from "@tanstack/react-query";
import { baseAxios } from "api/baseAxios";
import { AxiosError, AxiosResponse } from "axios";

interface SallerLoginParams {
  salesmanager_account: string;
  salesmanager_password: string;
  platform: "PC";
}

export type TokenDataType = {
  token: string;
};

export interface SallerLoginResponse {
  status: number; //응답
  message: string; //메세지
  data: TokenDataType;
}

interface SallerLoginProps {
  onSuccess: (data: SallerLoginResponse) => void;
  onError: (error: AxiosError) => void;
}

//영업사원 로그인
export const useSallerLogin = ({ onSuccess, onError }: SallerLoginProps) =>
  useMutation<SallerLoginResponse, AxiosError, SallerLoginParams>({
    mutationKey: ["login"],
    mutationFn: (params: SallerLoginParams) =>
      baseAxios
        .post("/auth/salesmanager/login", params)
        .then((res) => res.data),
    onSuccess,
    onError,
  });
