import { useSendEmail } from "api/api.email";
import { useFindSalesId } from "api/api.salesmanager.find";
import { SolidButton } from "components/molecules/button/SolidButton";
import { Divider } from "components/molecules/divider/Divider";
import { PrimaryInput } from "components/molecules/Input/PrimaryInput";
import { AlertModal } from "components/organisms/modal/AlertModal";
import React from "react";
import { useNavigate } from "react-router";
import { useSnackbarStore } from "store/snackbarStore";
import { colors } from "theme/colors";
import { Box, FlexBox } from "theme/globalStyle";
import { getEmailAuthTemplate } from "utils/emailtemplate";
import { generateRandomSixDigitNumber, idCheck } from "utils/func";
import ModalPortal from "utils/Portal";

export const FindPwTemplate = () => {
  const showSnackbar = useSnackbarStore((state) => state.showSnackbar);

  const navigate = useNavigate();

  const { mutate: findAccountMutate } = useFindSalesId();
  const [id, setId] = React.useState("");
  const [name, setName] = React.useState("");
  //이메일 인증번호 저장
  const [email, setEmail] = React.useState("");
  const [emailCode, setEmailCode] = React.useState("");
  const [emailCheck, setEamilCheck] = React.useState(false);
  const [emailAuthCode, setEmailAuthCode] = React.useState("");
  const [inputError, setInputError] = React.useState({
    name: false,
    id: false,
    email: false,
    email_code: false,
  });

  //이메일 전송
  const { mutate: sendEmailMutate, isPending: sendEmailPending } =
    useSendEmail();

  const [alertModal, setAlertModal] = React.useState({
    visible: false,
    msg: "",
    type: "",
  });

  //이메일 인증번호 발송
  function sendEamilAuthCode() {
    if (email === "" || !idCheck(email).result) {
      setInputError((prev) => ({ ...prev, email: false }));
    } else {
      const randomCode = generateRandomSixDigitNumber();
      sendEmailMutate(
        {
          to_email: email,
          subject: "[블루라이언스] 이메일 인증을 완료해주세요,",
          html: getEmailAuthTemplate(randomCode),
        },
        {
          onSuccess: (res) => {
            const { data, status } = res;

            if (data.data) {
              showSnackbar("인증번호 발송이 완료되었습니다.");
              setEmailAuthCode(randomCode);
            }
          },
        }
      );
    }
  }

  //이메일 인증번호 확인
  function checkEamilAuthCode() {
    if (emailCode === "" || emailCode !== emailAuthCode) {
      setInputError((prev) => ({ ...prev, email_code: true }));
    } else {
      setEamilCheck(true);
    }
  }

  function verificationInfo() {
    if (name === "") {
      setInputError((prev) => ({ ...prev, name: true }));
      return false;
    } else if (id === "") {
      setInputError((prev) => ({ ...prev, id: true }));
      return false;
    } else if (!emailCheck) {
      showSnackbar("이메일 인증을 진행해주세요.");
      return false;
    }
    return true;
  }

  function findPwAccess() {
    if (verificationInfo()) {
      const params = {
        salesmanager_name: name,
        email_address: email,
        salesmanager_account: id,
      };

      findAccountMutate(params, {
        onSuccess: (res) => {
          const { data, message, status } = res;

          if (status === 200) {
            navigate("/find/findPwSuccess", {
              state: { id: data.salesmanager_id, type: "pw" },
            });
          } else {
            alertModalOn("가입된 계정이 존재하지 않습니다.", "reload_page");
          }
        },
        onError: (error) => {
          alertModalOn("가입된 계정이 존재하지 않습니다.", "reload_page");
        },
      });
    }
  }

  function alertModalOn(msg: string, type?: string) {
    setAlertModal({
      visible: true,
      msg,
      type: type || "",
    });
  }
  function alertAction() {
    if (alertModal.type === "reload_page") {
      window.location.reload();
    }
  }

  return (
    <FlexBox $isFullWidth $mt={4}>
      <ModalPortal>
        <AlertModal
          visible={alertModal.visible}
          msg={alertModal.msg}
          type={alertModal.type}
          modalOff={() => {
            setAlertModal({ visible: false, msg: "", type: "" });
          }}
          action={alertAction}
        />
      </ModalPortal>
      <Box $isFullWidth>
        <PrimaryInput
          value={name}
          placeholder="이름을 입력해주세요."
          onChangeValue={(value: string) => {
            setName(value);
            setInputError((prev) => ({
              ...prev,
              name: false,
            }));
          }}
          label={"이름"}
          isRequire
          wrapperStyle={{ width: "100%" }}
          stateKey="email"
          $isError={inputError.name}
          errorMsg={"가입한 이름을 입력해주세요."}
        />
      </Box>
      <Box $mt={2} $isFullWidth>
        <PrimaryInput
          value={id}
          placeholder="아이디를 입력해주세요."
          onChangeValue={(value: string) => {
            setId(value);
            setInputError((prev) => ({
              ...prev,
              id: false,
            }));
          }}
          label={"아이디"}
          isRequire
          wrapperStyle={{ width: "100%" }}
          $isError={inputError.id}
          errorMsg={"아이디를 입력해주세요."}
        />
      </Box>

      <Box $mt={2} $isFullWidth>
        <PrimaryInput
          value={email}
          placeholder="예:bluelions@bluelions.kr"
          onChangeValue={(value: string) => {
            setEmail(value);
            setInputError((prev) => ({
              ...prev,
              email: !idCheck(value).result,
            }));
          }}
          label={"이메일"}
          isRequire
          wrapperStyle={{ width: "100%" }}
          rightButton={
            <Box $isFullWidth height={"100%"}>
              {!emailCheck && (
                <SolidButton
                  buttonLabel="인증번호 발송"
                  style={{ width: "100%", height: "100%" }}
                  onClick={sendEamilAuthCode}
                  isLoading={sendEmailPending}
                />
              )}
            </Box>
          }
          stateKey="email"
          $isError={inputError.email}
          errorMsg={
            email === ""
              ? "이메일을 입력해주세요."
              : "올바르지 않은 이메일 형식입니다."
          }
          readonly={emailCheck}
        />
      </Box>

      {emailAuthCode !== "" && !emailCheck && (
        <Box $mt={1.2} $isFullWidth>
          <PrimaryInput
            value={emailCode}
            maxInput={6}
            placeholder="인증번호 6자리를 입력해주세요"
            onChangeValue={setEmailCode}
            wrapperStyle={{ width: "100%" }}
            rightButton={
              <SolidButton
                buttonLabel="확인"
                style={{ width: "100%", height: "100%" }}
                onClick={checkEamilAuthCode}
              />
            }
            stateKey={"email_code"}
            $isError={inputError.email_code}
            errorMsg={
              emailCode === ""
                ? "인증번호를 입력해주세요."
                : "올바르지 않은 인증번호입니다."
            }
          />
        </Box>
      )}
      <Box $isFullWidth $mt={4}>
        <Divider
          variant="thick"
          width={"100%"}
          height={0.2}
          $bgcolor={colors.MAIN}
        />
      </Box>
      <Box $mt={2} $isFullWidth>
        <SolidButton
          size={"large"}
          buttonLabel="비밀번호 찾기"
          style={{
            width: "100%",
            paddingTop: "2rem",
            paddingBottom: "2rem",
          }}
          onClick={() => {
            findPwAccess();
            // navigate("/join/payment");
            // validateInput();
          }}
          disable={!emailCheck || id === "" || name === ""}
        />
      </Box>
      {/* )} */}
    </FlexBox>
  );
};
