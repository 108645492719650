import { useGetSallerList } from "api/api.salesmanager";
import { DatatablePagination } from "components/organisms/pagination/DatatablePagination";
import { NoneDataComponent } from "components/organisms/table/NoneDataComponent";
import React from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { useLocationStore } from "store/useLocationStore";
import { Box, FlexBox } from "theme/globalStyle";
import { tableCustomStyles } from "theme/table.custom";
import { convertUnixDate, NumberComma } from "utils/func";

interface SalesUseHistoryType {
  local_id: number;
  salesmanager_name: string;
  product_type: string;
  sub_product_name: string;
  company_name: string;
  email_address: string;
  final_price: number;
  final_discount: number;
  use_date: number;
}

interface CouponHistoryListProps {
  selCouponIdx: number;
}

export const CouponHistoryList: React.FC<CouponHistoryListProps> = ({
  selCouponIdx,
}) => {
  const [salesUseHistory, setSalesUseHistory] = React.useState<
    SalesUseHistoryType[]
  >([]);

  const { data: sallerData } = useGetSallerList();
  const { locationList } = useLocationStore((state) => state);

  const columns: TableColumn<SalesUseHistoryType>[] = [
    {
      name: "NO",
      sortable: false,
      cell: (_: any, index: number) => index + 1,
      width: "6rem",
      grow: 1,
    },
    {
      name: "사용일",
      selector: (row) => convertUnixDate(row.use_date),
      sortable: false,
    },
    {
      name: "상품명",
      selector: (row) => {
        if (locationList.length > 0) {
          const filterData = locationList.filter(
            (el) => el.regionId === row.local_id
          );
          if (filterData.length > 0) {
            return `${filterData[0].regionName}-${row.sub_product_name}`;
          } else {
            return "";
          }
        } else {
          return "";
        }
      },
      sortable: true,
      grow: 2,
    },
    {
      name: "성명",
      selector: (row) => row.salesmanager_name,
      sortable: true,
    },
    {
      name: "직장",
      selector: (row) => row.company_name,
      sortable: true,
    },
    {
      name: "이메일",
      selector: (row) => row.email_address,
      sortable: false,
      grow: 2,
    },
    {
      name: "할인금액",
      selector: (row) => NumberComma(row.final_discount) + "원",
      sortable: true,
    },
    {
      name: "결제금액",
      selector: (row) => NumberComma(row.final_price) + "원",
      sortable: true,
    },
  ];

  React.useEffect(() => {
    if (sallerData) {
      if (sallerData.status === 200) {
        let useList: SalesUseHistoryType[] = [];
        sallerData.data.forEach((item) => {
          item.payment.forEach((paymentItem) => {
            const productInfo = paymentItem.sub_product_information;

            if (paymentItem.coupon_information.coupon_id === selCouponIdx) {
              useList.push({
                local_id: productInfo.local_id,
                company_name: item.company_name,
                email_address: item.email_address,
                final_price: paymentItem.final_price,
                final_discount: paymentItem.final_discount,
                sub_product_name: productInfo.sub_product_name,
                product_type: productInfo.product_type,
                salesmanager_name: item.salesmanager_name,
                use_date: paymentItem.created_at || 0,
              });
            }
          });
        });

        setSalesUseHistory([...useList]);
      }
    }
  }, [sallerData, selCouponIdx]);
  return (
    <FlexBox $pa={2} $isFullWidth>
      <Box $isFullWidth>
        <DataTable
          columns={columns}
          data={[...salesUseHistory]}
          // selectableRows
          defaultSortFieldId={1}
          pagination
          paginationComponent={DatatablePagination}
          customStyles={tableCustomStyles}
          noDataComponent={
            <NoneDataComponent comment="쿠폰 사용이력이 존재하지 않습니다." />
          }
        />
      </Box>
    </FlexBox>
  );
};
