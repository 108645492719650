import { AlertModal } from "components/organisms/modal/AlertModal";
import AdminHomeTemplate from "components/templates/admin/AdminHomeTemplate";
import MyCustomerTemplate from "components/templates/saller/customerList/MyCustomerTemplate";
import NewCustomerTemplate from "components/templates/saller/customerList/NewCustomerTemplate";
import ProposalTemplate from "components/templates/saller/ProposalTemplate";
import React from "react";
import { useNavigate, useOutletContext } from "react-router";
import { useUserInfoStore } from "store/userInfoStore";
import ModalPortal from "utils/Portal";

interface SallerProposalType {
  selSubPage: string;
}

export const SallerProposal = () => {
  const { selSubPage } = useOutletContext<SallerProposalType>();
  const { payment } = useUserInfoStore((state) => state);
  const navigate = useNavigate();

  const [alertModal, setAlertModal] = React.useState(true);

  if (payment.length === 0) {
    return (
      <ModalPortal>
        <AlertModal
          visible={alertModal}
          modalOff={() => {
            setAlertModal(false);
          }}
          msg={"결제된 구독상품이 존재하지 않습니다."}
          action={() => {
            navigate("/payment", { replace: true });
          }}
        />
      </ModalPortal>
    );
  }

  if (selSubPage === "wait") {
    return <ProposalTemplate type={"WAIT"} />;
  } else if (selSubPage === "accept") {
    return <ProposalTemplate type={"ACCEPT"} />;
  } else if (selSubPage === "reject") {
    return <ProposalTemplate type={"REJECT"} />;
  } else {
    return <></>;
  }
};
