import AdminHomeTemplate from "components/templates/admin/AdminHomeTemplate";
import ChangePasswordTemplate from "components/templates/saller/MyInfoTemplate/ChangePasswordTemplate";
import CouponTemplate from "components/templates/saller/MyInfoTemplate/CouponTemplate";
import InfoTemplate from "components/templates/saller/MyInfoTemplate/InfoTemplate";
import PaymentListTemplate from "components/templates/saller/MyInfoTemplate/PaymentListTemplate";
import QnaTemplate from "components/templates/saller/MyInfoTemplate/QnaTemplate";
import SubscribeTemplate from "components/templates/saller/MyInfoTemplate/SubscribeTemplate";
import React from "react";
import { useOutletContext } from "react-router";

interface SallerMyInfoType {
  selSubPage: string;
}

export const SallerMyInfo = () => {
  const { selSubPage } = useOutletContext<SallerMyInfoType>();

  if (selSubPage === "info") {
    return <InfoTemplate />;
  } else if (selSubPage === "subscribe") {
    return <SubscribeTemplate />;
  } else if (selSubPage === "paymentList") {
    return <PaymentListTemplate />;
  } else if (selSubPage === "changePassword") {
    return <ChangePasswordTemplate />;
  } else if (selSubPage === "coupon") {
    return <CouponTemplate />;
  } else if (selSubPage === "qna") {
    return <QnaTemplate />;
  } else {
    return <></>;
  }
};
