import { Divider } from "components/molecules/divider/Divider";
import Typograpy from "components/molecules/Typograpy";
import { AlertModal } from "components/organisms/modal/AlertModal";
import React from "react";
import { useNavigate } from "react-router";
import { useAdminInfoStore } from "store/useAdminInfoStore";
import { useTokenStore } from "store/useTokenStore";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { Box, FlexBox } from "theme/globalStyle";
import ModalPortal from "utils/Portal";

interface AdminHeaderProps {
  title: string;
}

export const Header: React.FC<AdminHeaderProps> = ({ title }) => {
  const navigate = useNavigate();

  const { setToken } = useTokenStore((state) => state);
  const { deleteAdminInfo } = useAdminInfoStore((state) => state);

  const [alertModal, setAlertModal] = React.useState({
    visible: false,
    msg: "",
    type: "",
  });

  const alertModalOn = (msg: string, type?: string) => {
    setAlertModal({
      visible: true,
      msg,
      type: type || "",
    });
  };

  const alertAction = () => {
    if (alertModal.type === "logout_confirm") {
      deleteAdminInfo();
      setToken("");
      navigate("/");
    }
  };

  function logoutHandler() {
    alertModalOn("정말 로그아웃 하시겠습니까?", "logout_confirm");
  }

  return (
    <FlexBox
      height={6.8}
      $px={2}
      $flexDirection="row"
      $jc={"space-between"}
      $ai={"center"}
      $bbc={colors.DEFAULT_BORDER_COLOR}
      $bbw={1}
      $isFullWidth
    >
      <ModalPortal>
        <AlertModal
          visible={alertModal.visible}
          msg={alertModal.msg}
          type={alertModal.type}
          action={alertAction}
          modalOff={() => {
            setAlertModal({ visible: false, msg: "", type: "" });
          }}
        />
      </ModalPortal>
      <Typograpy fontStyle={fontStyle.heading1} fontWeight="Bold">
        {title}
      </Typograpy>

      <FlexBox $flexDirection="row" $gap={1.6} $ai={"center"}>
        <Box
          $isPointer
          onClick={() => {
            navigate("/");
          }}
        >
          <Typograpy fontStyle={fontStyle.label1_normal}>메인페이지</Typograpy>
        </Box>
        <Divider variant="normal" isVertical height={1.5} />
        <Box $isPointer onClick={logoutHandler}>
          <Typograpy fontStyle={fontStyle.label1_normal}>로그아웃</Typograpy>
        </Box>
      </FlexBox>
    </FlexBox>
  );
};
