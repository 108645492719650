import { GetPaymentListResponseData } from "api/api.payment";
import { useGetSallerInfo } from "api/api.salesmanager";
import Typograpy from "components/molecules/Typograpy";
import { DatatablePagination } from "components/organisms/pagination/DatatablePagination";
import { NoneDataComponent } from "components/organisms/table/NoneDataComponent";
import React from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { useUserInfoStore } from "store/userInfoStore";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { Box, FlexBox } from "theme/globalStyle";
import { tableCustomStyles } from "theme/table.custom";
import { convertUnixDate, NumberComma } from "utils/func";

const PaymentListTemplate = () => {
  const { salesmanager_id } = useUserInfoStore((state) => state);

  const { mutate: getSallerInfo, isPending: getSallerInfoPending } =
    useGetSallerInfo();

  const [paymentList, setPaymentList] = React.useState<
    GetPaymentListResponseData[]
  >([]);

  const columns: TableColumn<GetPaymentListResponseData>[] = [
    {
      name: "결제번호",
      sortable: false,
      cell: (row, index: number) => row.payment_id,
      width: "12rem",
      center: true,
    },
    {
      name: "결제일",
      selector: (row) => convertUnixDate(row.created_at || 0),
      sortable: false,
    },
    // {
    //   name: "결제여부",
    //   selector: (row) => row.payment_information?.status || "",
    //   sortable: true,
    // },
    {
      name: "상품명",
      selector: (row) => row.sub_product_information.sub_product_name,
      sortable: true,
    },
    {
      name: "결제금액",
      selector: (row) => NumberComma(row.final_price) + "원",
      sortable: true,
    },
    {
      name: "결제방법",
      selector: (row) => row.payment_method,
      sortable: false,
    },
    {
      name: "결제ID",
      selector: (row) => row.payment_information?.orderId || "",
      sortable: false,
      grow: 2,
    },
  ];

  React.useEffect(() => {
    // setPaymentList([...payment]);
    getSallerInfo(
      { salesmanager_id },
      {
        onSuccess: (res) => {
          if (res.status === 200) {
            setPaymentList([...res.data.payment.reverse()]);
          }
        },
      }
    );
  }, []);

  return (
    <FlexBox $isFullWidth $ai="center">
      <FlexBox
        $isFullWidth
        $flexDirection="row"
        $jc="space-between"
        $ai="center"
        height={6}
        $bbw={1}
        $bbc={colors.DEFAULT_BORDER_COLOR}
        $pl={2}
        $pr={4}
      >
        <Typograpy
          fontStyle={fontStyle.body1_normal}
          fontWeight="SemiBold"
          color={colors.SALLER_SUBSIDE_TITLE}
        >
          결제내역 관리
        </Typograpy>
      </FlexBox>

      <Box $isFullWidth $pa={2}>
        <DataTable
          columns={columns}
          data={[...paymentList.reverse()]}
          defaultSortFieldId={1}
          pagination
          paginationComponent={DatatablePagination}
          customStyles={tableCustomStyles}
          noDataComponent={
            <NoneDataComponent comment="결제내역이 존재하지 않습니다." />
          }
        />
      </Box>
    </FlexBox>
  );
};

export default PaymentListTemplate;
