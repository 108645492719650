import Typograpy from "components/molecules/Typograpy";
import React, { ReactChild } from "react";
import { colors } from "theme/colors";
import { Box, FlexBox } from "theme/globalStyle";

export interface MenuListType {
  label: string;
  action: () => void;
}

interface MenuPropsType {
  menuList: MenuListType[];

  //left 및 right는 둘중 하나만
  //박스 위치
  isLeft?: boolean;
  isRight?: boolean;

  children?: ReactChild; //Menu-on/off button
}

//서브메뉴
// 부모 컴포넌트 relative 적용 필요
export const Menu: React.FC<MenuPropsType> = React.memo(
  ({ menuList, isLeft, isRight, children }) => {
    const listRef = React.useRef<HTMLDivElement>(null);

    const [menuToggle, setMenuToggle] = React.useState(false);

    function menuToggleHandler() {
      setMenuToggle(!menuToggle);
    }

    React.useEffect(() => {
      const handleClick = (e: MouseEvent) => {
        if (listRef.current && !listRef.current.contains(e.target as Node)) {
          setMenuToggle(false);
        }
      };

      window.addEventListener("mousedown", handleClick);

      return () => window.removeEventListener("mousedown", handleClick);
    }, [listRef]);

    return (
      <Box ref={listRef}>
        <Box $isPointer onClick={menuToggleHandler}>
          {children}
        </Box>
        {menuToggle && (
          <FlexBox
            $position={"absolute"}
            $left={isLeft ? 0 : undefined}
            $right={isRight ? 0 : undefined}
            $mt={0.8}
            $br={1}
            $shadowType="emphasize"
            $bw={1}
            $bc={colors.SUBMENU_BORDER}
            style={{ zIndex: 999 }}
          >
            {menuList.map((item, index) => {
              return (
                <Box
                  key={"menu" + index}
                  width={"100%"}
                  $bbw={menuList.length - 1 === index ? 0 : 1}
                  $bbc={colors.SUBMENU_BORDER}
                  $py={0.8}
                  $px={1.2}
                  $isPointer
                  onClick={() => {
                    item.action();
                    setMenuToggle(false);
                  }}
                  $bgcolor={colors.WHITE}
                >
                  <Typograpy style={{ whiteSpace: "nowrap" }} fontSize={16}>
                    {item.label}
                  </Typograpy>
                </Box>
              );
            })}
          </FlexBox>
        )}
      </Box>
    );
  }
);
