import React from "react";
import { FlexBox } from "theme/globalStyle";
import Typograpy from "../Typograpy";
import { fontStyle } from "theme/fontStyle";
import { colors } from "theme/colors";

interface TableTitleProps {
  title: string;
  width?: number;
  isRequire?: boolean;
}

export const TableTitle: React.FC<TableTitleProps> = ({
  title,
  width,
  isRequire,
}) => {
  return (
    <FlexBox
      $jc="center"
      $ai="center"
      $flexDirection={"row"}
      width={width || 10}
      $minHeight={5.8}
      $py={1.2}
      $bgcolor={colors.SALLER_DETAIL_TITLE_BG}
      $gap={0.2}
    >
      <Typograpy fontStyle={fontStyle.label2} color={colors.BLACK}>
        {title}
      </Typograpy>
      {isRequire && (
        <Typograpy color={colors.RED} fontStyle={fontStyle.label2}>
          *
        </Typograpy>
      )}
    </FlexBox>
  );
};
