import styled from "styled-components";
import { ReactComponent as TextDeleteIcon } from "assets/img/ic_textdelete.svg";
import { ReactComponent as CloseIcon } from "assets/img/ic_close.svg";
import { ReactComponent as SearchIcon } from "assets/img/ic_search.svg";
import { ReactComponent as ErrorIcon } from "assets/img/ic_error.svg";
import { ReactComponent as GraySelArrowIcon } from "assets/img/ic_select_arrow_gray.svg";
import { ReactComponent as SelArrowIcon } from "assets/img/ic_select_arrow.svg";
import { ReactComponent as LeftArrowIcon } from "assets/img/ic_arrow_left.svg";
import { ReactComponent as RightArrowIcon } from "assets/img/ic_arrow_right.svg";
import { ReactComponent as WhiteArrowIcon } from "assets/img/ic_white_arrow.svg";
import { ReactComponent as ListIcon } from "assets/img/ic_list.svg";
import { ReactComponent as CartIcon } from "assets/img/ic_cart.svg";
import { ReactComponent as HomeOffIcon } from "assets/img/ic_home_off.svg";
import { ReactComponent as HomeOnIcon } from "assets/img/ic_home_on.svg";
import { ReactComponent as DownloadIcon } from "assets/img/ic_download.svg";
import { ReactComponent as PlusIcon } from "assets/img/ic_plus.svg";
import { ReactComponent as SolutionIcon } from "assets/img/ic_solution.svg";
import { ReactComponent as StarIcon } from "assets/img/ic_star.svg";
import { ReactComponent as NaverIcon } from "assets/img/ic_naver.svg";
import { ReactComponent as KakaoIcon } from "assets/img/ic_kakao.svg";
import { ReactComponent as EyeOffIcon } from "assets/img/ic_eyeoff.svg";
import { ReactComponent as EyeOnIcon } from "assets/img/ic_eyeon.svg";
import { ReactComponent as MoreIcon } from "assets/img/ic_more.svg";
import { ReactComponent as UploadIcon } from "assets/img/ic_upload.svg";
import { ReactComponent as TeamOnIcon } from "assets/img/ic_team_on.svg";
import { ReactComponent as TeamOffIcon } from "assets/img/ic_team_off.svg";
import { ReactComponent as DocumentOnIcon } from "assets/img/ic_document_on.svg";
import { ReactComponent as DocumentOffIcon } from "assets/img/ic_document_off.svg";
import { ReactComponent as PeopleOnIcon } from "assets/img/ic_people_on.svg";
import { ReactComponent as PeopleOffIcon } from "assets/img/ic_people_off.svg";
import { ReactComponent as CrossIc } from "assets/img/ic_cross.svg";
import { ReactComponent as TrashIc } from "assets/img/ic_trash.svg";
import { ReactComponent as TrashBlackIc } from "assets/img/ic_trash_black.svg";
import { ReactComponent as EnvIc } from "assets/img/ic_env.svg";

import { ReactComponent as RankImg } from "assets/img/rank_img.svg";

interface IconPropsType {
  width?: number;
  height?: number;
}

//border 있는 x 아이콘
const BorderDeleteIcon = styled(TextDeleteIcon)<IconPropsType>`
  width: ${({ width }) => (width ? width + "rem" : "2.4rem")};
  height: ${({ height }) => (height ? height + "rem" : "2.4rem")};
`;
//border 없는 x 아이콘
const IcClose = styled(CloseIcon)<IconPropsType>`
  width: ${({ width }) => (width ? width + "rem" : "1.5rem")};
  height: ${({ height }) => (height ? height + "rem" : "1.5rem")};
`;

// search 아이콘
const IcSearch = styled(SearchIcon)<IconPropsType>`
  width: ${({ width }) => (width ? width + "rem" : "1.6rem")};
  height: ${({ height }) => (height ? height + "rem" : "1.6rem")};
`;

// 에러 아이콘
const IcError = styled(ErrorIcon)<IconPropsType>`
  width: ${({ width }) => (width ? width + "rem" : "1.9rem")};
  height: ${({ height }) => (height ? height + "rem" : "1.9rem")};
`;

// 셀렉트 박스 arrow 선택 되지 않은 상태
const IcGraySelArrow = styled(GraySelArrowIcon)<IconPropsType>`
  width: ${({ width }) => (width ? width + "rem" : "1.2rem")};
  height: ${({ height }) => (height ? height + "rem" : "0.65rem")};
`;

// 셀렉트박스 arrow 선택된상태
const IcSelArrow = styled(SelArrowIcon)<IconPropsType>`
  width: ${({ width }) => (width ? width + "rem" : "1.2rem")};
  height: ${({ height }) => (height ? height + "rem" : "0.65rem")};
`;

const IcLeftArrow = styled(LeftArrowIcon)<IconPropsType>`
  width: ${({ width }) => (width ? width + "rem" : "1.6rem")};
  height: ${({ height }) => (height ? height + "rem" : "1.6rem")};
`;
const IcRightArrow = styled(RightArrowIcon)<IconPropsType>`
  width: ${({ width }) => (width ? width + "rem" : "1.6rem")};
  height: ${({ height }) => (height ? height + "rem" : "1.6rem")};
`;

const IcWhiteArrow = styled(WhiteArrowIcon)<IconPropsType>`
  width: ${({ width }) => (width ? width + "rem" : "1.6rem")};
  height: ${({ height }) => (height ? height + "rem" : "1.6rem")};
`;

//리스트 아이콘
const IcList = styled(ListIcon)<IconPropsType>`
  width: ${({ width }) => (width ? width + "rem" : "2rem")};
  height: ${({ height }) => (height ? height + "rem" : "2rem")};
`;

//장바구니 아이콘
const IcCart = styled(CartIcon)<IconPropsType>`
  width: ${({ width }) => (width ? width + "rem" : "2rem")};
  height: ${({ height }) => (height ? height + "rem" : "2rem")};
`;

//다운로드 아이콘
const IcDownload = styled(DownloadIcon)<IconPropsType>`
  width: ${({ width }) => (width ? width + "rem" : "1.8rem")};
  height: ${({ height }) => (height ? height + "rem" : "1.8rem")};
`;

//더하기 (+) 아이콘
const IcPlus = styled(PlusIcon)<IconPropsType>`
  width: ${({ width }) => (width ? width + "rem" : "2rem")};
  height: ${({ height }) => (height ? height + "rem" : "2rem")};
`;

//인트로 약간 dna같은 아이콘...?
const IcSolution = styled(SolutionIcon)<IconPropsType>`
  width: ${({ width }) => (width ? width + "rem" : "7.5rem")};
  height: ${({ height }) => (height ? height + "rem" : "8rem")};
`;

//rank full 이미지
const ImgRank = styled(RankImg)<IconPropsType>`
  width: ${({ width }) => (width ? width + "rem" : "22.6rem")};
  height: ${({ height }) => (height ? height + "rem" : "3.2rem")};
`;

//rank 아이콘
const IcStar = styled(StarIcon)<IconPropsType>`
  width: ${({ width }) => (width ? width + "rem" : "2.4rem")};
  height: ${({ height }) => (height ? height + "rem" : "2.4rem")};
`;

//네이버 아이콘
const IcNaver = styled(NaverIcon)<IconPropsType>`
  width: ${({ width }) => (width ? width + "rem" : "1.6rem")};
  height: ${({ height }) => (height ? height + "rem" : "1.6rem")};
`;

//카카오 아이콘
const IcKakao = styled(KakaoIcon)<IconPropsType>`
  width: ${({ width }) => (width ? width + "rem" : "1.8rem")};
  height: ${({ height }) => (height ? height + "rem" : "1.68rem")};
`;

//비밀번호 눈표시 off
const IcEyeOff = styled(EyeOffIcon)<IconPropsType>`
  width: ${({ width }) => (width ? width + "rem" : "2rem")};
  height: ${({ height }) => (height ? height + "rem" : "2rem")};
`;
//비밀번호 눈표시 on
const IcEyeOn = styled(EyeOnIcon)<IconPropsType>`
  width: ${({ width }) => (width ? width + "rem" : "2rem")};
  height: ${({ height }) => (height ? height + "rem" : "2rem")};
`;

//점세개 찍혀있는 더보기 아이콘
const IcMore = styled(MoreIcon)<IconPropsType>`
  width: ${({ width }) => (width ? width + "rem" : "1.55rem")};
  height: ${({ height }) => (height ? height + "rem" : "0.35rem")};
`;

//홈 아이콘 - off
const IcHomeOff = styled(HomeOffIcon)<IconPropsType>`
  width: ${({ width }) => (width ? width + "rem" : "1.88rem")};
  height: ${({ height }) => (height ? height + "rem" : "1.93rem")};
`;

//홈 아이콘 - on
const IcHomeOn = styled(HomeOnIcon)<IconPropsType>`
  width: ${({ width }) => (width ? width + "rem" : "1.88rem")};
  height: ${({ height }) => (height ? height + "rem" : "1.93rem")};
`;

//팀 아이콘 on
const IcTeamOn = styled(TeamOnIcon)<IconPropsType>`
  width: ${({ width }) => (width ? width + "rem" : "2rem")};
  height: ${({ height }) => (height ? height + "rem" : "1.8rem")};
`;
//팀 아이콘 off
const IcTeamOff = styled(TeamOffIcon)<IconPropsType>`
  width: ${({ width }) => (width ? width + "rem" : "2rem")};
  height: ${({ height }) => (height ? height + "rem" : "1.8rem")};
`;
//문서 아이콘 on
const IcDocumentOn = styled(DocumentOnIcon)<IconPropsType>`
  width: ${({ width }) => (width ? width + "rem" : "1.6rem")};
  height: ${({ height }) => (height ? height + "rem" : "2rem")};
`;
//문서 아이콘 off
const IcDocumentOff = styled(DocumentOffIcon)<IconPropsType>`
  width: ${({ width }) => (width ? width + "rem" : "1.6rem")};
  height: ${({ height }) => (height ? height + "rem" : "2rem")};
`;
//프로필 아이콘 on
const IcPeopleOn = styled(PeopleOnIcon)<IconPropsType>`
  width: ${({ width }) => (width ? width + "rem" : "1.8rem")};
  height: ${({ height }) => (height ? height + "rem" : "1.8rem")};
`;
//프로필 아이콘 off
const IcPeopleOff = styled(PeopleOffIcon)<IconPropsType>`
  width: ${({ width }) => (width ? width + "rem" : "1.8rem")};
  height: ${({ height }) => (height ? height + "rem" : "1.8rem")};
`;

//업로드 아이콘
const IcUpload = styled(UploadIcon)<IconPropsType>`
  width: ${({ width }) => (width ? width + "rem" : "1.6rem")};
  height: ${({ height }) => (height ? height + "rem" : "1.6rem")};
`;

//업로드 아이콘
const IcCross = styled(CrossIc)<IconPropsType>`
  width: ${({ width }) => (width ? width + "rem" : "1rem")};
  height: ${({ height }) => (height ? height + "rem" : "1rem")};
`;

//쓰레기통 아이콘
const IcTrash = styled(TrashIc)<IconPropsType>`
  width: ${({ width }) => (width ? width + "rem" : "1rem")};
  height: ${({ height }) => (height ? height + "rem" : "1rem")};
`;

//쓰레기통 검은색 아이콘
const IcTrashBlack = styled(TrashBlackIc)<IconPropsType>`
  width: ${({ width }) => (width ? width + "rem" : "1rem")};
  height: ${({ height }) => (height ? height + "rem" : "1rem")};
`;
//환경설정 아이콘
const IcEnv = styled(EnvIc)<IconPropsType>`
  width: ${({ width }) => (width ? width + "rem" : "1rem")};
  height: ${({ height }) => (height ? height + "rem" : "1rem")};
`;

export {
  BorderDeleteIcon,
  IcClose,
  IcSearch,
  IcError,
  IcGraySelArrow,
  IcSelArrow,
  IcLeftArrow,
  IcRightArrow,
  IcWhiteArrow,
  IcList,
  IcCart,
  IcHomeOff,
  IcHomeOn,
  IcDownload,
  IcPlus,
  IcSolution,
  ImgRank,
  IcStar,
  IcNaver,
  IcKakao,
  IcEyeOff,
  IcEyeOn,
  IcMore,
  IcTeamOn,
  IcTeamOff,
  IcDocumentOn,
  IcDocumentOff,
  IcPeopleOn,
  IcPeopleOff,
  IcUpload,
  IcCross,
  IcTrash,
  IcTrashBlack,
  IcEnv,
};
