import Typograpy from "components/molecules/Typograpy";
import React from "react";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";

interface CardTitleType {
  title: string;
}

interface CardContentsType {
  contents: string;
}

export const CardTitle: React.FC<CardTitleType> = ({ title }) => {
  return (
    <Typograpy
      color={colors.INSURANCE_TITLE}
      fontStyle={fontStyle.caption1}
      fontWeight="SemiBold"
    >
      {title}
    </Typograpy>
  );
};

export const CardContents: React.FC<CardContentsType> = ({ contents }) => {
  return (
    <Typograpy
      color={colors.INSURANCE_CONTENT}
      fontStyle={fontStyle.label1_normal}
    >
      {contents}
    </Typograpy>
  );
};
