import React from "react";
import Typograpy from "components/molecules/Typograpy";
import ModalPortal from "utils/Portal";
import DataTable, { TableColumn } from "react-data-table-component";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { Box, FlexBox } from "theme/globalStyle";
import {
  convertUnixDate,
  maskAllButFirst,
  operatorTypeValueReturn,
  subtypeValueReturn,
} from "utils/func";
import { ConsumerDetailModal } from "components/organisms/modal/ConsumerDetailModal";
import { CustomerListFilter } from "components/organisms/filter/CustomerListFilter";
import { tableCustomStyles } from "theme/table.custom";

import {
  GetUsersParams,
  GetUsersResponse,
  GetUsersResponseData,
  getUserList,
} from "api/blueButton/GetUsers";
import { Pagination } from "components/organisms/pagination/Pagination";
import { NoneDataComponent } from "components/organisms/table/NoneDataComponent";
import { useUserInfoStore } from "store/userInfoStore";
import { useSnackbarStore } from "store/snackbarStore";
import LoadingComponent from "components/organisms/loadingComponent";
import useScrollLock from "hooks/useScrollLock";
import { useLocationStore } from "store/useLocationStore";
import { GetLocationResponseData } from "api/blueButton/getLocationList";

const MyCustomerTemplate = () => {
  const { payment } = useUserInfoStore((state) => state);
  const showSnackBar = useSnackbarStore((state) => state.showSnackbar);
  const { locationList } = useLocationStore((state) => state);
  const [isDetailModal, setIsDetailModal] = React.useState({
    visible: false,
    selectId: 0,
  });

  //고객 리스트 반환 정보
  const [userData, setUserData] = React.useState<GetUsersResponse>();

  //필터 정보
  const [filterInfo, setFilterInfo] = React.useState<GetUsersParams>({
    regionId: "",
    type1: "전체",
    type2: "",
    searchKeyword: "",
    totalIncomeOperatorType: "",
    totalIncome: "",
    ageOperatorType: "",
    age: "",
    page: 0,
    size: 10,
  });

  //보험 & 금융 탭 설정
  const [productType, setProductType] = React.useState<
    "insurances" | "finance"
  >("insurances");

  //구독타입
  const [subscribeType, setSubscribeType] = React.useState("insurnaces");

  //리스트 로딩
  const [isListLoading, setIsListLoading] = React.useState(false);

  //필터셋팅
  const filterSetting = (value: string, key?: string) => {
    if (key) {
      if (key === "regionId") {
        setFilterInfo({
          ...filterInfo,
          [key]: value,
          page: 0,
        });
      } else {
        setFilterInfo({
          ...filterInfo,
          [key]: value,
        });
      }
    }
  };

  //페이지 로드시 지역 선택
  function pageMountLocationSetting() {
    let haveLocList: GetLocationResponseData[] = [];
    locationList.forEach((locItem) => {
      payment.forEach((paymentItem) => {
        if (locItem.regionId === paymentItem.sub_product_information.local_id) {
          haveLocList.push(locItem);
        }
      });
    });

    setFilterInfo((prev) => ({
      ...prev,
      regionId: haveLocList[0].regionName,
    }));
  }

  //지역 id가져오기
  const getRegionId = React.useCallback(() => {
    if (filterInfo.regionId) {
      return locationList.filter(
        (el) => el.regionName === filterInfo.regionId
      )[0].regionId;
    }
  }, [filterInfo.regionId]);

  //구독한 상품에따라 선택추가
  function productTypeSetting() {
    const selPaymentLocation = payment.filter(
      (el) => el.sub_product_information.local_id === getRegionId()
    );

    if (selPaymentLocation.length === 0) {
      showSnackBar("결제되지 않은 지역입니다. 결제 후 진행해주세요.");
      pageMountLocationSetting();
    } else if (selPaymentLocation.length === 1) {
      setSubscribeType(
        selPaymentLocation[0].sub_product_information.product_type
      );
    } else {
      let isHaveInsurances = false;
      let isHaveFinances = false;
      let isHavePremium = false;

      selPaymentLocation.forEach((item) => {
        if (item.sub_product_information.product_type === "insurances") {
          isHaveInsurances = true;
        } else if (item.sub_product_information.product_type === "finance") {
          isHaveFinances = true;
        } else if (item.sub_product_information.product_type === "premium") {
          isHavePremium = true;
        }
      });

      if ((isHaveInsurances && isHaveFinances) || isHavePremium) {
        setSubscribeType("premium");
      } else if (!isHaveInsurances && isHaveFinances) {
        setSubscribeType("finance");
      } else if (isHaveInsurances && !isHaveFinances) {
        setSubscribeType("insurnaces");
      }
    }
  }

  //유저정보 가져오기
  async function getUserHandler() {
    if (filterInfo.regionId === "") return;

    const filterParams = {
      ...filterInfo,
      regionId: getRegionId(),
      type1: subtypeValueReturn(filterInfo.type1 || "") || "INSURANCE_FINANCE",
      type2: "BUSINESS_NAME",
      age: Number(filterInfo.age),
      ageOperatorType:
        filterInfo.age === ""
          ? "NONE"
          : operatorTypeValueReturn(filterInfo.ageOperatorType || ""),
    };
    setIsListLoading(true);
    const resUserData = await getUserList(filterParams);
    setIsListLoading(false);

    setUserData(resUserData);
    productTypeSetting();
  }

  //검색
  const searchAction = () => {
    if (filterInfo.page === 0) {
      getUserHandler();
    } else {
      setFilterInfo({
        ...filterInfo,
        page: 0,
      });
    }
  };

  //데이터테이블 셋팅
  const columns: TableColumn<GetUsersResponseData>[] = [
    { name: "NO", cell: (_, index) => index + 1, width: "6rem" },
    {
      name: "구분",
      selector: (row) =>
        row.isFinancialInfo && row.isInsuranceInfo
          ? "보험/금융"
          : row.isFinancialInfo
          ? "금융"
          : row.isInsuranceInfo
          ? "보험"
          : "",
    },
    { name: "성명", selector: (row) => maskAllButFirst(row.name) },
    { name: "연령", selector: (row) => `만 ${row.age}세`, sortable: true },
    { name: "직장", selector: (row) => row.businessName, grow: 4 },
    {
      name: "가입일",
      selector: (row) => convertUnixDate(row.createdDate / 1000),
      grow: 2,
    },
    {
      name: "종합소득",
      selector: (row) => row.totalIncome + "원",
      grow: 2,
    },
    {
      name: "",
      cell: (row) => (
        <FlexBox
          $isPointer
          onClick={() => setIsDetailModal({ visible: true, selectId: row.id })}
          $br={4}
          $bgcolor={colors.MAIN}
          // $isFullWidth
          $px={2.4}
          $py={0.8}
          $ai={"center"}
        >
          <Typograpy color={colors.WHITE}>상세보기</Typograpy>
          {/* <IcMore /> */}
        </FlexBox>
      ),
      // grow: 1.5,
    },
  ];

  React.useEffect(() => {
    if (locationList.length > 0 && payment.length > 0) {
      pageMountLocationSetting();
    }
  }, [locationList, payment]);

  React.useEffect(() => {
    getUserHandler();
  }, [filterInfo.regionId, filterInfo.page]);

  useScrollLock(isDetailModal.visible);
  return (
    <FlexBox $isFullWidth>
      {isDetailModal.visible && (
        <ModalPortal>
          <ConsumerDetailModal
            userData={userData}
            setIsDetailModal={setIsDetailModal}
            selectId={isDetailModal.selectId}
            productType={productType}
          />
        </ModalPortal>
      )}
      <FlexBox
        $isFullWidth
        $flexDirection="row"
        $jc="space-between"
        $ai="center"
        height={6}
        $bbw={1}
        $bbc={colors.DEFAULT_BORDER_COLOR}
        $pl={2}
        $pr={4}
      >
        <Typograpy
          fontStyle={fontStyle.body1_normal}
          fontWeight="SemiBold"
          color={colors.SALLER_SUBSIDE_TITLE}
        >
          나의 고객 리스트
        </Typograpy>
        <FlexBox $flexDirection="row" $gap={2.4} height={"100%"}>
          {(subscribeType === "insurances" || subscribeType === "premium") && (
            <Box height={"calc(100% - 0.3rem)"}>
              <FlexBox
                $isPointer
                height={"100%"}
                $jc={"center"}
                onClick={() => {
                  setProductType("insurances");
                }}
              >
                <Typograpy
                  fontStyle={fontStyle.headline2}
                  fontWeight={productType === "insurances" ? "Bold" : "Regular"}
                  color={colors.SALLER_TAB_SEL}
                >
                  보험상품
                </Typograpy>
              </FlexBox>
              {productType === "insurances" && (
                <Box
                  width={"100%"}
                  $bc={colors.MAIN}
                  $btlr={10}
                  $btrr={10}
                  height={0.3}
                  $bgcolor={colors.SALLER_TAB_SEL}
                />
              )}
            </Box>
          )}
          {(subscribeType === "finance" || subscribeType === "premium") && (
            <Box height={"calc(100% - 0.3rem)"}>
              <FlexBox
                $isPointer
                height={"100%"}
                $jc={"center"}
                onClick={() => {
                  setProductType("finance");
                }}
              >
                <Typograpy
                  fontStyle={fontStyle.headline2}
                  fontWeight={productType === "finance" ? "Bold" : "Regular"}
                  color={colors.SALLER_TAB_NONE_SEL}
                >
                  금융상품
                </Typograpy>
              </FlexBox>
              {productType === "finance" && (
                <Box
                  width={"100%"}
                  $bc={colors.MAIN}
                  $btlr={10}
                  $btrr={10}
                  height={0.3}
                  $bgcolor={colors.SALLER_TAB_SEL}
                />
              )}
            </Box>
          )}
        </FlexBox>
      </FlexBox>
      <Box $isFullWidth $pa={3}>
        <CustomerListFilter
          filterInfo={filterInfo}
          filterSetting={filterSetting}
          searchAction={searchAction}
        />
      </Box>
      {!isListLoading ? (
        <Box $isFullWidth $px={3} $pb={3}>
          <Box $isFullWidth>
            <Box $mb={0.8}>
              <Typograpy fontStyle={fontStyle.label1_normal}>
                총 {userData?.data.page.totalElements}명
              </Typograpy>
            </Box>
            {userData && (
              <DataTable
                columns={columns}
                data={userData?.data.response}
                defaultSortFieldId={1}
                customStyles={tableCustomStyles}
                noDataComponent={<NoneDataComponent />}
              />
            )}
            <Pagination
              page={
                filterInfo.page === 0 || filterInfo.page
                  ? filterInfo.page + 1
                  : 0
              }
              rowCount={userData?.data.page.totalElements || 0}
              rowPerPage={filterInfo.size || 10}
              setPage={(page: number) => {
                setFilterInfo({
                  ...filterInfo,
                  page: page - 1,
                });
              }}
            />
          </Box>
        </Box>
      ) : (
        <LoadingComponent />
      )}
    </FlexBox>
  );
};

export default MyCustomerTemplate;
