import React, { ReactNode } from "react";
import Modal from "react-modal";
import { CommonModalType } from "./commonModalType";

interface CommonModalPropsType extends CommonModalType {
  customStyle?: Modal.Styles;
  children: ReactNode;
}

export const CommonModal: React.FC<CommonModalPropsType> = ({
  visible,
  modalOff,
  customStyle,
  children,
}) => {
  return (
    <Modal
      onRequestClose={modalOff}
      isOpen={visible}
      style={
        customStyle || {
          overlay: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "rgba(0,0,0,0.4)",
            zIndex: 999,
          },
          content: {
            width: "70%",
            //   height: "70%",
            position: "relative",
            padding: 0,
            paddingTop: "2rem",
            paddingBottom: "2rem",
            inset: 0,
          },
        }
      }
    >
      {children}
    </Modal>
  );
};
