import { Divider } from "components/molecules/divider/Divider";
import Typograpy from "components/molecules/Typograpy";
import React from "react";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { FlexBox } from "theme/globalStyle";
import { NumberComma } from "utils/func";
import { CardContents, CardTitle } from "./CardEl";
import { LongTermCollectiveType } from "api/blueButton/getFinancesInfo";

interface LongTermCollectiveListPropsType {
  data: LongTermCollectiveType;
}

//주택자금대출 리스트
export const LongTermCollectiveList: React.FC<
  LongTermCollectiveListPropsType
> = ({ data }) => {
  return (
    <FlexBox
      $flexDirection="row"
      $ai="center"
      $isFullWidth
      $bw={1}
      $bc={colors.DEFAULT_BORDER_COLOR}
      $br={10}
      $py={1}
      $px={2}
    >
      <FlexBox $flex={3}>
        <CardTitle title={"기관명"} />
        <CardContents contents={data.insitution} />
      </FlexBox>
      <Divider height={"100%"} variant="normal" isVertical />
      <FlexBox $pl={1.6} $minWidth={12}>
        <CardTitle title={"펀드명"} />
        <CardContents contents={data.fundName} />
      </FlexBox>
      <Divider height={"100%"} variant="normal" isVertical />
      <FlexBox $pl={1.6} $minWidth={12}>
        <CardTitle title={"펀드 연간납입 금액"} />
        <CardContents contents={`${Number(data.annualTotalAmount)}원`} />
      </FlexBox>
      <Divider height={"100%"} variant="normal" isVertical />
      <FlexBox $pl={1.6} $minWidth={12}>
        <CardTitle title={"공제대상 금액"} />
        <CardContents contents={`${Number(data.deductionTargetAmount)}원`} />
      </FlexBox>
    </FlexBox>
  );
};
