import { useQueryClient } from "@tanstack/react-query";
import { GetUsersResponseData, useGetUsersList } from "api/blueButton/GetUsers";
import React from "react";
import DataTable, { TableColumn } from "react-data-table-component";
import { Box, FlexBox } from "theme/globalStyle";
import {
  convertUnixDate,
  filterUserId,
  formatAndMaskVariableDigits,
  maskAllButFirst,
  NumberComma,
} from "utils/func";

import PerpleCheckIcon from "assets/img/ic_perple_check.svg";
import GreenCheckIcon from "assets/img/ic_green_check.svg";
import { OutlineButton } from "components/molecules/button/OutlineButton";
import { colors } from "theme/colors";
import { DatatablePagination } from "components/organisms/pagination/DatatablePagination";
import { tableCustomStyles } from "theme/table.custom";
import { GetProposalListData } from "api/api.userProposal";
import { GetSallerListResponse } from "api/api.salesmanager";
import ModalPortal from "utils/Portal";
import { ConsumerDetailModal } from "components/organisms/modal/ConsumerDetailModal";

interface SallerSendListProps {
  selCustomerIndex: number;
  sallerData?: GetSallerListResponse;
}

//ADMIN - 설계사목록 - 발송목록
export const SallerSendList: React.FC<SallerSendListProps> = ({
  selCustomerIndex,
  sallerData,
}) => {
  const [userProposalList, setUserProposalList] = React.useState<
    GetProposalListData[]
  >([]);

  const [userIdList, setUserIdList] = React.useState("");
  const [userList, setUserList] = React.useState<GetUsersResponseData[]>([]);

  const { data: userData, refetch } = useGetUsersList({ userIds: userIdList });

  const [isDetailModal, setIsDetailModal] = React.useState({
    visible: false,
    selectId: 0,
  });
  const [selProposalInfo, setSelProposalInfo] = React.useState<{
    proposal_id: number;
    proposal_type: "insurances" | "finance";
  }>({
    proposal_id: 0,
    proposal_type: "insurances",
  });

  const columns: TableColumn<GetProposalListData>[] = [
    {
      name: "NO",
      // selector: (row: any, index: number) => index,
      sortable: false,
      cell: (_, index: number) => index + 1,
      width: "6rem",
    },
    {
      name: "지역",
      selector: (row) => {
        const filterUserList = userList.filter((el) => el.id === row.user_id);
        if (filterUserList.length === 0) {
          return "";
        }
        return filterUserList[0].address;
      },
      sortable: false,
      grow: 3,
    },
    {
      name: "구분",
      selector: (row) => {
        const filterUserList = userList.filter((el) => el.id === row.user_id);
        if (filterUserList.length === 0) {
          return "";
        }
        if (
          filterUserList[0].isFinancialInfo &&
          filterUserList[0].isInsuranceInfo
        ) {
          return "보험/금융";
        } else if (filterUserList[0].isFinancialInfo) {
          return "금융";
        } else if (filterUserList[0].isInsuranceInfo) {
          return "보험";
        }
        return "";
      },
      sortable: false,
    },
    {
      name: "성명",
      selector: (row) => {
        const filterUserList = userList.filter((el) => el.id === row.user_id);
        if (filterUserList.length === 0) {
          return "";
        }
        return maskAllButFirst(filterUserList[0].name);
      },
      sortable: true,
    },
    {
      name: "연령",
      selector: (row) => {
        const filterUserList = userList.filter((el) => el.id === row.user_id);
        if (filterUserList.length === 0) {
          return "";
        }
        return "만 " + filterUserList[0].age + "세";
      },
      sortable: true,
    },
    {
      name: "직장",
      selector: (row) => {
        const filterUserList = userList.filter((el) => el.id === row.user_id);
        if (filterUserList.length === 0) {
          return "";
        }
        return filterUserList[0].businessName;
      },
      sortable: true,
      grow: 4,
    },
    {
      name: "이메일",
      selector: (row) => {
        const filterUserList = userList.filter((el) => el.id === row.user_id);
        if (filterUserList.length === 0) {
          return "";
        }
        return filterUserList[0].email;
      },
      sortable: false,
      grow: 2,
    },
    {
      name: "종합소득",
      selector: (row) => {
        const filterUserList = userList.filter((el) => el.id === row.user_id);
        if (filterUserList.length === 0) {
          return "";
        }
        return (
          formatAndMaskVariableDigits(filterUserList[0].totalIncome, 7) + "원"
        );
      },
      sortable: true,
      grow: 2,
    },
    {
      name: "발송일",
      selector: (row) => {
        const filterUserList = userList.filter((el) => el.id === row.user_id);
        if (filterUserList.length === 0) {
          return "";
        }
        return convertUnixDate(row.created_at);
      },
      sortable: true,
      grow: 2,
    },
    {
      name: "제안상태",
      // selector: (row) => row.readCount,
      sortable: false,
      grow: 2,
      cell: (row) => {
        function openDetailModal() {
          setIsDetailModal({
            visible: true,
            selectId: row.user_id,
          });
          setSelProposalInfo({
            proposal_id: row.user_proposal_id,
            proposal_type: row.sales_proposal.proposal_type as
              | "insurances"
              | "finance",
          });
        }

        if (row.proposition_status === "WAIT") {
          return (
            <OutlineButton
              size={"small"}
              buttonLabel="발송완료"
              $br={100}
              leftIcon={PerpleCheckIcon}
              style={{
                minWidth: "9.7rem",
              }}
              onClick={openDetailModal}
            />
          );
        } else if (row.proposition_status === "REJECT") {
          return (
            <OutlineButton
              size={"small"}
              buttonLabel="X 거절"
              $br={100}
              bc={colors.REFUSE_BTN}
              fontStyle={`color:${colors.REFUSE_BTN};`}
              style={{
                minWidth: "9.7rem",
              }}
              onClick={openDetailModal}
            />
          );
        } else {
          return (
            <OutlineButton
              size={"small"}
              buttonLabel="수락"
              $br={100}
              leftIcon={GreenCheckIcon}
              bc={colors.ACCEPT_BTN}
              fontStyle={`color:${colors.ACCEPT_BTN};`}
              style={{
                minWidth: "9.7rem",
              }}
              onClick={openDetailModal}
            />
          );
        }
      },
    },
  ];

  //블루버튼 user를 조회하기위한 id리스트 string변환
  const idListFilter = () => {
    const idList = filterUserId(userProposalList.map((el) => el.user_id));
    setUserIdList(idList);
  };

  React.useEffect(() => {
    if (userProposalList.length > 0) {
      idListFilter();
    }
  }, [userProposalList]);

  React.useEffect(() => {
    if (sallerData && selCustomerIndex) {
      if (sallerData.status === 200) {
        const selSalesmanagerInfo = sallerData.data.filter(
          (el) => el.salesmanager_id === selCustomerIndex
        );
        if (selSalesmanagerInfo.length > 0) {
          setUserProposalList([...selSalesmanagerInfo[0].user_proposal]);
        } else {
        }
      }
    }
  }, [sallerData, selCustomerIndex]);

  React.useEffect(() => {
    refetch();
  }, [userIdList]);

  React.useEffect(() => {
    if (userData) {
      setUserList([...userData.data.response]);
    }
  }, [userData]);

  return (
    <FlexBox $pa={2} $isFullWidth>
      {isDetailModal.visible && (
        <ModalPortal>
          <ConsumerDetailModal
            setIsDetailModal={setIsDetailModal}
            selectId={isDetailModal.selectId}
            productType={selProposalInfo.proposal_type}
            proposal_id={selProposalInfo.proposal_id}
            userData={userData}
          />
        </ModalPortal>
      )}
      {/* <CustomerListFilter
        filterInfo={filterInfo}
        filterSetting={filterSetting}
      /> */}

      <Box $isFullWidth>
        <DataTable
          columns={columns}
          data={userProposalList.filter(
            (el) => el.proposition_status !== "SEND"
          )}
          // selectableRows
          defaultSortFieldId={1}
          pagination
          paginationComponent={DatatablePagination}
          customStyles={tableCustomStyles}
        />
      </Box>
    </FlexBox>
  );
};
