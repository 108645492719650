import { IcClose } from "components/atoms/icon/icon";
import { Divider } from "components/molecules/divider/Divider";
import Typograpy from "components/molecules/Typograpy";
import { NoticeCreate } from "components/templates/admin/AdminNoticeTemplate/NoticeCreate";
import { NoticeDetail } from "components/templates/admin/AdminNoticeTemplate/NoticeDetail";
import React from "react";
import Modal from "react-modal";
import { fontStyle } from "theme/fontStyle";
import { Box, FlexBox } from "theme/globalStyle";
import { CommonModalType } from "./commonModalType";
import ModalPortal from "utils/Portal";
import { AlertModal } from "./AlertModal";
import { OutlineButton } from "components/molecules/button/OutlineButton";
import { colors } from "theme/colors";
import { SolidButton } from "components/molecules/button/SolidButton";
import { TableTitle } from "components/molecules/table/TableTitle";
import { TableContent } from "components/molecules/table/TableContent";
import { DetailTableRow } from "../table/DetailTableRow";
import { EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { PrimaryInput } from "components/molecules/Input/PrimaryInput";
import { useUserInfoStore } from "store/userInfoStore";
import useScrollLock from "hooks/useScrollLock";
import {
  CouponDataWithParams,
  GetCouponDataListResponse,
} from "api/api.coupon";
import { CouponBoxData, useCreateCouponBox } from "api/api.couponBox";
import { useQueryClient } from "@tanstack/react-query";
import { useSnackbarStore } from "store/snackbarStore";
import { useCouponStore } from "store/useCouponStore";
import { CommonModal } from "./CommonModal";

interface AddCouponModalPropsType extends CommonModalType {
  couponBoxList: CouponBoxData[];
  refreshCouponBox: () => void;
}

//쿠폰등록 modal
export const AddCouponModal: React.FC<AddCouponModalPropsType> = ({
  visible,
  modalOff,
  couponBoxList,
  refreshCouponBox,
}) => {
  const showSnackbar = useSnackbarStore((state) => state.showSnackbar);
  const { salesmanager_id } = useUserInfoStore((state) => state);
  //쿠폰코드 찾은 후 아이디 반환필요.
  const { couponList } = useCouponStore((state) => state);

  const { mutate: createCouponMutate } = useCreateCouponBox();

  const [couponError, setCouponError] = React.useState(false);

  const [couponCode, setCouponCode] = React.useState("");

  const [alertModal, setAlertModal] = React.useState({
    visible: false,
    msg: "",
    type: "",
  });

  //쿠폰등록
  const addCouponHandler = () => {
    if (couponCode === "") {
      setCouponError(true);
    } else {
      const filterCoupon = couponList.filter(
        (el) => el.coupon_code === couponCode
      );

      if (filterCoupon.length === 0) {
        alertModalOn("존재하지 않는 쿠폰입니다.");
      } else {
        const isHaveSameCoupon = couponBoxList.some(
          (el) => el.coupon_id === filterCoupon[0].coupon_id
        );

        if (isHaveSameCoupon) {
          alertModalOn("이미 등록되거나 사용된 쿠폰입니다.");
        } else {
          const params = {
            salesmanager_id,
            coupon_id: filterCoupon[0].coupon_id || 0,
            expired_date: filterCoupon[0].expiration_end_date,
          };
          createCouponMutate(params, {
            onSuccess: (res) => {
              refreshCouponBox();
              showSnackbar("쿠폰등록이 완료되었습니다.");
              modalOff();
            },
            onError: (error) => {
              alertModalOn(error.message);
            },
          });
        }
      }
    }
  };

  function alertModalOn(msg: string, type?: string) {
    setAlertModal({
      visible: true,
      msg,
      type: type || "",
    });
  }

  function alertModalOff() {
    setAlertModal({
      visible: false,
      msg: "",
      type: "",
    });
  }

  //modal 활성화시 body 스크롤 lock
  useScrollLock(visible);

  return (
    <CommonModal
      modalOff={modalOff}
      visible={visible}
      customStyle={{
        overlay: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(0,0,0,0.4)",
          zIndex: 999,
        },
        content: {
          width: "50%",
          //   height: "70%",
          position: "relative",
          padding: 0,
          paddingTop: "2rem",
          paddingBottom: "2rem",
          inset: 0,
        },
      }}
    >
      <ModalPortal>
        <AlertModal
          visible={alertModal.visible}
          modalOff={alertModalOff}
          msg={alertModal.msg}
        />
      </ModalPortal>
      <FlexBox
        $isFullWidth
        $flexDirection="row"
        $jc={"space-between"}
        $ai={"center"}
        $px={3}
      >
        <Typograpy fontStyle={fontStyle.heading2}>쿠폰등록</Typograpy>
        <Box $isPointer onClick={modalOff}>
          <IcClose />
        </Box>
      </FlexBox>
      <Box $isFullWidth $my={2}>
        <Divider variant="normal" width={"100%"} />
      </Box>

      <FlexBox $mb={2} $px={2} $isFullWidth>
        <PrimaryInput
          value={couponCode}
          onChangeValue={(value) => {
            setCouponCode(value);
            setCouponError(false);
          }}
          placeholder="쿠폰코드를 입력해주세요."
          errorMsg="쿠폰코드를 입력해주세요."
          $isError={couponError}
        />
        <Box $isFullWidth $mt={1.2}>
          <SolidButton
            buttonLabel="쿠폰등록"
            size={"large"}
            style={{ width: "100%" }}
            onClick={addCouponHandler}
          />
        </Box>
      </FlexBox>
    </CommonModal>
  );
};
