import { useQueryClient } from "@tanstack/react-query";
import {
  CouponDataWithParams,
  GetCouponDataListResponse,
} from "api/api.coupon";

import { IcSearch } from "components/atoms/icon/icon";
import { PrimaryInput } from "components/molecules/Input/PrimaryInput";
import Typograpy from "components/molecules/Typograpy";
import LoadingComponent from "components/organisms/loadingComponent";
import { color } from "framer-motion";
import useDateRangeChecker from "hooks/useDateRangeChecker";
import React from "react";
import { useCouponStore } from "store/useCouponStore";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { Box, FlexBox } from "theme/globalStyle";
import { InlayHintKind } from "typescript";
import { dummyProfileList } from "utils/dummyList";
import { getDate, getUnixDate, isDateInRange } from "utils/func";

interface CouponSearchAreaProps {
  setSelCouponIdx: (index: number) => void; //설계사 선택
  selCouponIdx: number | ""; //선택한 설계사 index
  couponModalOn: (couponIdx?: number) => void;
}

//쿠폰 목록 검색
export const CouponSearchArea: React.FC<CouponSearchAreaProps> = ({
  selCouponIdx,
  setSelCouponIdx,
  couponModalOn,
}) => {
  const { couponList } = useCouponStore((state) => state);
  const [inputValue, setInputValue] = React.useState<string>("");

  React.useEffect(() => {
    if (couponList.length > 0) {
      setSelCouponIdx(couponList[0].coupon_id || 0);
    } else {
      // console.error("isError ... ", couponData.message);
    }
  }, [couponList]);

  return (
    <FlexBox
      $minWidth={30}
      height={"100%"}
      $brw={1}
      $brc={colors.DEFAULT_BORDER_COLOR}
      $bgcolor={colors.ADMIN_SEARCH_BG}
      $maxHeight={"calc(100vh - 6.8rem)"}
      $py={1.6}
    >
      <Box $isFullWidth $pb={1.6} $px={2}>
        <PrimaryInput
          value={inputValue}
          onChangeValue={setInputValue}
          placeholder="쿠폰명을 입력해주세요."
          rightIcon={<IcSearch />}
          inputStyle={{ height: "3rem", borderRadius: 100 }}
        />
      </Box>
      <Box style={{ overflowY: "scroll" }} $isFullWidth>
        {couponList
          .filter((el) => {
            if (inputValue === "") {
              return el;
            } else {
              return el.coupon_name.includes(inputValue);
            }
          })
          .map((item, index) => {
            return (
              <FlexBox
                $flexDirection="row"
                $jc={"space-between"}
                $ai={"center"}
                $py={1.2}
                $px={2}
                key={"customer" + index}
                $isPointer
                $isFullWidth
                $bgcolor={
                  item.coupon_id === selCouponIdx
                    ? colors.ADMIN_CUSTOMER_SEL_BG
                    : ""
                }
                $bw={1}
                $bc={
                  item.coupon_id === selCouponIdx
                    ? colors.ADMIN_CUSTOMER_SEL_BORDER
                    : colors.ADMIN_SEARCH_BG
                }
                onClick={() => {
                  setSelCouponIdx(item.coupon_id || 0);
                }}
              >
                <FlexBox $flexDirection="row" $gap={1.2}>
                  <FlexBox>
                    <FlexBox $flexDirection="row" $ai={"center"} $gap={0.4}>
                      <Typograpy
                        fontStyle={fontStyle.label1_normal}
                        fontWeight="Bold"
                      >
                        {item.coupon_name}
                      </Typograpy>
                    </FlexBox>
                    <Typograpy fontStyle={fontStyle.caption1}>
                      {
                        getDate(new Date(item.expiration_start_date * 1000))
                          .comText
                      }{" "}
                      ~{" "}
                      {
                        getDate(new Date(item.expiration_end_date * 1000))
                          .comText
                      }
                    </Typograpy>
                  </FlexBox>
                </FlexBox>
                <FlexBox
                  $py={0.5}
                  width={5.5}
                  $jc={"center"}
                  $ai={"center"}
                  $br={100}
                  $bgcolor={
                    isDateInRange(
                      item.expiration_start_date,
                      item.expiration_end_date
                    )
                      ? colors.ADMIN_COUPON_PROCESS
                      : colors.ADMIN_COUPON_END
                  }
                  $bw={1}
                  $bc={colors.ADMIN_COUPON_END_FONT}
                >
                  <Typograpy
                    color={
                      isDateInRange(
                        item.expiration_start_date,
                        item.expiration_end_date
                      )
                        ? colors.WHITE
                        : colors.ADMIN_COUPON_END_FONT
                    }
                    fontStyle={fontStyle.caption2}
                  >
                    {isDateInRange(
                      item.expiration_start_date,
                      item.expiration_end_date
                    )
                      ? "진행중"
                      : "종료"}
                  </Typograpy>
                </FlexBox>
              </FlexBox>
            );
          })}
      </Box>
      <Box $isFullWidth $px={1.2} $mt={2}>
        <FlexBox
          $isFullWidth
          $br={100}
          $bgcolor={colors.MAIN}
          $jc={"center"}
          $ai={"center"}
          $py={0.8}
          $isPointer
          onClick={() => {
            couponModalOn();
          }}
        >
          <Typograpy
            fontStyle={fontStyle.label1_normal}
            color={colors.WHITE}
            fontWeight="SemiBold"
          >
            새 쿠폰 추가
          </Typograpy>
        </FlexBox>
      </Box>
    </FlexBox>
  );
};
