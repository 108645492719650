import { IcTrash } from "components/atoms/icon/icon";
import { TableRowWrapper } from "components/atoms/table/table";
import { OutlineButton } from "components/molecules/button/OutlineButton";
import { SolidButton } from "components/molecules/button/SolidButton";
import { SolidCheckbox } from "components/molecules/checkbox/SolidCheckkbox";
import { File } from "components/molecules/file/File";
import { PrimaryInput } from "components/molecules/Input/PrimaryInput";
import { Radio } from "components/molecules/radio/Radio";
import { SelectBox } from "components/molecules/selectbox/SelectBox";
import { TableContent } from "components/molecules/table/TableContent";
import { TableTitle } from "components/molecules/table/TableTitle";
import { Textarea } from "components/molecules/textarea/TextArea";
import Typograpy from "components/molecules/Typograpy";
import { CusMemberInfo } from "components/organisms/saller/customerDetail/CusMemberInfo";
import React from "react";
import { useSnackbarStore } from "store/snackbarStore";
import { useUserInfoStore } from "store/userInfoStore";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { Box, FlexBox } from "theme/globalStyle";
import {
  convertFileSizeToMB,
  convertUnixDate,
  getEamilButton,
  maxFileSize,
} from "utils/func";
import { convertToRaw, EditorState, Modifier } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftjsToHtml from "draftjs-to-html";
import { useLoadingStore } from "store/useLoadingStore";
import ModalPortal from "utils/Portal";
import { AlertModal } from "components/organisms/modal/AlertModal";
import { AxiosError } from "axios";
import { useTokenStore } from "store/useTokenStore";
import { useQueryClient } from "@tanstack/react-query";
import useScrollLock from "hooks/useScrollLock";
import { GetUsersResponseData } from "api/blueButton/GetUsers";
import { useSendEmail } from "api/api.email";
import { CreateProposalParams, useCreateProposal } from "api/api.userProposal";
import { useUploadProposalFile } from "api/api.uploadFile";
import { proposalEmailTemplate } from "utils/emailtemplate";
import { sendAligoMessagae } from "api/aligo/sendAligoMessage";

interface InseranceProposalProps {
  setIsDetailModal: (modalInfo: { visible: boolean; selectId: number }) => void;
  detailUserInfo: GetUsersResponseData;
  productType: "insurances" | "finance";
}

const MAX_EDITOR_LENGTH = 1000;

//보험상품 제안하기
export const Proposal: React.FC<InseranceProposalProps> = ({
  setIsDetailModal,
  detailUserInfo,
  productType,
}) => {
  const { showSnackbar } = useSnackbarStore((state) => state);
  const { updateLoading } = useLoadingStore((state) => state);
  const { token } = useTokenStore((state) => state);

  //이메일보내기
  const { mutate: sendEmailMutate, isPending: sendEmailLoading } =
    useSendEmail();

  //제안서 생성
  const { mutate: proposalMutate, isPending: createProposalLoading } =
    useCreateProposal({
      onSuccess: (res) => {
        // showSnackbar("제안서 생성완료");
      },
      onError: (error) => {
        console.log(error);
      },
    });

  //제안서 파일 업로드
  const { mutate: uploadProposalMutate, isPending: uploadFileLoading } =
    useUploadProposalFile();

  const { salesmanager_id, salesmanager_name, company_name } = useUserInfoStore(
    (state) => state
  );

  const [proposalInfo, setProposalInfo] = React.useState<CreateProposalParams>({
    userInfo: {
      ...detailUserInfo,
      user_id: detailUserInfo.id,
      createdDate: detailUserInfo.createdDate / 1000,
    },
    salesmanager_id: salesmanager_id,
    sales_proposal: {
      salesmanager_id: salesmanager_id,
      product_information: {
        product_name: "",
        product_content: "",
        is_show: "Y",
      },
      proposal_type: productType,
      proposal_title: "",
      proposal_content: "",
      notification: "NO",
      file_info: [],
    },
    proposition_status: "WAIT",
    user_proposal_id: 0,
  });

  const [proposalError, setProposalError] = React.useState({
    isTitleError: false,
    titleMsg: "",
    isContentError: false,
    contentMsg: "",
  });

  const [proposalFileList, setProposalFileList] = React.useState<File[]>([]);

  //에디터 입력
  const [editorState, setEditorState] = React.useState<EditorState>(() =>
    EditorState.createEmpty()
  );

  const [alertModal, setAlertModal] = React.useState({
    visible: false,
    msg: "",
    type: "",
  });

  const alertModalOn = (msg: string, type?: string) => {
    setAlertModal({
      visible: true,
      msg,
      type: type || "",
    });
  };

  //제안정보 입력
  function inputHandler(value: string, key?: string) {
    if (key) {
      if (key === "proposal_title") {
        setProposalError({
          ...proposalError,
          isTitleError: false,
          titleMsg: "",
        });
      }
      setProposalInfo((prev) => ({
        ...prev,
        sales_proposal: { ...prev.sales_proposal, [key]: value },
      }));
    }
  }

  // 에디터 상태 변경 시 호출 (글자 수 제한)
  const onEditorStateChange = (newEditorState: EditorState) => {
    const currentContent = newEditorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText().length;

    setProposalError({
      ...proposalError,
      isContentError: false,
      contentMsg: "",
    });

    if (currentContentLength > MAX_EDITOR_LENGTH) {
      setEditorState(editorState);
    } else {
      setEditorState(newEditorState);
    }
  };
  // 붙여넣기 처리 (글자 수 제한)
  const handlePastedText = (
    pastedText: string,
    html: string,
    editorState: EditorState
  ) => {
    const currentContent = editorState.getCurrentContent();
    const currentContentLength = currentContent.getPlainText().length;

    if (currentContentLength + pastedText.length > MAX_EDITOR_LENGTH) {
      return false; // 붙여넣기 방지
    }
    return true; // 붙여넣기 허용
  };

  //제안 파일 첨부
  function selectFileHandler(file: FileList) {
    if (file) {
      // if (file[0].size > maxFileSize) {
      //   showSnackbar("파일은 4MB이하로 업로드해주세요.");
      // } else {
      setProposalFileList((prev) => [...prev, file[0]]);
      // }
    }
  }

  //제안 파일 삭제
  function deleteFileHandler(index: number) {
    if (proposalFileList[index]) {
      setProposalFileList((prev) => prev.filter((_, i) => i !== index));
    }
  }

  //editor 공백만 있을경우 및 trim처리
  const isEditorEmpty = (): boolean => {
    const currentContent = editorState.getCurrentContent();
    const plainText = currentContent.getPlainText(); // 실제 텍스트 추출
    return !plainText.trim(); // 공백만 있는지 확인
  };

  function createProposalSuccess() {
    showSnackbar("제안서 생성이 완료되었습니다.");
    setIsDetailModal({ visible: false, selectId: 0 });
    if (proposalInfo.sales_proposal.notification === "YES") {
      sendAligoMessagae({
        name: detailUserInfo.name,
        receiver: detailUserInfo.phoneNumber,
        title: proposalInfo.sales_proposal.proposal_title,
      });
    }
  }
  function createProposalFail(error: AxiosError) {
    alertModalOn("제안서 생성이 실패하였습니다.", error.message);
  }

  //이메일 보내기
  function sendEmailHandler() {
    let params = {
      ...proposalInfo,
      sales_proposal: {
        ...proposalInfo.sales_proposal,
        proposal_content: draftjsToHtml(
          convertToRaw(editorState.getCurrentContent())
        ).replaceAll("<p></p>", "<br/>"),
      },
    };
    let fileHtmlContents = "";

    if (proposalFileList.length > 0) {
      let fileTotalSize = 0;
      let fileSrcList: File[] = [];
      let fileNameList: string[] = [];
      let fileInfoList: { file_name: string; file_url: string }[] = [];

      proposalFileList.map((item) => {
        fileTotalSize += item.size;
        fileSrcList.push(item);
        fileNameList.push(item.name);
      });

      uploadProposalMutate(
        {
          fileList: fileSrcList,
          fileNameList: fileNameList,
        },
        {
          onSuccess: (res) => {
            params = {
              ...params,
              sales_proposal: {
                ...params.sales_proposal,
                file_info: res.data,
              },
            };
            res.data.map((fileData) => {
              const fileSrc =
                process.env.REACT_APP_FILE_SERVER + fileData.file_url;

              fileHtmlContents =
                fileHtmlContents +
                `<a style="font-size:18px; font-weight:bold; color:${colors.MAIN};" href='${fileSrc}' download>${fileData.file_url}</a><br />`;
            });
            proposalMutate(params, {
              //영업제안서 저장
              onSuccess: (proposalRes) => {
                sendEmailMutate(
                  {
                    html: proposalEmailTemplate(
                      proposalRes.data.data.user_proposal_id,
                      productType,
                      salesmanager_name,
                      company_name,
                      params.sales_proposal.proposal_content,
                      fileHtmlContents
                    ),
                    subject:
                      `${
                        productType === "insurances"
                          ? "[보험상품제안]"
                          : "[금융상품제안]"
                      }` +
                      " " +
                      params.sales_proposal.proposal_title,
                    // to_email: "kimgtea@bluelions.kr",
                    to_email: detailUserInfo.email,
                  },
                  {
                    onSuccess: createProposalSuccess,
                    onError: createProposalFail,
                  }
                );
              },
              onError: (error) => {
                createProposalFail(error);
              },
            });
          },
        }
      );
    } else {
      proposalMutate(params, {
        //영업제안서 저장
        onSuccess: (proposalRes) => {
          sendEmailMutate(
            {
              html: proposalEmailTemplate(
                proposalRes.data.data.user_proposal_id,
                productType,
                salesmanager_name,
                company_name,
                params.sales_proposal.proposal_content
              ),
              subject:
                `${
                  productType === "insurances"
                    ? "[보험상품제안]"
                    : "[금융상품제안]"
                }` +
                " " +
                params.sales_proposal.proposal_title,
              to_email: detailUserInfo.email,
              // to_email: "kimgtea@bluelions.kr",
            },
            {
              onSuccess: createProposalSuccess,
              onError: createProposalFail,
            }
          );
        },
        onError: (error) => {},
      });
    }
  }

  //제안서 저장
  function saveProposal() {
    if (proposalInfo.sales_proposal.proposal_title.trim() === "") {
      setProposalError({
        ...proposalError,
        isTitleError: true,
        titleMsg: "제안명을 입력해주세요.",
      });
      showSnackbar("제안명을 입력해주세요.");
    } else if (isEditorEmpty()) {
      setProposalError({
        ...proposalError,
        isContentError: true,
        contentMsg: "제안 내용을 입력해주세요.",
      });
      showSnackbar("제안 내용을 입력해주세요.");
    } else {
      sendEmailHandler();
    }
  }

  React.useEffect(() => {
    updateLoading(
      sendEmailLoading || createProposalLoading || uploadFileLoading
    );
  }, [sendEmailLoading, createProposalLoading, uploadFileLoading]);

  useScrollLock(true);

  return (
    <Box $isFullWidth>
      <ModalPortal>
        <AlertModal
          visible={alertModal.visible}
          msg={alertModal.msg}
          type={alertModal.type}
          modalOff={() => {
            setAlertModal({ visible: false, msg: "", type: "" });
          }}
        />
      </ModalPortal>
      <Typograpy
        fontWeight="Medium"
        fontStyle={fontStyle.headline2}
        color={colors.BLACK}
      >
        기본정보
      </Typograpy>
      <FlexBox $mt={2} $isFullWidth $btw={1} $btc={colors.DEFAULT_BORDER_COLOR}>
        <CusMemberInfo detailUserInfo={detailUserInfo} />
      </FlexBox>
      <Box $mt={4} />
      <Typograpy
        fontWeight="Medium"
        fontStyle={fontStyle.headline2}
        color={colors.BLACK}
      >
        제안내용
      </Typograpy>

      <FlexBox $mt={2} $btw={1} $btc={colors.DEFAULT_BORDER_COLOR}>
        <TableRowWrapper>
          <TableTitle title={"제안명"} />
          <TableContent isColumnCenter>
            <FlexBox $isFullWidth $flexDirection="row" $ai="center" $gap={1.2}>
              <PrimaryInput
                value={proposalInfo.sales_proposal.proposal_title}
                onChangeValue={inputHandler}
                placeholder="제안명을 입력하세요."
                stateKey="proposal_title"
                maxInput={200}
                $isError={proposalError.isTitleError}
                errorMsg={proposalError.titleMsg}
              />
              <Box style={{ whiteSpace: "nowrap" }}>
                <Typograpy fontStyle={fontStyle.caption1} color={colors.RED}>
                  {proposalInfo.sales_proposal.proposal_title.length}
                </Typograpy>
                <Typograpy fontStyle={fontStyle.caption1} color={colors.BLACK}>
                  /200
                </Typograpy>
              </Box>
            </FlexBox>
          </TableContent>
        </TableRowWrapper>
        <TableRowWrapper>
          <TableTitle title={"알림톡 발송"} />
          <TableContent isColumnCenter>
            <FlexBox $flexDirection="row" $ai="center" $isFullWidth $gap={0.8}>
              <SolidCheckbox
                $checked={proposalInfo.sales_proposal.notification === "YES"}
                onChange={() => {
                  setProposalInfo({
                    ...proposalInfo,
                    sales_proposal: {
                      ...proposalInfo.sales_proposal,
                      notification:
                        proposalInfo.sales_proposal.notification === "YES"
                          ? "NO"
                          : "YES",
                    },
                  });
                }}
                name="notification"
                value={"Y"}
                label="발송"
              />
            </FlexBox>
          </TableContent>
        </TableRowWrapper>
        <TableRowWrapper>
          <TableTitle title={"영업담당자"} />
          <TableContent isColumnCenter isLeft>
            <FlexBox $flexDirection="row" $ai="center" $gap={1.2}>
              <Typograpy
                fontStyle={fontStyle.label1_reading}
                color={colors.SALLER_DETAIL_CONTENT}
              >
                {salesmanager_name}
              </Typograpy>
            </FlexBox>
          </TableContent>
        </TableRowWrapper>
        <TableRowWrapper>
          <TableTitle title={"회사명"} />
          <TableContent isColumnCenter isLeft>
            <FlexBox $flexDirection="row" $ai="center" $gap={1.2}>
              <Typograpy
                fontStyle={fontStyle.label1_reading}
                color={colors.SALLER_DETAIL_CONTENT}
              >
                {company_name}
              </Typograpy>
            </FlexBox>
          </TableContent>
        </TableRowWrapper>
        <TableRowWrapper>
          <TableTitle title={"제안 내용"} />
          <TableContent isColumnCenter>
            <FlexBox $isFullWidth>
              <FlexBox
                $flexDirection="row"
                $ai="center"
                $gap={1.2}
                $isFullWidth
              >
                {/* <Textarea
                value={proposalInfo.proposal_content}
                onChangeValue={inputHandler}
                placeholder="설명을 입력해주세요"
                style={{ width: "100%" }}
                stateKey="proposal_content"
                inputMax={200}
              /> */}
                <Editor
                  editorState={editorState}
                  onEditorStateChange={onEditorStateChange}
                  handlePastedText={handlePastedText}
                  toolbarClassName="tool-wrapper"
                  wrapperClassName="wrapper-area"
                  editorClassName="editor-area"
                />
                <Box style={{ whiteSpace: "nowrap" }}>
                  <Typograpy fontStyle={fontStyle.caption1} color={colors.RED}>
                    {editorState.getCurrentContent().getPlainText().length}
                  </Typograpy>
                  <Typograpy
                    fontStyle={fontStyle.caption1}
                    color={colors.BLACK}
                  >
                    /{MAX_EDITOR_LENGTH}
                  </Typograpy>
                </Box>
              </FlexBox>
              {proposalError.isContentError && (
                <Box $mt={0.4}>
                  <Typograpy fontStyle={fontStyle.caption1} color={colors.RED}>
                    {proposalError.contentMsg}
                  </Typograpy>
                </Box>
              )}
            </FlexBox>
          </TableContent>
        </TableRowWrapper>

        <TableRowWrapper>
          <TableTitle title={"첨부파일"} />
          <TableContent isColumnCenter isLeft>
            <FlexBox $isFullWidth>
              <File
                fileId="proposal-file"
                onChange={(e: FileList) => {
                  selectFileHandler(e);
                }}
                uploadLabel="파일선택"
                uploadButton={proposalFileList.length === 2 ? <></> : undefined}
              />
              {proposalFileList.length > 0 && (
                <FlexBox $isFullWidth $mt={1.2} $gap={0.8}>
                  {proposalFileList.map((item, index) => {
                    return (
                      <FlexBox
                        $flexDirection="row"
                        $ai={"center"}
                        key={"proposal-file" + index}
                        $gap={1.2}
                      >
                        <Typograpy
                          fontStyle={fontStyle.label2}
                          color={colors.FILE_NAME_FONT}
                        >
                          {item.name} ({convertFileSizeToMB(item.size)}MB)
                        </Typograpy>
                        <FlexBox
                          $isPointer
                          onClick={() => {
                            deleteFileHandler(index);
                          }}
                        >
                          <IcTrash width={1.5} height={1.5} />
                        </FlexBox>
                      </FlexBox>
                    );
                  })}
                </FlexBox>
              )}
            </FlexBox>
          </TableContent>
        </TableRowWrapper>
      </FlexBox>

      <FlexBox
        $flexDirection="row"
        $ai="center"
        $jc="center"
        $isFullWidth
        $mt={4}
        $gap={1.2}
      >
        <OutlineButton
          buttonLabel="취소"
          type={"assis"}
          size={"large"}
          $br={4}
          onClick={() => {
            setIsDetailModal({
              visible: false,
              selectId: 0,
            });
          }}
        />
        <SolidButton
          buttonLabel="제안하기"
          size={"large"}
          $br={4}
          onClick={() => {
            saveProposal();
            // showSnackbar("저장되었습니다.");
          }}
        />
      </FlexBox>
    </Box>
  );
};
