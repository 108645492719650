import { useQueryClient } from "@tanstack/react-query";
import {
  GetSallerData,
  GetSallerListResponse,
  useGetSallerList,
} from "api/api.salesmanager";
import { IconOutlineLoading } from "components/atoms/icon/animated";
import { IcSearch } from "components/atoms/icon/icon";
import { PrimaryInput } from "components/molecules/Input/PrimaryInput";
import Typograpy from "components/molecules/Typograpy";
import React from "react";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { Box, FlexBox } from "theme/globalStyle";

interface SearchAreaProps {
  setSelCustomerIndex: (index: number) => void; //설계사 선택
  selCustomerIndex: number | ""; //선택한 설계사 index
  searchName: string; //현재 입력되어있는 이름
  setSearchName: (name: string) => void; //이름검색
}

//설계사 목록 이름 검색
export const SearchArea: React.FC<SearchAreaProps> = ({
  selCustomerIndex,
  setSelCustomerIndex,
  searchName,
  setSearchName,
}) => {
  const queryClient = useQueryClient();

  const { data: userData, isLoading: userLoading } = useGetSallerList();

  // const userData: GetSallerListResponse | undefined = queryClient.getQueryData([
  //   "salesmanagerList",
  // ]);

  const [inputValue, setInputValue] = React.useState<string>("");

  const [sallerList, setSallerList] = React.useState<GetSallerData[]>([]);

  // const { data: userData } = useGetSallerList();

  React.useEffect(() => {
    if (userData) {
      if (userData.status === 200) {
        setSallerList([...userData.data]);
        if (selCustomerIndex === "") {
          setSelCustomerIndex(userData.data[0].salesmanager_id || 0);
        }
      } else {
        console.error("isError..." + userData.message);
      }
    }
  }, [userData]);

  return (
    <FlexBox
      $minWidth={30}
      height={"100%"}
      $brw={1}
      $brc={colors.DEFAULT_BORDER_COLOR}
      $bgcolor={colors.ADMIN_SEARCH_BG}
      $maxHeight={"calc(100vh - 6.8rem)"}
    >
      <Box $isFullWidth $py={1.6} $px={2}>
        <PrimaryInput
          value={inputValue}
          onChangeValue={setInputValue}
          placeholder="이름을 입력해주세요."
          rightIcon={<IcSearch />}
          inputStyle={{ height: "3rem", borderRadius: 100 }}
          iconAction={() => {
            setSearchName(inputValue);
          }}
          onEnterEvent={() => {
            setSearchName(inputValue);
          }}
        />
      </Box>
      {userLoading && (
        <FlexBox $isFullWidth $ai={"center"} $jc={"center"}>
          <IconOutlineLoading />
        </FlexBox>
      )}
      <Box style={{ overflowY: "scroll" }} $isFullWidth>
        {sallerList
          .filter((el) => {
            if (searchName === "") {
              return el;
            } else {
              return el.salesmanager_name.includes(searchName);
            }
          })
          .map((item, index) => {
            return (
              <FlexBox
                $flexDirection="row"
                $jc={"space-between"}
                $ai={"center"}
                $py={1.2}
                $px={2}
                key={"customer" + index}
                $isPointer
                $isFullWidth
                $bgcolor={
                  item.salesmanager_id === selCustomerIndex
                    ? colors.ADMIN_CUSTOMER_SEL_BG
                    : ""
                }
                $bw={1}
                $bc={
                  item.salesmanager_id === selCustomerIndex
                    ? colors.ADMIN_CUSTOMER_SEL_BORDER
                    : colors.ADMIN_SEARCH_BG
                }
                onClick={() => {
                  setSelCustomerIndex(item.salesmanager_id || 0);
                }}
              >
                <FlexBox $flexDirection="row" $gap={1.2}>
                  <FlexBox>
                    <FlexBox $flexDirection="row" $ai={"center"} $gap={0.4}>
                      <Typograpy
                        fontStyle={fontStyle.label1_normal}
                        fontWeight="Bold"
                      >
                        {item.salesmanager_name}
                      </Typograpy>
                      <Typograpy
                        fontStyle={fontStyle.caption2}
                        color={colors.TYPE_SEL_NONE_FONT}
                      >
                        {item.company_name}
                      </Typograpy>
                    </FlexBox>
                    <Typograpy fontStyle={fontStyle.caption1}>
                      {/* {item.location} / 고객 {item.customer}명 */}
                      요청 제안수 : {item.user_proposal.length}
                    </Typograpy>
                  </FlexBox>
                </FlexBox>
              </FlexBox>
            );
          })}
      </Box>
    </FlexBox>
  );
};
