import { Divider } from "components/molecules/divider/Divider";
import Typograpy from "components/molecules/Typograpy";
import React from "react";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { FlexBox } from "theme/globalStyle";
import { NumberComma } from "utils/func";

interface HousingFundCardListPropsType {
  data: {
    housingFundName: string;
    redemptionAmount: number;
    interestAmount: number;
  };
}

//주택자금대출 리스트
export const HousingFundCardList: React.FC<HousingFundCardListPropsType> = ({
  data,
}) => {
  return (
    <FlexBox
      $flexDirection="row"
      $ai="center"
      $isFullWidth
      $bw={1}
      $bc={colors.DEFAULT_BORDER_COLOR}
      $br={10}
      $py={1}
      $px={2}
    >
      <FlexBox $flex={3}>
        <Typograpy
          color={colors.INSURANCE_TITLE}
          fontStyle={fontStyle.caption1}
          fontWeight="SemiBold"
        >
          대출명
        </Typograpy>
        <Typograpy
          color={colors.INSURANCE_CONTENT}
          fontStyle={fontStyle.label1_normal}
        >
          {data.housingFundName
            .replaceAll("）", ")")
            .replaceAll("（", "(")
            .replaceAll(" ", "")}
        </Typograpy>
      </FlexBox>
      {/* <Divider height={"100%"} variant="normal" isVertical /> */}
      <Divider height={"100%"} variant="normal" isVertical />
      <FlexBox $pl={1.6} $minWidth={12}>
        <Typograpy
          color={colors.INSURANCE_TITLE}
          fontStyle={fontStyle.caption1}
          fontWeight="SemiBold"
        >
          현재상환액
        </Typograpy>
        <Typograpy
          color={colors.INSURANCE_CONTENT}
          fontStyle={fontStyle.label1_normal}
        >
          {NumberComma(data.redemptionAmount)}원
        </Typograpy>
      </FlexBox>
      <Divider height={"100%"} variant="normal" isVertical />
      <FlexBox $pl={1.6} $minWidth={12}>
        <Typograpy
          color={colors.INSURANCE_TITLE}
          fontStyle={fontStyle.caption1}
          fontWeight="SemiBold"
        >
          현재 상환 이자액
        </Typograpy>
        <Typograpy
          color={colors.INSURANCE_CONTENT}
          fontStyle={fontStyle.label1_normal}
        >
          {NumberComma(data.interestAmount)}원
        </Typograpy>
      </FlexBox>
    </FlexBox>
  );
};
