import { InquiryParamsWithDataType, useGetInquiryList } from "api/api.inquiry";
import { TableTd, TableTr, TableWrapper } from "components/atoms/table/table";
import Typograpy from "components/molecules/Typograpy";
import {
  BoardFilterType,
  BoardListFilter,
} from "components/organisms/filter/BoardListFilter";
import { QnaModal } from "components/organisms/modal/QnaModal";
import { NoticeTableHeader } from "components/organisms/notice/NoticeTableHeader";
import { Pagination } from "components/organisms/pagination/Pagination";
import React from "react";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { Box, FlexBox, MainMaxWidth } from "theme/globalStyle";
import { convertUnixDate } from "utils/func";
import ModalPortal from "utils/Portal";

export const QnaShowState = ({ isDone }: { isDone: boolean }) => {
  return (
    <FlexBox
      $br={2}
      $bw={1}
      $bc={isDone ? colors.GREEN : colors.RED}
      $py={0.4}
      width={6}
      $ai={"center"}
    >
      <Typograpy fontSize={11} color={isDone ? colors.GREEN : colors.RED}>
        {isDone ? "답변완료" : "대기중"}
      </Typograpy>
    </FlexBox>
  );
};

const AdminQnaTemplate = () => {
  const { data: inquiryData } = useGetInquiryList({});

  const [inquiryList, setInquiryList] = React.useState<
    InquiryParamsWithDataType[]
  >([]);

  const [boardFilter, setBoardFilter] = React.useState<BoardFilterType>({
    state: "전체",
    keyword: "",
    date_start: "",
    date_end: "",
  });

  const boardFilterHandler = (value: string, key?: string) => {
    if (key) {
      setBoardFilter({
        ...boardFilter,
        [key]: value,
      });
    }
  };

  const [qnaPage, setQnaPage] = React.useState(1);
  const startPost = (qnaPage - 1) * 10 + 1;
  const endPost = startPost + 10 - 1;

  const [qnaModal, setQnaModal] = React.useState({
    visible: false,
    qnaId: 1,
  });

  const stateList = [
    { label: "전체", value: "" },
    { label: "답변대기", value: "" },
    { label: "답변완료", value: "" },
  ];

  const applyFilters = () => {
    if (!inquiryData) return;

    let filtered = [...inquiryData.data.reverse()];
    if (boardFilter.state) {
      filtered = filtered.filter((item) => {
        if (boardFilter.state === "전체") {
          return true;
        } else if (boardFilter.state === "답변대기") {
          return !item.admin_id;
        } else {
          return item.admin_id;
        }
      });
    }

    if (boardFilter.keyword) {
      filtered = filtered.filter((item) =>
        item.title.toLowerCase().includes(boardFilter.keyword.toLowerCase())
      );
    }

    if (boardFilter.date_start) {
      filtered = filtered.filter(
        (item) =>
          new Date(convertUnixDate(item.created_at || 0)) >=
          new Date(boardFilter.date_start)
      );
    }

    if (boardFilter.date_end) {
      filtered = filtered.filter(
        (item) =>
          new Date(convertUnixDate(item.created_at || 0)) <=
          new Date(boardFilter.date_end)
      );
    }

    setInquiryList(filtered);
  };

  React.useEffect(() => {
    if (inquiryData) {
      if (inquiryData.status === 200) {
        setInquiryList([...inquiryData.data.reverse()]);
      }
    }
  }, [inquiryData]);

  return (
    <FlexBox
      $isFullWidth
      $ai={"center"}
      $bgcolor={colors.WHITE}
      height={"100%"}
    >
      {qnaModal.visible && (
        <ModalPortal>
          <QnaModal
            qnaId={qnaModal.qnaId}
            visible={qnaModal.visible}
            modalOff={() => {
              setQnaModal({ ...qnaModal, visible: false });
            }}
            mode={"admin"}
          />
        </ModalPortal>
      )}

      <FlexBox
        $isFullWidth
        $maxWidth={`calc(${MainMaxWidth}rem + 4rem)`}
        // $pa={4}
        $py={inquiryList.length === 0 ? 0 : 2}
        height={"100%"}
      >
        {inquiryList.length === 0 ? (
          <FlexBox $isFullWidth height={"100%"} $ai={"center"} $jc={"center"}>
            <Typograpy>등록된 문의사항이 없습니다.</Typograpy>
          </FlexBox>
        ) : (
          <FlexBox $pa={2} $bgcolor={colors.WHITE} $isFullWidth>
            <BoardListFilter
              boardFilter={boardFilter}
              boardFilterHandler={boardFilterHandler}
              stateList={stateList}
              applyFilters={applyFilters}
            />
            <TableWrapper>
              <thead>
                <TableTr>
                  <NoticeTableHeader title={"문의번호"} width={"10%"} />
                  <NoticeTableHeader title={"상태"} width={"10%"} />
                  <NoticeTableHeader title={"문의명"} width={"40%"} />
                  <NoticeTableHeader title={"등록일"} width={"15%"} />
                  <NoticeTableHeader title={"답변일"} width={"15%"} />
                  <NoticeTableHeader title={"작성자"} width={"10%"} />
                </TableTr>
              </thead>
              <tbody>
                {inquiryList
                  .slice(startPost - 1, endPost)
                  .map((item, index) => {
                    return (
                      <TableTr key={"notice" + index}>
                        <TableTd>
                          <Typograpy
                            fontStyle={fontStyle.caption1}
                            color={colors.BLACK}
                          >
                            {inquiryList.length - startPost - index + 1}
                          </Typograpy>
                        </TableTd>
                        <TableTd>
                          <FlexBox
                            $px={0.8}
                            $py={0.4}
                            $jc={"center"}
                            $ai={"center"}
                          >
                            <QnaShowState isDone={item.admin_id !== null} />
                          </FlexBox>
                        </TableTd>
                        <TableTd>
                          <Box
                            $isPointer
                            onClick={() => {
                              setQnaModal({
                                visible: true,
                                qnaId: item.inquiry_id || 0,
                              });
                            }}
                          >
                            <Typograpy
                              fontStyle={fontStyle.caption1}
                              color={colors.BLACK}
                              hoverColor={colors.ADMIN_MAIN_COLOR}
                            >
                              {item.title}
                            </Typograpy>
                          </Box>
                        </TableTd>
                        <TableTd>
                          <Typograpy
                            fontStyle={fontStyle.caption1}
                            color={colors.BLACK}
                          >
                            {item.created_at
                              ? convertUnixDate(item.created_at)
                              : "-"}
                          </Typograpy>
                        </TableTd>
                        <TableTd>
                          <Typograpy
                            fontStyle={fontStyle.caption1}
                            color={colors.BLACK}
                          >
                            {item.answer_date
                              ? convertUnixDate(item.answer_date)
                              : "-"}
                          </Typograpy>
                        </TableTd>
                        <TableTd>
                          <Typograpy
                            fontStyle={fontStyle.caption1}
                            color={colors.BLACK}
                          >
                            {"-"}
                          </Typograpy>
                        </TableTd>
                      </TableTr>
                    );
                  })}
              </tbody>
            </TableWrapper>
            <FlexBox $isFullWidth $ai={"center"}>
              <Pagination
                page={qnaPage}
                setPage={setQnaPage}
                rowCount={inquiryList.length}
                rowPerPage={10}
              />
            </FlexBox>
          </FlexBox>
        )}
      </FlexBox>
    </FlexBox>
  );
};

export default AdminQnaTemplate;
