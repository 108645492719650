import React from "react";
import { colors } from "theme/colors";
import Typograpy from "../Typograpy";
import {
  StyleDotPagination,
  StyleNumberPagination,
} from "components/atoms/pagination/pagination";

interface PaginationButtonType {
  pageNum: number;
  $isNowPage: boolean;
  onChangePage: (page: number) => void;
  btnType?: "dot" | "number";
}

export const PaginationButton = ({
  pageNum,
  $isNowPage,
  onChangePage,
  btnType = "number",
}: PaginationButtonType) => {
  if (btnType === "number") {
    return (
      <StyleNumberPagination
        $isNowPage={$isNowPage}
        onClick={() => {
          onChangePage(pageNum);
        }}
      >
        <Typograpy
          fontSize={14}
          color={
            $isNowPage ? colors.PAGINATION_NOW_FONT : colors.PAGINATION_FONT
          }
        >
          {pageNum}
        </Typograpy>
      </StyleNumberPagination>
    );
  } else {
    return (
      <StyleDotPagination
        $isNowPage={$isNowPage}
        onClick={() => {
          onChangePage(pageNum);
        }}
      />
    );
  }
};
