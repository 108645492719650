import styled from "styled-components";
import { colors } from "theme/colors";

export interface CheckboxProps {
  size?: "normal" | "small";
  $checked?: boolean;
  disabled?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
  value: string;
  label?: string;
  fontStyle?: string;
  fontWeight?:
    | "Thin"
    | "Light"
    | "Regular"
    | "Medium"
    | "SemiBold"
    | "Bold"
    | "ExtraBold";
  fontColor?: string;
}

const CheckboxWrapper = styled.label<Omit<CheckboxProps, "name" | "value">>`
  display: inline-flex;
  align-items: center;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
`;

const HiddenCheckbox = styled.input.attrs({
  type: "checkbox",
})`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

//사각형 체크박스
const StyleSolidCheckbox = styled.div<CheckboxProps>`
  width: ${({ size }) => (size === "small" ? "1.6rem" : "2rem")};
  height: ${({ size }) => (size === "small" ? "1.6rem" : "2rem")};
  background: ${({ $checked }) => ($checked ? `${colors.MAIN}` : "white")};
  border: 2px solid ${({ $checked }) => ($checked ? `${colors.MAIN}` : "#ccc")};
  border-radius: 3px;
  transition: all 0.2s;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.2);
  }
`;

//둥근 체크박스
const StyleRoundCheckbox = styled.div<CheckboxProps>`
  width: ${({ size }) => (size === "small" ? "1.6rem" : "2rem")};
  height: ${({ size }) => (size === "small" ? "1.6rem" : "2rem")};
  background: ${({ $checked }) => ($checked ? `${colors.MAIN}` : "white")};
  border: 2px solid ${({ $checked }) => ($checked ? `${colors.MAIN}` : "#ccc")};
  border-radius: 50%;
  transition: all 0.2s;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.2);
  }
`;

//체크박스만
const StyleJustCheckBox = styled.div<CheckboxProps>`
  width: ${({ size }) => (size === "small" ? "1.6rem" : "2rem")};
  height: ${({ size }) => (size === "small" ? "1.6rem" : "2rem")};
  color: ${({ $checked }) => ($checked ? `${colors.MAIN}` : "#ccc")};
  transition: all 0.2s;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.2);
  }
`;

// 체크 마크
const CheckIcon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
  position: relative; // 추가: 체크마크를 hover 효과 위에 표시하기 위해
  z-index: 1; // 추가: 체크마크를 hover 효과 위에 표시하기 위해
`;

const JustCheckIcon = styled.svg<{ checked?: boolean }>`
  fill: none;
  stroke: ${({ checked }) =>
    !checked ? colors.CHECK_IC_GRAY : colors.BTN_MAIN_COLOR};
  stroke-width: 2px;
  position: relative; // 추가: 체크마크를 hover 효과 위에 표시하기 위해
  z-index: 1; // 추가: 체크마크를 hover 효과 위에 표시하기 위해
`;

export {
  CheckboxWrapper,
  HiddenCheckbox,
  StyleSolidCheckbox,
  StyleRoundCheckbox,
  StyleJustCheckBox,
  CheckIcon,
  JustCheckIcon,
};
