import { useMutation, useQuery } from "@tanstack/react-query";
import { baseAxios } from "api/baseAxios";
import { AxiosError, AxiosResponse } from "axios";

interface SendEmailParams {
  to_email: string; //수신자 이메일
  subject: string; //제목
  html: string; //내용 (html 코드)
}

export interface SendEmailResponse {
  status: number; //응답
  message: string; //메세지
  data: boolean;
}

//이메일 발송
export const useSendEmail = () =>
  useMutation<AxiosResponse<SendEmailResponse>, AxiosError, SendEmailParams>({
    mutationKey: ["SendEmail"],
    mutationFn: (params: SendEmailParams) =>
      baseAxios.post("/email/send", params).then((res) => {
        return res;
      }),
  });
