import Typograpy from "components/molecules/Typograpy";
import { DefaultLegendContentProps } from "recharts";
import { FlexBox } from "theme/globalStyle";

//범례 custom
export const customLegend = (props: DefaultLegendContentProps) => {
  const { payload } = props;

  return (
    <FlexBox $flexDirection="row" $isFullWidth $jc={"center"} $mt={2}>
      {payload?.map((entry, index) => {
        return (
          <FlexBox
            key={"customLefend" + index}
            $flexDirection="row"
            $gap={0.4}
            $mr={1.2}
          >
            <Typograpy fontSize={14} color={entry.color}>
              ■
            </Typograpy>
            <Typograpy fontSize={14} color={entry.color}>
              {entry.value}
            </Typograpy>
          </FlexBox>
        );
      })}
    </FlexBox>
  );
};
