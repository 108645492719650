import { useQueryClient } from "@tanstack/react-query";
import { CouponDataWithParams } from "api/api.coupon";
import { CouponBoxData, GetCouponBoxDataListResponse } from "api/api.couponBox";
import { GetPaymentListResponseData } from "api/api.payment";
import {
  CreateSubProductParamsWithType,
  GetSubProductListResponse,
} from "api/api.subproduct";
import { GetLocationResponseData } from "api/blueButton/getLocationList";
import { BorderDeleteIcon } from "components/atoms/icon/icon";
import { SelectBox } from "components/molecules/selectbox/SelectBox";
import React from "react";
import { useSnackbarStore } from "store/snackbarStore";
import { useCouponStore } from "store/useCouponStore";
import { Box, FlexBox } from "theme/globalStyle";

interface SalesLocationSelBox {
  leftDisabled?: boolean;
  rightDisabled?: boolean;
  locationList: GetLocationResponseData[]; //지역 리스트
  subProductList: CreateSubProductParamsWithType[]; //구독상품 리스트
  couponBoxList?: CouponBoxData[]; //보유 쿠폰 리스트
  locPaymentInfo: GetPaymentListResponseData;
  selIndex: number;
  locationHandler?: (location: string, index: number) => void; //지역선택 핸들러
  choiceSubProduct?: (opt: string, localId: number) => void; //구독상품 선택 핸들러
  choiceCoupon?: (opt: string, localId: number) => void; //쿠폰선택 핸들러
  cancleSelProduct?: (productIndex: number) => void; //row 삭제 핸들러
  selSalesArea: GetPaymentListResponseData[]; //선택한 영업지역 리스트 (구독상품 선택가능)
  isNew?: boolean;
}

export const SalesLocationSelBox: React.FC<SalesLocationSelBox> = ({
  selIndex,
  locationList,
  subProductList,
  couponBoxList,
  locPaymentInfo,
  locationHandler,
  choiceSubProduct,
  choiceCoupon,
  selSalesArea,
  cancleSelProduct,
}) => {
  const showSnackbar = useSnackbarStore((state) => state.showSnackbar);

  // const queryClient = useQueryClient();
  // const subProductData: GetSubProductListResponse | undefined =
  //   queryClient.getQueryData(["subProductList"]);

  const { couponList } = useCouponStore((state) => state);

  // const [subProductList, setSubProductList] = React.useState<
  //   CreateSubProductParamsWithType[]
  // >([]);

  const selLocVerify = (location: string) => {
    const selLocationId = locationList.filter(
      (el) => el.regionName === location
    )[0].regionId;

    //보험,금융의 경우는 그외 항목 선택가능 (지역 추가 한번 가능)
    //프리미엄일 경우 지역 추가 불가
    const filterAreaList = selSalesArea.filter(
      (el) => el.sub_product_information.local_id === selLocationId
    );

    if (filterAreaList.length === 0) {
      //지역이 하나도 없을땐 바로 추가 가능
      return true;
    } else if (filterAreaList.length === 1) {
      //지역이 하나 있을경우
      if (
        filterAreaList[0].sub_product_information.product_type === "premium"
      ) {
        showSnackbar("해당지역은 더 이상 추가가 불가합니다.");
      } else {
        return true;
      }
    } else {
      //지역이 두개이상 (보험,금융 선택된 상태)
      showSnackbar("해당지역은 더 이상 추가가 불가합니다.");
      return false;
    }
  };

  const selSubVerify = (value: string) => {
    const optFilter = subProductList.filter(
      (el) => el.sub_product_optname === value
    );
    const sameLocalList = selSalesArea.filter(
      (el) =>
        el.sub_product_information.local_id ===
        locPaymentInfo.sub_product_information.local_id
    );
    const haveSubproduct = sameLocalList.some(
      (el) =>
        el.sub_product_information.product_type === optFilter[0].product_type
    );
    if (locPaymentInfo.sub_product_information.local_id === 0) {
      showSnackbar("지역을 선택해주세요.");
      return false;
    } else if (sameLocalList.length > 1) {
      if (
        sameLocalList.some(
          (el) => el.sub_product_information.product_type === "premium"
        ) ||
        optFilter[0].product_type === "premium"
      ) {
        showSnackbar(
          "보험/금융 상품을 보유하신 경우 프리미엄 구독이 불가합니다."
        );
        return false;
      } else if (haveSubproduct) {
        showSnackbar("이미 보유한 상품입니다.");
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  };

  React.useEffect(() => {
    if (choiceSubProduct) choiceSubProduct("", selIndex);
  }, [JSON.stringify(locPaymentInfo.sub_product_information.local_id)]);

  if (subProductList.length === 0) return <></>;

  return (
    <FlexBox $gap={1.2} $flexDirection={"row"} $ai={"stretch"} $isFullWidth>
      <Box $flex={1}>
        <SelectBox
          optList={locationList.map((item) => item.regionName)}
          onChangeValue={(location: string) => {
            if (locationHandler && selLocVerify(location))
              locationHandler(location, selIndex);
          }}
          placeholder="주 영업지역 선택"
          selOpt={
            locationList.filter(
              (el) =>
                el.regionId === locPaymentInfo.sub_product_information.local_id
            )[0]?.regionName
          }
        />
      </Box>
      <Box $flex={1}>
        <SelectBox
          optList={subProductList.map((el) => el.sub_product_optname)}
          selOpt={
            locPaymentInfo.sub_product_information.sub_product_id !== 0
              ? subProductList.filter(
                  (el) =>
                    el.product_type ===
                    locPaymentInfo.sub_product_information.product_type
                )[0].sub_product_optname
              : ""
          }
          placeholder="상품 선택"
          footerLabel=""
          onChangeValue={(value) => {
            if (choiceSubProduct && selSubVerify(value))
              choiceSubProduct(value, selIndex);
          }}
        />
      </Box>
      {couponBoxList &&
        (couponBoxList.length > 0 ||
          locPaymentInfo.coupon_information.coupon_name !== "") &&
        locPaymentInfo.sub_product_information.sub_product_id !== 0 && (
          <Box $flex={1}>
            <SelectBox
              optList={[
                "선택안함",
                ...couponBoxList.map(
                  (el) =>
                    couponList.filter(
                      (couponInfo) => couponInfo.coupon_id === el.coupon_id
                    )[0].coupon_name
                ),
              ]}
              selOpt={locPaymentInfo.coupon_information.coupon_name || ""}
              placeholder="쿠폰 선택"
              footerLabel=""
              onChangeValue={(value) => {
                if (choiceCoupon) choiceCoupon(value, selIndex);
              }}
            />
          </Box>
        )}
      <FlexBox
        $ai={"center"}
        $jc={"center"}
        $isPointer
        onClick={() => {
          if (cancleSelProduct) cancleSelProduct(selIndex);
        }}
      >
        <BorderDeleteIcon />
      </FlexBox>
    </FlexBox>
  );
};
