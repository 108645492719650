import React from "react";
import Typograpy from "components/molecules/Typograpy";
import { colors } from "theme/colors";
import { fontStyle } from "theme/fontStyle";
import { Box, FlexBox, MainMaxWidth } from "theme/globalStyle";

import { PieChartComponent } from "components/organisms/chart/PieChart";
import {
  MapChart,
  MapChartDataType,
} from "components/organisms/chart/MapChart";
import { JoinStatistics } from "./component/JoinStatistics";
import { Input } from "components/atoms/input/input";
import { useSnackbarStore } from "store/snackbarStore";
import { getDate, getUnixDate } from "utils/func";
import { PaymentStatistics } from "./component/PaymentStatistics";
import { useLocationStore } from "store/useLocationStore";
import { useGetSallerList } from "api/api.salesmanager";

export const DashboardTitle = ({ title }: { title: string }) => {
  return (
    <Typograpy fontStyle={fontStyle.label1_normal} fontWeight="Bold">
      {title}
    </Typograpy>
  );
};

const CompanyAdminHomeTemplate = () => {
  const showSnackBar = useSnackbarStore((state) => state.showSnackbar);
  const { data: salesData } = useGetSallerList();
  const { locationList } = useLocationStore((state) => state);

  //mapchart 시작 및 종료일
  const [mapStartDate, setMapStartDate] = React.useState(
    getDate().prevWeekHipon
  );
  const [mapEndDate, setMapEndDate] = React.useState(getDate().hiponText);

  const [servicePer, setServicePer] = React.useState([
    { name: "보험", value: 0, fill: "#96C6FA" },
    { name: "금융", value: 0, fill: "#479EF5" },
    { name: "프리미엄", value: 0, fill: "#115EA3" },
  ]);
  const [localStats, setLocalStats] = React.useState<MapChartDataType[]>([]);

  //통계 셋팅(가입서비스 비율 셋팅)
  function servicePerSetting() {
    if (!salesData) return;

    const lookupTable = {
      insurances: "보험",
      finance: "금융",
      premium: "프리미엄",
    };

    let tempServicePer = [...servicePer];
    salesData.data.forEach((item) => {
      item.payment.forEach((paymentItem) => {
        const subProductType = paymentItem.sub_product_information.product_type;
        const serviceName =
          lookupTable[subProductType as keyof typeof lookupTable];
        if (serviceName) {
          const arrIndex = tempServicePer.findIndex(
            (el) => el.name === serviceName
          );
          if (arrIndex !== -1) {
            tempServicePer[arrIndex].value += 1;
          }
        }
      });
    });
    setServicePer([...tempServicePer]);
  }

  //맵차트 셋팅
  function mapChartSetting() {
    if (!salesData || locationList.length === 0) return;

    let tempLocalStats: MapChartDataType[] = [];
    salesData.data.forEach((item) => {
      item.payment.forEach((paymentItem) => {
        // console.log(paymentItem);
        if (paymentItem.sub_product_information.product_type) {
          //지역별 가입현황 셋팅
          const regionName = locationList.filter(
            (el) => el.regionId === paymentItem.sub_product_information.local_id
          )[0].regionName;
          const isHaveLocale = tempLocalStats.some(
            (el) => el.locale === regionName
          );

          if (isHaveLocale) {
            const localIndex = tempLocalStats.findIndex(
              (el) => el.locale === regionName
            );

            const [startYear, startMonth, startDay] = mapStartDate.split("-");
            const [endYear, endMonth, endDay] = mapEndDate.split("-");
            const startUnix = getUnixDate(startYear, startMonth, startDay);
            const endUnix = getUnixDate(endYear, endMonth, endDay);
            if (paymentItem.created_at) {
              if (
                paymentItem.created_at >= startUnix &&
                paymentItem.created_at <= endUnix
              ) {
                //지역 가입수
                tempLocalStats[localIndex].count += 1;
                //지역별 총매출
                tempLocalStats[localIndex].totalPayment +=
                  paymentItem.final_price;
              }
            }
          } else {
            tempLocalStats.push({
              locale: regionName,
              count: 1,
              totalPayment: paymentItem.final_price,
            });
          }
        }
      });
    });
    setLocalStats([...tempLocalStats]);
  }

  const dateHandler = (value: string, type: "start" | "end") => {
    if (type === "start") {
      if (mapEndDate !== "") {
        if (new Date(value) > new Date(mapEndDate)) {
          showSnackBar("시작일보다 종료일이 빠를 수 없습니다.");
        } else {
          setMapStartDate(value);
        }
      }
    } else {
      if (mapStartDate !== "") {
        if (new Date(mapStartDate) > new Date(value)) {
          showSnackBar("시작일보다 종료일이 빠를 수 없습니다.");
        } else {
          setMapEndDate(value);
        }
      }
    }
  };

  React.useEffect(() => {
    if (!salesData) {
      return;
    }
    if (salesData.status === 200) {
      servicePerSetting();
      mapChartSetting();
    }
  }, [salesData]);

  React.useEffect(() => {
    if (!salesData || locationList.length === 0) return;

    if (salesData.status === 200) {
      mapChartSetting();
    }
  }, [mapStartDate, mapEndDate]);

  return (
    <FlexBox $isFullWidth $ai={"center"}>
      <FlexBox
        $isFullWidth
        $maxWidth={`calc(${MainMaxWidth}rem + 4rem)`}
        $pa={4}
      >
        <JoinStatistics />
        <PaymentStatistics />
        <FlexBox
          $flexDirection="row"
          $ai={"stretch"}
          $isFullWidth
          $gap={1.6}
          $mt={2}
        >
          <Box $flex={1} $isFullWidth $shadowType="strong" $pa={2} $br={8}>
            <FlexBox $flexDirection="row" $ai={"center"} $jc={"space-between"}>
              <FlexBox>
                <DashboardTitle title={"가입서비스 비율"} />
                <Typograpy
                  fontStyle={fontStyle.caption1}
                  color={colors.SALLER_DESCRIPTION}
                >
                  Description
                </Typograpy>
              </FlexBox>
            </FlexBox>
            <Box width={"100%"} height={40}>
              <Box $mt={2} height={"100%"} $isFullWidth>
                <PieChartComponent dataList={servicePer} />
              </Box>
            </Box>
          </Box>
          <Box $flex={1} $isFullWidth $shadowType="strong" $pa={2} $br={8}>
            <FlexBox
              $flexDirection="row"
              $isFullWidth
              $jc={"space-between"}
              $ai={"center"}
            >
              <FlexBox
                $flexDirection="row"
                $ai={"center"}
                $jc={"space-between"}
              >
                <DashboardTitle title={"지역별 가입현황"} />
              </FlexBox>

              <FlexBox
                $mt={1.2}
                $flexDirection="row"
                $ai={"flex-end"}
                $gap={1.2}
              >
                <FlexBox $flexDirection="row" $ai={"flex-end"} $gap={1.2}>
                  <Input
                    value={mapStartDate}
                    style={{ width: "auto" }}
                    type={"date"}
                    onChange={(e) => {
                      dateHandler(e.target.value, "start");
                    }}
                    max={mapEndDate}
                  />
                  <Input
                    value={mapEndDate}
                    style={{ width: "auto" }}
                    type={"date"}
                    onChange={(e) => {
                      dateHandler(e.target.value, "end");
                    }}
                    min={mapStartDate}
                  />
                </FlexBox>
              </FlexBox>
            </FlexBox>
            <Box $isFullWidth $mt={4}>
              <MapChart localData={localStats} />
            </Box>
            <FlexBox $isPointer $isFullWidth $ai={"flex-end"}>
              <Typograpy
                fontStyle={fontStyle.label1_normal}
                color={colors.MAIN}
              >
                View Details
              </Typograpy>
            </FlexBox>
          </Box>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};

export default CompanyAdminHomeTemplate;
