import React from "react";
import "./App.css";
import Snackbar from "components/organisms/snackbar/Snackbar";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { Router } from "Router";
import { useTokenStore } from "store/useTokenStore";
import { useLoadingStore } from "store/useLoadingStore";
import { LoadingModal } from "components/organisms/modal/LoadingModal";
import { baseAxios } from "api/baseAxios";
import axios from "axios";
import { getGeolocationInfo } from "api/geolocation/api.geolocation";

export const KAKAO_APP_KEY = process.env.REACT_APP_KAKAO_APP_KEY;
export const KAKAO_REDIRECT_URL = process.env.REACT_APP_KAKAO_REDIRECT_URL;

function App() {
  const queryClient = new QueryClient();

  const { setNiceToken } = useTokenStore((state) => state);

  const getNiceToken = () => {
    baseAxios
      .post("/auth/verify/prev", {
        grant_type: "client_credentials",
        scope: "default",
      })
      .then((res) => {
        setNiceToken(res.data.data.dataBody.access_token);
      });
  };

  const { isLoading } = useLoadingStore((state) => state);
  if (process.env.NODE_ENV === "production") {
    //production에서 console.log제거
    console = window.console || {};
    console.log = function no_console() {};
    console.warn = function no_console() {};
    console.error = function () {};
  }

  //클라이언트 ip가져오기
  async function getIpv4() {
    const geoLocationInfo = await getGeolocationInfo();
    console.log("client ip ???", geoLocationInfo.IPv4);
  }

  React.useEffect(() => {
    getNiceToken();
  }, []);
  React.useEffect(() => {
    // getIpv4();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <Router />
      <Snackbar />
      {isLoading && <LoadingModal />}
    </QueryClientProvider>
  );
}

export default App;
